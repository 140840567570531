.custom-notifier {
    display: none;
    font-size: 1.05em;
    min-height: 40px;
    line-height: 35px;
    text-align: center;
    width: 100%;
    background-color: #f8f8f8;
    color: #df1166;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 100000;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.n-warning {
    background-color: rgb(255, 234, 168);
    color: rgb(130, 98, 0);
    border-color: rgb(130, 98, 0);
}

.n-success {
    background-color: rgb(188, 245, 188);
    color: darkgreen;
    border-color: darkgreen;
}

.n-alert {
    background-color: rgb(255, 129, 129);
    color: #fff;
    border-color: white;
}
