.masonry-wrapper .grid-item .grid-item__inner {
    position: relative
}

.ps-small-banner .row div {
    text-align: center;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper {
    position: absolute
}

.masonry-wrapper .grid-item .grid-item__inner .grid-item__content-wrapper {
    overflow: hidden
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper {
    margin: 0 !important
}

.masonry-wrapper.ac_masonry-grid .grid-item__inner {
    padding-top: 100%
}

.masonry-wrapper.ac_masonry-grid .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid .grid-item.wide .grid-item__inner {
    padding-top: 50%
}

.masonry-wrapper.ac_masonry-grid .grid-item.high .grid-item__inner {
    padding-top: 200%
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item__inner {
    padding-top: 75%
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.wide .grid-item__inner {
    padding-top: 37.5%
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.high .grid-item__inner {
    padding-top: 150%
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item__inner {
    padding-top: 56.25%
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.wide .grid-item__inner {
    padding-top: 28.125%
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.high .grid-item__inner {
    padding-top: 112.5%
}

[data-col-xs="1"].masonry-wrapper .grid-item, [data-col-xs="1"].masonry-wrapper .grid-sizer {
    width: 100%
}

[data-col-xs="2"].masonry-wrapper .grid-item, [data-col-xs="2"].masonry-wrapper .grid-sizer {
    width: 50%
}

    [data-col-xs="2"].masonry-wrapper .grid-item.wide, [data-col-xs="2"].masonry-wrapper .grid-item.large {
        width: 100%
    }

    [data-col-xs="2"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="3"].masonry-wrapper .grid-item, [data-col-xs="3"].masonry-wrapper .grid-sizer {
    width: 33.33333%
}

    [data-col-xs="3"].masonry-wrapper .grid-item.wide, [data-col-xs="3"].masonry-wrapper .grid-item.large {
        width: 66.66667%
    }

    [data-col-xs="3"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="4"].masonry-wrapper .grid-item, [data-col-xs="4"].masonry-wrapper .grid-sizer {
    width: 25%
}

    [data-col-xs="4"].masonry-wrapper .grid-item.wide, [data-col-xs="4"].masonry-wrapper .grid-item.large {
        width: 50%
    }

    [data-col-xs="4"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="5"].masonry-wrapper .grid-item, [data-col-xs="5"].masonry-wrapper .grid-sizer {
    width: 20%
}

    [data-col-xs="5"].masonry-wrapper .grid-item.wide, [data-col-xs="5"].masonry-wrapper .grid-item.large {
        width: 40%
    }

    [data-col-xs="5"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="6"].masonry-wrapper .grid-item, [data-col-xs="6"].masonry-wrapper .grid-sizer {
    width: 16.66667%
}

    [data-col-xs="6"].masonry-wrapper .grid-item.wide, [data-col-xs="6"].masonry-wrapper .grid-item.large {
        width: 33.33333%
    }

    [data-col-xs="6"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="7"].masonry-wrapper .grid-item, [data-col-xs="7"].masonry-wrapper .grid-sizer {
    width: 14.28571%
}

    [data-col-xs="7"].masonry-wrapper .grid-item.wide, [data-col-xs="7"].masonry-wrapper .grid-item.large {
        width: 28.57143%
    }

    [data-col-xs="7"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="8"].masonry-wrapper .grid-item, [data-col-xs="8"].masonry-wrapper .grid-sizer {
    width: 12.5%
}

    [data-col-xs="8"].masonry-wrapper .grid-item.wide, [data-col-xs="8"].masonry-wrapper .grid-item.large {
        width: 25%
    }

    [data-col-xs="8"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="9"].masonry-wrapper .grid-item, [data-col-xs="9"].masonry-wrapper .grid-sizer {
    width: 11.11111%
}

    [data-col-xs="9"].masonry-wrapper .grid-item.wide, [data-col-xs="9"].masonry-wrapper .grid-item.large {
        width: 22.22222%
    }

    [data-col-xs="9"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

[data-col-xs="10"].masonry-wrapper .grid-item, [data-col-xs="10"].masonry-wrapper .grid-sizer {
    width: 10%
}

    [data-col-xs="10"].masonry-wrapper .grid-item.wide, [data-col-xs="10"].masonry-wrapper .grid-item.large {
        width: 20%
    }

    [data-col-xs="10"].masonry-wrapper .grid-item.extra-large {
        width: 100%
    }

@media (min-width:768px) {
    [data-col-sm="1"].masonry-wrapper .grid-item, [data-col-sm="1"].masonry-wrapper .grid-sizer {
        width: 100%
    }
}

@media (min-width:768px) {
    [data-col-sm="2"].masonry-wrapper .grid-item, [data-col-sm="2"].masonry-wrapper .grid-sizer {
        width: 50%
    }

        [data-col-sm="2"].masonry-wrapper .grid-item.wide, [data-col-sm="2"].masonry-wrapper .grid-item.large {
            width: 100%
        }

        [data-col-sm="2"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="3"].masonry-wrapper .grid-item, [data-col-sm="3"].masonry-wrapper .grid-sizer {
        width: 33.33333%
    }

        [data-col-sm="3"].masonry-wrapper .grid-item.wide, [data-col-sm="3"].masonry-wrapper .grid-item.large {
            width: 66.66667%
        }

        [data-col-sm="3"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="4"].masonry-wrapper .grid-item, [data-col-sm="4"].masonry-wrapper .grid-sizer {
        width: 25%
    }

        [data-col-sm="4"].masonry-wrapper .grid-item.wide, [data-col-sm="4"].masonry-wrapper .grid-item.large {
            width: 50%
        }

        [data-col-sm="4"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="5"].masonry-wrapper .grid-item, [data-col-sm="5"].masonry-wrapper .grid-sizer {
        width: 20%
    }

        [data-col-sm="5"].masonry-wrapper .grid-item.wide, [data-col-sm="5"].masonry-wrapper .grid-item.large {
            width: 40%
        }

        [data-col-sm="5"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="6"].masonry-wrapper .grid-item, [data-col-sm="6"].masonry-wrapper .grid-sizer {
        width: 16.66667%
    }

        [data-col-sm="6"].masonry-wrapper .grid-item.wide, [data-col-sm="6"].masonry-wrapper .grid-item.large {
            width: 33.33333%
        }

        [data-col-sm="6"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="7"].masonry-wrapper .grid-item, [data-col-sm="7"].masonry-wrapper .grid-sizer {
        width: 14.28571%
    }

        [data-col-sm="7"].masonry-wrapper .grid-item.wide, [data-col-sm="7"].masonry-wrapper .grid-item.large {
            width: 28.57143%
        }

        [data-col-sm="7"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="8"].masonry-wrapper .grid-item, [data-col-sm="8"].masonry-wrapper .grid-sizer {
        width: 12.5%
    }

        [data-col-sm="8"].masonry-wrapper .grid-item.wide, [data-col-sm="8"].masonry-wrapper .grid-item.large {
            width: 25%
        }

        [data-col-sm="8"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="9"].masonry-wrapper .grid-item, [data-col-sm="9"].masonry-wrapper .grid-sizer {
        width: 11.11111%
    }

        [data-col-sm="9"].masonry-wrapper .grid-item.wide, [data-col-sm="9"].masonry-wrapper .grid-item.large {
            width: 22.22222%
        }

        [data-col-sm="9"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:768px) {
    [data-col-sm="10"].masonry-wrapper .grid-item, [data-col-sm="10"].masonry-wrapper .grid-sizer {
        width: 10%
    }

        [data-col-sm="10"].masonry-wrapper .grid-item.wide, [data-col-sm="10"].masonry-wrapper .grid-item.large {
            width: 20%
        }

        [data-col-sm="10"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="1"].masonry-wrapper .grid-item, [data-col-md="1"].masonry-wrapper .grid-sizer {
        width: 100%
    }
}

@media (min-width:992px) {
    [data-col-md="2"].masonry-wrapper .grid-item, [data-col-md="2"].masonry-wrapper .grid-sizer {
        width: 50%
    }

        [data-col-md="2"].masonry-wrapper .grid-item.wide, [data-col-md="2"].masonry-wrapper .grid-item.large {
            width: 100%
        }

        [data-col-md="2"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="3"].masonry-wrapper .grid-item, [data-col-md="3"].masonry-wrapper .grid-sizer {
        width: 33.33333%
    }

        [data-col-md="3"].masonry-wrapper .grid-item.wide, [data-col-md="3"].masonry-wrapper .grid-item.large {
            width: 66.66667%
        }

        [data-col-md="3"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="4"].masonry-wrapper .grid-item, [data-col-md="4"].masonry-wrapper .grid-sizer {
        width: 25%
    }

        [data-col-md="4"].masonry-wrapper .grid-item.wide, [data-col-md="4"].masonry-wrapper .grid-item.large {
            width: 50%
        }

        [data-col-md="4"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="5"].masonry-wrapper .grid-item, [data-col-md="5"].masonry-wrapper .grid-sizer {
        width: 20%
    }

        [data-col-md="5"].masonry-wrapper .grid-item.wide, [data-col-md="5"].masonry-wrapper .grid-item.large {
            width: 40%
        }

        [data-col-md="5"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="6"].masonry-wrapper .grid-item, [data-col-md="6"].masonry-wrapper .grid-sizer {
        width: 16.66667%
    }

        [data-col-md="6"].masonry-wrapper .grid-item.wide, [data-col-md="6"].masonry-wrapper .grid-item.large {
            width: 33.33333%
        }

        [data-col-md="6"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="7"].masonry-wrapper .grid-item, [data-col-md="7"].masonry-wrapper .grid-sizer {
        width: 14.28571%
    }

        [data-col-md="7"].masonry-wrapper .grid-item.wide, [data-col-md="7"].masonry-wrapper .grid-item.large {
            width: 28.57143%
        }

        [data-col-md="7"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="8"].masonry-wrapper .grid-item, [data-col-md="8"].masonry-wrapper .grid-sizer {
        width: 12.5%
    }

        [data-col-md="8"].masonry-wrapper .grid-item.wide, [data-col-md="8"].masonry-wrapper .grid-item.large {
            width: 25%
        }

        [data-col-md="8"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="9"].masonry-wrapper .grid-item, [data-col-md="9"].masonry-wrapper .grid-sizer {
        width: 11.11111%
    }

        [data-col-md="9"].masonry-wrapper .grid-item.wide, [data-col-md="9"].masonry-wrapper .grid-item.large {
            width: 22.22222%
        }

        [data-col-md="9"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:992px) {
    [data-col-md="10"].masonry-wrapper .grid-item, [data-col-md="10"].masonry-wrapper .grid-sizer {
        width: 10%
    }

        [data-col-md="10"].masonry-wrapper .grid-item.wide, [data-col-md="10"].masonry-wrapper .grid-item.large {
            width: 20%
        }

        [data-col-md="10"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="1"].masonry-wrapper .grid-item, [data-col-lg="1"].masonry-wrapper .grid-sizer {
        width: 100%
    }
}

@media (min-width:1200px) {
    [data-col-lg="2"].masonry-wrapper .grid-item, [data-col-lg="2"].masonry-wrapper .grid-sizer {
        width: 50%
    }

        [data-col-lg="2"].masonry-wrapper .grid-item.wide, [data-col-lg="2"].masonry-wrapper .grid-item.large {
            width: 100%
        }

        [data-col-lg="2"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="3"].masonry-wrapper .grid-item, [data-col-lg="3"].masonry-wrapper .grid-sizer {
        width: 33.33333%
    }

        [data-col-lg="3"].masonry-wrapper .grid-item.wide, [data-col-lg="3"].masonry-wrapper .grid-item.large {
            width: 66.66667%
        }

        [data-col-lg="3"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="4"].masonry-wrapper .grid-item, [data-col-lg="4"].masonry-wrapper .grid-sizer {
        width: 25%
    }

        [data-col-lg="4"].masonry-wrapper .grid-item.wide, [data-col-lg="4"].masonry-wrapper .grid-item.large {
            width: 50%
        }

        [data-col-lg="4"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="5"].masonry-wrapper .grid-item, [data-col-lg="5"].masonry-wrapper .grid-sizer {
        width: 20%
    }

        [data-col-lg="5"].masonry-wrapper .grid-item.wide, [data-col-lg="5"].masonry-wrapper .grid-item.large {
            width: 40%
        }

        [data-col-lg="5"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="6"].masonry-wrapper .grid-item, [data-col-lg="6"].masonry-wrapper .grid-sizer {
        width: 16.66667%
    }

        [data-col-lg="6"].masonry-wrapper .grid-item.wide, [data-col-lg="6"].masonry-wrapper .grid-item.large {
            width: 33.33333%
        }

        [data-col-lg="6"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="7"].masonry-wrapper .grid-item, [data-col-lg="7"].masonry-wrapper .grid-sizer {
        width: 14.28571%
    }

        [data-col-lg="7"].masonry-wrapper .grid-item.wide, [data-col-lg="7"].masonry-wrapper .grid-item.large {
            width: 28.57143%
        }

        [data-col-lg="7"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="8"].masonry-wrapper .grid-item, [data-col-lg="8"].masonry-wrapper .grid-sizer {
        width: 12.5%
    }

        [data-col-lg="8"].masonry-wrapper .grid-item.wide, [data-col-lg="8"].masonry-wrapper .grid-item.large {
            width: 25%
        }

        [data-col-lg="8"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="9"].masonry-wrapper .grid-item, [data-col-lg="9"].masonry-wrapper .grid-sizer {
        width: 11.11111%
    }

        [data-col-lg="9"].masonry-wrapper .grid-item.wide, [data-col-lg="9"].masonry-wrapper .grid-item.large {
            width: 22.22222%
        }

        [data-col-lg="9"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

@media (min-width:1200px) {
    [data-col-lg="10"].masonry-wrapper .grid-item, [data-col-lg="10"].masonry-wrapper .grid-sizer {
        width: 10%
    }

        [data-col-lg="10"].masonry-wrapper .grid-item.wide, [data-col-lg="10"].masonry-wrapper .grid-item.large {
            width: 20%
        }

        [data-col-lg="10"].masonry-wrapper .grid-item.extra-large {
            width: 100%
        }
}

[data-gap="5"].masonry-wrapper {
    margin: -3px
}

    [data-gap="5"].masonry-wrapper .grid-item .grid-item__content-wrapper {
        margin: 3px;
        top: 3px;
        right: 3px;
        bottom: 3px;
        left: 3px
    }

[data-gap="10"].masonry-wrapper {
    margin: -5px
}

    [data-gap="10"].masonry-wrapper .grid-item .grid-item__content-wrapper {
        margin: 5px;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px
    }

[data-gap="15"].masonry-wrapper {
    margin: -8px
}

    [data-gap="15"].masonry-wrapper .grid-item .grid-item__content-wrapper {
        margin: 8px;
        top: 8px;
        right: 8px;
        bottom: 8px;
        left: 8px
    }

[data-gap="20"].masonry-wrapper {
    margin: -10px
}

    [data-gap="20"].masonry-wrapper .grid-item .grid-item__content-wrapper {
        margin: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px
    }

[data-gap="25"].masonry-wrapper {
    margin: -13px
}

    [data-gap="25"].masonry-wrapper .grid-item .grid-item__content-wrapper {
        margin: 13px;
        top: 13px;
        right: 13px;
        bottom: 13px;
        left: 13px
    }

[data-gap="30"].masonry-wrapper {
    margin: -15px
}

    [data-gap="30"].masonry-wrapper .grid-item .grid-item__content-wrapper {
        margin: 15px;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px
    }

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
    display: block
}

audio, canvas, progress, video {
    display: inline-block
}

    audio:not([controls]) {
        display: none;
        height: 0
    }

progress {
    vertical-align: baseline
}

template, [hidden] {
    display: none
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

    a:active, a:hover {
        outline-width: 0
    }

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b, strong {
    font-weight: inherit
}

b, strong {
    font-weight: bolder
}

dfn {
    font-style: italic
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

mark {
    background-color: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

img {
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

code, kbd, pre, samp {
    font-family: monospace,monospace;
    font-size: 1em
}

figure {
    margin: 1em 40px
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

button, input, select, textarea {
    font: inherit;
    margin: 0
}

optgroup {
    font-weight: 700
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button
}

    button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
        border-style: none;
        padding: 0
    }

    button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
        outline: 1px dotted ButtonText
    }

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

textarea {
    overflow: auto
}

[type="checkbox"], [type="radio"] {
    box-sizing: border-box;
    padding: 0
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
    height: auto
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

    [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none
    }

::-webkit-input-placeholder {
    color: inherit;
    opacity: .54
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

* {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400
}

h1, h2, h3, h4, h5, h6 {
    position: relative;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
    color: #656565;
    font-family: "Barlow", sans-serif
}

    h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
        color: inherit
    }

p {
    font-size: 14px;
    line-height: 1.6em;
    color: #403c34
}

    p span {
        font-family: inherit;
        color: inherit;
        font-size: inherit
    }

a {
    position: relative;
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    a:hover {
        color: #df1166
    }

a, input, textarea, button, select {
    outline: none
}

    a:hover, h1:focus, h2:focus, h3:focus, h4:focus, h5:focus, h6:focus {
        text-decoration: none
    }

    a:focus, button:focus, select:focus, input:focus, textarea:focus {
        outline: none;
        text-decoration: none
    }

img {
    max-width: 100%
}

iframe {
    border: 0;
    max-width: 100%;
    width: 100%
}

.ps-heading--2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #323232;
    font-weight: 500
}

body {
    overflow-x: hidden
}

.ps-container::after {
    clear: both;
    content: "";
    display: table
}

@media (min-width:320px) {
    .ps-container {
        margin: 0 auto;
        width: 100%;
        padding: 0 15px
    }
}

@media (max-width:768px) {
    .ps-product__thumbnail {
        height: 260px
    }
}

@media (min-width:768px) {
    .ps-container {
        width: 100%;
        padding: 0 30px
    }
}

@media (min-width:1440px) {
    .ps-container {
        width: 1440px
    }
}

main.ps-main {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

@media (max-width:767px) {
    .ps-col-tiny .col-xs-12 {
        width: 50%
    }

    .ps-sidebar {
        display: none;
        position: absolute !important;
        top: 0;
        right: 0;
        border: 1px solid #dedede;
        background-color: #fff;
        padding-top: 20px
    }
}

@media (max-width:479px) {
    .ps-col-tiny .col-xs-12 {
        width: 100%
    }

    .ps-sidebar {
        display: none;
        position: absolute !important;
        top: 0;
        right: 0;
        border: 1px solid #dedede;
        background-color: #fff;
        padding-top: 20px
    }
}

.ps-masonry--flex[data-item="4"] {
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column wrap;
    -moz-flex-flow: column wrap;
    flex-flow: column wrap;
    width: 100%;
    height: 500px
}

    .ps-masonry--flex[data-item="4"] .ps-masonry__item {
        width: 25%
    }

        .ps-masonry--flex[data-item="4"] .ps-masonry__item.wide {
            width: 50%
        }

.ps-template-3 {
    padding-left: 300px
}

    .ps-template-3 .ps-container {
        width: 100%;
        padding: 0 50px
    }

@media (max-width:1199px) {
    .ps-template-3 {
        padding-left: 0
    }
}

@media (max-width:991px) {
    .ps-template-3 .ps-container {
        padding: 0 30px
    }

    .ps-sidebar {
        display: none;
        position: absolute !important;
        top: 0;
        right: 0;
        border: 1px solid #dedede;
        background-color: #fff;
        padding-top: 20px
    }
}

@media (max-width:767px) {
    .ps-template-3 .ps-container {
        padding: 0 15px
    }
}

.ps-products-listing {
    padding-top: 10px
}

    .ps-products-listing::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-products-listing .ps-sidebar {
        position: relative;
        width: 290px;
        float: left;
        padding-left: 30px;
        padding-right: 30px;
    }

    .ps-products-listing .ps-content {
        float: right;
        padding: 0 30px;
        width: calc(100% - 290px)
    }

@media (max-width:1199px) {
    .ps-products-listing .ps-sidebar {
        float: none;
        width: 100%;
        max-width: 300px
    }

    .ps-products-listing .ps-content {
        float: none;
        width: 100%;
        padding: 0 15px
    }
}

.ps-products-listing.inverse .ps-sidebar {
    padding-left: 0
}

.ps-products-listing.inverse .ps-content {
    float: left
}

@media (max-width:1199px) {
    .ps-products-listing.inverse .ps-sidebar {
        padding-left: 30px
    }
}

@media (max-width:767px) {
    .ps-products-listing {
        padding-top: 30px
    }
}

.ps-social li {
    display: inline-block;
    margin-right: 10px
}

    .ps-social li a {
        display: block;
        position: relative;
        width: 40px;
        height: 40px;
        background-color: #b4b4b4;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        -webkit-transition: all 0.4s cubic-bezier(.77,0,.175,1);
        -moz-transition: all 0.4s cubic-bezier(.77,0,.175,1);
        transition: all 0.4s cubic-bezier(.77,0,.175,1)
    }

        .ps-social li a img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            color: #fff
        }

        .ps-social li a:hover {
            background-color: #df1166
        }

    .ps-social li:last-child {
        margin-right: 0
    }

.ps-list--line li a {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    line-height: 2.4em;
    letter-spacing: .3px;
    text-align: left;
    color: #656565;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .ps-list--line li a:before {
        content: '';
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        width: 0;
        height: 2px;
        background-color: #df1166;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

.ps-list--line li:hover > a {
    padding-left: 15px;
    color: #df1166
}

    .ps-list--line li:hover > a:before {
        width: 10px;
        -webkit-transition-delay: 0.1s;
        -moz-transition-delay: 0.1s;
        transition-delay: 0.1s
    }

.ps-list--social {
    display: block;
    text-align: center
}

    .ps-list--social li {
        display: inline-block;
        margin-right: 10px
    }

        .ps-list--social li a {
            position: relative;
            display: inline-block;
            width: 35px;
            height: 35px;
            color: #fff;
            background-color: #b4b4b4;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%
        }

            .ps-list--social li a i {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -moz-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                -o-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                color: #2b3147
            }

            .ps-list--social li a:hover {
                background-color: #df1166
            }

        .ps-list--social li:last-child {
            margin-right: 10px
        }

.ps-list--arrow a {
    position: relative;
    display: block;
    padding-left: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    letter-spacing: .5px;
    color: #626262
}

    .ps-list--arrow a:before {
        content: "\f105";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        font-family: FontAwesome
    }

    .ps-list--arrow a:hover {
        color: #df1166
    }

        .ps-list--arrow a:hover .circle {
            background-color: #ea1e63;
            border-color: #ea1e63
        }

            .ps-list--arrow a:hover .circle:before {
                color: #fff;
                visibility: visible;
                opacity: 1
            }

.ps-list--arrow li {
    margin-bottom: 20px
}

    .ps-list--arrow li.current a {
        color: #ea1e63
    }

    .ps-list--arrow li.current .circle {
        background-color: #ea1e63
    }

        .ps-list--arrow li.current .circle:before {
            visibility: visible;
            opacity: 1;
            color: #fff
        }

.ps-list--number {
    list-style: decimal
}

    .ps-list--number li {
        margin-bottom: 10px
    }

.ps-list--checked li {
    margin-bottom: 20px
}

    .ps-list--checked li a {
        position: relative;
        display: block;
        padding-left: 30px;
        font-size: 14px;
        color: #313131
    }

        .ps-list--checked li a:before {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 20px;
            height: 20px;
            border: solid 1px #8d8d8d;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

        .ps-list--checked li a:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 8px;
            color: #fff;
            width: 6px;
            height: 10px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: hidden;
            opacity: 0
        }

        .ps-list--checked li a:hover:before {
            background-color: #df1166;
            border-color: #df1166
        }

        .ps-list--checked li a:hover:after {
            visibility: visible;
            opacity: 1
        }

    .ps-list--checked li.current a:before {
        background-color: #df1166;
        border-color: #df1166
    }

    .ps-list--checked li.current a:after {
        visibility: visible;
        opacity: 1
    }

.ps-list--color li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px
}

    .ps-list--color li a {
        display: inline-block;
        width: 25px;
        height: 25px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        background-color: #50cf96
    }

    .ps-list--color li:nth-child(2) a {
        background-color: #d0011b
    }

    .ps-list--color li:nth-child(3) a {
        background-color: #f8e81c
    }

    .ps-list--color li:nth-child(4) a {
        background-color: #8b572a
    }

    .ps-list--color li:nth-child(5) a {
        background-color: #bd0fe1
    }

    .ps-list--color li:nth-child(6) a {
        background-color: #4990e2
    }

    .ps-list--color li:nth-child(7) a {
        background-color: #000
    }

    .ps-list--color li:nth-child(8) a {
        background-color: #000
    }

    .ps-list--color li:nth-child(9) a {
        background-color: #cfb850
    }

    .ps-list--color li:nth-child(10) a {
        background-color: #50cacf
    }

    .ps-list--color li:nth-child(11) a {
        background-color: #e29849
    }

    .ps-list--color li:nth-child(12) a {
        background-color: #50cf96
    }

    .ps-list--color li:nth-child(13) a {
        background-color: #50cf96
    }

.ps-list--filter li a {
    line-height: 30px;
    color: #777
}

    .ps-list--filter li a:hover {
        color: #df1166
    }

.ps-list--filter li.current a {
    color: #df1166
}

.top-filter {
    display: inline;
    float: right
}

    .top-filter span {
        font-weight: 500;
    }

.menu-toggle {
    position: relative;
    display: inline-block;
    display: none;
    width: 40px;
    height: 40px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%
}

    .menu-toggle span, .menu-toggle:before, .menu-toggle:after {
        position: absolute;
        right: 10px;
        height: 3px;
        width: 20px;
        background-color: #DF1166;
        z-index: 100;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

    .menu-toggle span {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .menu-toggle:before, .menu-toggle:after {
        content: ''
    }

    .menu-toggle:before {
        top: 12px
    }

    .menu-toggle:after {
        bottom: 12px
    }

    .menu-toggle:hover {
        cursor: pointer
    }

    .menu-toggle.active span {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0
    }

    .menu-toggle.active:before, .menu-toggle.active:after {
        top: 50%;
        bottom: auto;
        width: 20px;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        background-color: #fc354c
    }

    .menu-toggle.active:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    .menu-toggle.active:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

@media (max-width:1199px) {
    .menu-toggle {
        display: inline-block
    }
}

.ps-btn, button.ps-btn {
    display: inline-block;
    padding: 10px 30px;
    background-color: #df1166;
    border: 5px solid #df1166;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .ps-btn:hover, button.ps-btn:hover {
        color: #fff;
        background-color: #333;
        border: 5px solid #df1166
    }

.ps-btn--outline, button.ps-btn--outline {
    padding: 15px 30px;
    background-color: #fff;
    border: 2px solid #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: .05em;
    text-transform: uppercase;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .ps-btn--outline:before, .ps-btn--outline:after, button.ps-btn--outline:before, button.ps-btn--outline:after {
        display: none
    }

    .ps-btn--outline:hover, button.ps-btn--outline:hover {
        color: #fff;
        background-color: #df1166;
        border-color: #df1166
    }

.ps-btn--favorite {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #999;
    border: none;
    vertical-align: top;
    font-size: 20px
}

    .ps-btn--favorite i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        color: #fff
    }

    .ps-btn--favorite:hover {
        background-color: #df1166
    }

.ps-btn--fullwidth {
    width: 100%;
    text-align: center
}

.ps-btn--gray, button.ps-btn--gray {
    background-color: #e5e5e5;
    border: none;
    color: #000;
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center
}

    .ps-btn--gray:hover, button.ps-btn--gray:hover {
        background-color: #df1166
    }

.form-control {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    height: 50px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0
}

    .form-control:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none;
        border-color: #df1166
    }

.textarea.form-control {
    height: auto
}

.ps-input--has-icon {
    position: relative
}

    .ps-input--has-icon > i {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 15px
    }

.ps-select > i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px
}

.ps-select > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none
}

.ps-checkbox {
    position: relative;
    display: block
}

    .ps-checkbox > input {
        position: absolute;
        visibility: hidden;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }

    .ps-checkbox label {
        position: relative;
        padding-left: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #737373;
        font-weight: 400;
        cursor: pointer
    }

        .ps-checkbox label:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            z-index: 10;
            border: 1px solid #ccc;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

        .ps-checkbox label:after {
            content: '';
            display: block;
            position: absolute;
            top: 4px;
            left: 7px;
            width: 6px;
            height: 10px;
            border: 2px solid #df1166;
            border-top: none;
            border-left: none;
            z-index: 5;
            opacity: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

    .ps-checkbox input[type=checkbox]:checked ~ label:before {
        border-color: #df1166
    }

    .ps-checkbox input[type=checkbox]:checked ~ label:after {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 1
    }

.ps-checkbox--inline {
    display: inline-block
}

.ps-select {
    display: inline-block
}

    .ps-select button.btn.dropdown-toggle.btn-default {
        height: 50px;
        border: solid 1px #979797;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        outline: none;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
        color: #303030
    }

        .ps-select button.btn.dropdown-toggle.btn-default:hover {
            outline: none !important;
            background-color: #fff
        }

        .ps-select button.btn.dropdown-toggle.btn-default:focus, .ps-select button.btn.dropdown-toggle.btn-default:active {
            outline: none !important;
            background-color: #fff
        }

.ps-radio {
    position: relative;
    line-height: 30px
}

    .ps-radio > input {
        position: absolute;
        visibility: hidden;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: none
    }

    .ps-radio label {
        margin: 0;
        position: relative;
        padding-left: 30px;
        font-family: 'Montserrat', sans-serif;
        color: #737373;
        font-weight: 400;
        line-height: 30px;
        vertical-align: top;
        cursor: pointer;
    }

        .ps-radio label:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
            height: 20px;
            width: 20px;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease;
            border: 1px solid #ddd;
        }

/*.ps-radio label:after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 5px;
            width: 20px;
            height: 20px;
            background-color: #aeaeae;
            z-index: 20;
            opacity: 0;
            -webkit-transform: scale(1.5,1.5);
            -moz-transform: scale(1.5,1.5);
            -ms-transform: scale(1.5,1.5);
            -o-transform: scale(1.5,1.5);
            transform: scale(1.5,1.5);
            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            transition: all 0.6s ease
        }*/

/*.ps-radio input[type=radio]:checked ~ label:before {
        background-color: #fff;
        -webkit-box-shadow: 0 0 30px 2px rgba(255,169,39,.3);
        -moz-box-shadow: 0 0 30px 2px rgba(255,169,39,.3);
        -ms-box-shadow: 0 0 30px 2px rgba(255,169,39,.3);
        box-shadow: 0 0 30px 2px rgba(255,169,39,.3)
    }*/

/*.ps-radio input[type=radio]:checked ~ label:after {
        -webkit-transform: scale(1,1);
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        transform: scale(1,1);
        opacity: 1;
        background-color: #bfce5b
    }*/

.ps-radio--inline {
    display: inline-block;
}

    .ps-radio--inline:last-child {
        margin-right: 0
    }

.ps-radio--square {
    line-height: 35px
}

    .ps-radio--square label:before {
        width: 36px;
        height: 36px;
        background-color: #fff;
        border-color: #e0e8f6
    }

    .ps-radio--square label:after {
        top: 8px;
        left: 8px;
        opacity: 1;
        -webkit-transform: scale(1,1);
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        transform: scale(1,1);
        background-color: #bfce5b
    }

    .ps-radio--square label:before, .ps-radio--square label:after {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }

    .ps-radio--square input[type=radio]:checked ~ label:before {
        border-color: #bfce5b
    }

.ps-radio--square-2 label:before {
    border-width: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px
}

.ps-radio--checked {
    padding: 20px 0;
    border-bottom: 1px solid #eef2f7
}

    .ps-radio--checked > label {
        position: relative;
        display: block;
        line-height: 15px;
        font-size: 14px;
        color: #212121;
        padding-left: 30px
    }

        .ps-radio--checked > label span {
            float: right;
            color: #a9bcd7
        }

        .ps-radio--checked > label:before {
            width: 15px;
            height: 15px;
            border: 1px solid #a9bcd7;
            -webkit-border-radius: 1px;
            -moz-border-radius: 1px;
            -ms-border-radius: 1px;
            border-radius: 1px;
            background-color: #fff
        }

        .ps-radio--checked > label:after {
            top: 2px;
            left: 5px;
            width: 5px;
            height: 8px;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            border-radius: 0;
            border-bottom: 2px solid #212121;
            border-right: 2px solid #212121;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease
        }

    .ps-radio--checked input[type=radio]:checked ~ label {
        color: #df1166
    }

        .ps-radio--checked input[type=radio]:checked ~ label span {
            color: #df1166
        }

        .ps-radio--checked input[type=radio]:checked ~ label:before {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -ms-box-shadow: none;
            box-shadow: none;
            border-color: #212121
        }

        .ps-radio--checked input[type=radio]:checked ~ label:after {
            background-color: transparent;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg)
        }

    .ps-radio--checked:last-child {
        border-bottom-width: 0
    }

.ps-radio--circle {
    padding: 20px 0;
    border-bottom: 1px solid #eef2f7
}

    .ps-radio--circle > label {
        position: relative;
        display: block;
        padding-left: 30px;
        line-height: 15px;
        font-size: 14px;
        color: #212121
    }

        .ps-radio--circle > label span {
            float: right;
            color: #a9bcd7
        }

        .ps-radio--circle > label:before {
            width: 16px;
            height: 16px;
            background-color: #fff;
            border: 1px solid #a9bcd7;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%
        }

        .ps-radio--circle > label:after {
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            border-bottom: 2px solid #212121;
            border-right: 2px solid #212121;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease
        }

    .ps-radio--circle input[type=radio]:checked ~ label {
        color: #df1166
    }

        .ps-radio--circle input[type=radio]:checked ~ label span {
            color: #df1166
        }

        .ps-radio--circle input[type=radio]:checked ~ label:before {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -ms-box-shadow: none;
            box-shadow: none;
            border-color: #212121
        }

        .ps-radio--circle input[type=radio]:checked ~ label:after {
            background-color: #212121
        }

    .ps-radio--circle:last-child {
        border-bottom-width: 0
    }

.ps-radio--small > label {
    padding-left: 30px
}

    .ps-radio--small > label:before {
        top: 5px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-color: #fff
    }

    .ps-radio--small > label:after {
        top: 10px;
        width: 10px;
        height: 10px
    }

.ps-radio--color1 label:before {
    border: 1px solid #ffa0ac;
    background-color: #ffa0ac
}

.ps-radio--color1 input[type=radio]:checked ~ label:after {
    background-color: #ffa0ac
}

.ps-radio--color1.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color1.ps-radio--square label:after {
    background-color: #ffa0ac
}

.ps-radio--color1.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #ffa0ac
}

.ps-radio--color1.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color2 label:before {
    border: 1px solid #f3cb53;
    background-color: #f3cb53
}

.ps-radio--color2 input[type=radio]:checked ~ label:after {
    background-color: #f3cb53
}

.ps-radio--color2.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color2.ps-radio--square label:after {
    background-color: #f3cb53
}

.ps-radio--color2.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #f3cb53
}

.ps-radio--color2.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color3 label:before {
    border: 1px solid #77cbbc;
    background-color: #77cbbc
}

.ps-radio--color3 input[type=radio]:checked ~ label:after {
    background-color: #77cbbc
}

.ps-radio--color3.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color3.ps-radio--square label:after {
    background-color: #77cbbc
}

.ps-radio--color3.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #77cbbc
}

.ps-radio--color3.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color4 label:before {
    border: 1px solid #ff8a65;
    background-color: #ff8a65
}

.ps-radio--color4 input[type=radio]:checked ~ label:after {
    background-color: #ff8a65
}

.ps-radio--color4.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color4.ps-radio--square label:after {
    background-color: #ff8a65
}

.ps-radio--color4.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #ff8a65
}

.ps-radio--color4.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color5 label:before {
    border: 1px solid #a0abaf;
    background-color: #a0abaf
}

.ps-radio--color5 input[type=radio]:checked ~ label:after {
    background-color: #a0abaf
}

.ps-radio--color5.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color5.ps-radio--square label:after {
    background-color: #a0abaf
}

.ps-radio--color5.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #a0abaf
}

.ps-radio--color5.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color6 label:before {
    border: 1px solid #a078be;
    background-color: #a078be
}

.ps-radio--color6 input[type=radio]:checked ~ label:after {
    background-color: #a078be
}

.ps-radio--color6.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color6.ps-radio--square label:after {
    background-color: #a078be
}

.ps-radio--color6.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #a078be
}

.ps-radio--color6.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color7 label:before {
    border: 1px solid #f48c73;
    background-color: #f48c73
}

.ps-radio--color7 input[type=radio]:checked ~ label:after {
    background-color: #f48c73
}

.ps-radio--color7.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color7.ps-radio--square label:after {
    background-color: #f48c73
}

.ps-radio--color7.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #f48c73
}

.ps-radio--color7.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color8 label:before {
    border: 1px solid #f4c7b0;
    background-color: #f4c7b0
}

.ps-radio--color8 input[type=radio]:checked ~ label:after {
    background-color: #f4c7b0
}

.ps-radio--color8.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color8.ps-radio--square label:after {
    background-color: #f4c7b0
}

.ps-radio--color8.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #f4c7b0
}

.ps-radio--color8.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color9 label:before {
    border: 1px solid #525252;
    background-color: #525252
}

.ps-radio--color9 input[type=radio]:checked ~ label:after {
    background-color: #525252
}

.ps-radio--color9.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color9.ps-radio--square label:after {
    background-color: #525252
}

.ps-radio--color9.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #525252
}

.ps-radio--color9.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-radio--color10 label:before {
    border: 1px solid #6e8ccf;
    background-color: #6e8ccf
}

.ps-radio--color10 input[type=radio]:checked ~ label:after {
    background-color: #6e8ccf
}

.ps-radio--color10.ps-radio--square label:before {
    background-color: #fff;
    border-color: #e0e8f6
}

.ps-radio--color10.ps-radio--square label:after {
    background-color: #6e8ccf
}

.ps-radio--color10.ps-radio--square input[type=radio]:checked ~ label:before {
    border-color: #6e8ccf
}

.ps-radio--color10.ps-radio--square-2 label:before {
    border-width: 2px
}

.ps-number {
    position: relative
}

    .ps-number span {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 50%;
        border-left: 1px solid #979797;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

        .ps-number span:before {
            font-family: FontAwesome;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            color: #303030
        }

        .ps-number span.up {
            border-bottom: 1px solid #979797
        }

            .ps-number span.up:before {
                content: "\f106"
            }

        .ps-number span.down {
            top: auto;
            bottom: 0
        }

            .ps-number span.down:before {
                content: "\f107"
            }

        .ps-number span:hover {
            cursor: pointer;
            background-color: #df1166
        }

            .ps-number span:hover:before {
                color: #fff
            }

.form-group--inline {
    position: relative
}

    .form-group--inline p {
        margin: 0
    }

    .form-group--inline label {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-bottom: 0;
        left: 0;
        font-size: 16px;
        line-height: 1.3em;
        color: #5b5b5b;
        font-weight: 400
    }

    .form-group--inline.textarea label {
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
        -o-transform: translateY(50%);
        transform: translateY(50%);
        top: 0
    }

@media (max-width:1199px) {
    .form-group--inline {
        padding-left: 0
    }

        .form-group--inline label {
            position: static;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0)
        }

        .form-group--inline.textarea label {
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0)
        }
}

.le-quantity {
    display: inline-block
}

    .le-quantity .input-group-btn {
        display: inline-block
    }

    .le-quantity input.form-control {
        position: relative;
        top: -2px;
        margin-right: -4px;
        display: inline-block;
        width: 130px;
        height: 40px;
        text-align: center;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }

        .le-quantity input.form-control:focus {
            border-color: #f1f1f1
        }

    .le-quantity button {
        display: inline-block;
        height: 40px;
        width: 40px;
        border: none;
        background-color: #283044;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        font-size: 20px;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

        .le-quantity button:hover {
            background-color: #df1166
        }

        .le-quantity button.minus {
            float: left
        }

.ps-remove {
    position: relative;
    display: inline-block;
    background-color: #f1f1f1;
    width: 30px;
    height: 30px;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%
}

    .ps-remove:before, .ps-remove:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 2px;
        background-color: #b1b1b1;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

    .ps-remove:before {
        -webkit-transform: translate(-50%,-50%) rotate(-45deg);
        -moz-transform: translate(-50%,-50%) rotate(-45deg);
        -ms-transform: translate(-50%,-50%) rotate(-45deg);
        -o-transform: translate(-50%,-50%) rotate(-45deg);
        transform: translate(-50%,-50%) rotate(-45deg)
    }

    .ps-remove:after {
        -webkit-transform: translate(-50%,-50%) rotate(45deg);
        -moz-transform: translate(-50%,-50%) rotate(45deg);
        -ms-transform: translate(-50%,-50%) rotate(45deg);
        -o-transform: translate(-50%,-50%) rotate(45deg);
        transform: translate(-50%,-50%) rotate(45deg)
    }

    .ps-remove:hover {
        background-color: #333
    }

        .ps-remove:hover:before, .ps-remove:hover:after {
            background-color: #fff
        }

.ps-form--icon {
    position: relative
}

    .ps-form--icon > i {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 20px
    }

.error {
    color: red
}

.ps-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 75px;
    height: 62px;
    z-index: 20;
}

.ps-badge-50 {
    background-image: url('/Content/theme/images/50sticker.png');
}

.ps-badge-20 {
    background-image: url('/Content/theme/images/20sticker.png');
}


.ps-badge span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: #ff2756;
    text-transform: uppercase
}

.ps-badge--sale-off {
    border-color: #df1166
}

    .ps-badge--sale-off span {
        color: #df1166
    }

/*2.5 badge*/
.ps-badge-2 {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 52px;
    height: 20px;
    z-index: 20;
    background: #df1166;
    color: white;
}

    .ps-badge-2 span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

.ps-badge--sale-off-2 {
    border-color: #df1166;
}

    .ps-badge--sale-off-2 span {
        color: #df1166;
    }



.ps-iconbox {
    margin: 0 auto 30px;
    max-width: 480px;
    padding: 0 30px;
    text-align: center
}

    .ps-iconbox i {
        display: inline-block;
        font-size: 55px;
        margin-bottom: 20px
    }

        .ps-iconbox i:before {
            color: #fff
        }

    .ps-iconbox h3 {
        margin-bottom: 0;
        font-size: 24px;
        line-height: 1em;
        color: #df1166
    }



    .ps-iconbox header {
        margin-bottom: 10px
    }

        .ps-iconbox header img {
            margin-bottom: 15px;
            width: 64px
        }

        .ps-iconbox header p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.71;
            color: #656565;
            font-weight: 700;
            font-family: "Barlow", sans-serif
        }

    .ps-iconbox p {
        color: #5b5b5b
    }

@media (max-width:479px) {
    .ps-iconbox p {
        font-size: 14px
    }
}

.ps-post__thumbnail {
    position: relative
}

    .ps-post__thumbnail .ps-post__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10
    }

    .ps-post__thumbnail:hover .ps-post__overlay {
        background-color: rgba(255,255,255,.5)
    }

.ps-post__thumbnail {
    position: relative
}

    .ps-post__thumbnail img {
        width: 100%
    }

.ps-post__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10
}

.ps-post--home {
    margin-bottom: 50px
}

    .ps-post--home .ps-post__posted {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        background-color: #df1166;
        text-align: center
    }

        .ps-post--home .ps-post__posted span {
            color: #fff;
            line-height: 1em;
            font-weight: 700;
            display: block
        }

            .ps-post--home .ps-post__posted span.date {
                font-size: 32px
            }

            .ps-post--home .ps-post__posted span.month {
                font-size: 18px
            }

    .ps-post--home .ps-post__thumbnail {
        margin-bottom: 15px
    }

    .ps-post--home .ps-post__title {
        font-size: 18px;
        color: #5b5b5b;
        text-transform: uppercase;
        font-weight: 700
    }

        .ps-post--home .ps-post__title:hover {
            color: #df1166
        }

    .ps-post--home .ps-post__meta {
        font-size: 14px;
        font-weight: 500
    }

        .ps-post--home .ps-post__meta a {
            color: #df1166
        }

    .ps-post--home .ps-post__content p {
        line-height: 1.8em;
        color: #8d8d8d;
        font-size: 14px
    }

    .ps-post--home .ps-post__morelink {
        font-size: 16px;
        font-weight: 600;
        color: #8d8d8d;
        text-transform: uppercase;
        line-height: 25px
    }

        .ps-post--home .ps-post__morelink i {
            margin-left: 10px;
            vertical-align: middle;
            line-height: 25px
        }

        .ps-post--home .ps-post__morelink:hover {
            color: #df1166
        }

@media (max-width:991px) {
    .ps-post--home::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-post--home .ps-post__thumbnail {
        float: left;
        width: 50%
    }

    .ps-post--home .ps-post__content {
        float: left;
        width: 50%;
        padding-left: 30px
    }
}

@media (max-width:767px) {
    .ps-post--home .ps-post__thumbnail {
        width: 100%
    }

    .ps-post--home .ps-post__content {
        width: 100%;
        padding-left: 0
    }
}

.ps-post--jewelry .ps-post__title {
    font-family: "Barlow", sans-serif;
    text-transform: none
}

.ps-post--jewelry .ps-post__morelink {
    font-family: "Barlow", sans-serif
}

.ps-post--campaign {
    display: table;
    background-color: #62628a
}

    .ps-post--campaign .ps-post__thumbnail {
        overflow: hidden
    }

    .ps-post--campaign .ps-post__thumbnail, .ps-post--campaign .ps-post__content {
        display: table-cell
    }

    .ps-post--campaign .ps-post__thumbnail {
        width: 60%
    }

    .ps-post--campaign .ps-post__content {
        width: 40%;
        vertical-align: top;
        text-align: center;
        background-color: #62628a;
        padding: 100px
    }

        .ps-post--campaign .ps-post__content h3 {
            font-family: "Barlow", sans-serif;
            font-size: 24px;
            color: #fff;
            font-weight: 400;
            line-height: 1.3em
        }

        .ps-post--campaign .ps-post__content h2 {
            font-family: "Barlow", sans-serif;
            font-size: 48px;
            line-height: 1.3em;
            color: #fff
        }

        .ps-post--campaign .ps-post__content p {
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 300;
            color: #fff
        }

    .ps-post--campaign .ps-post__morelink {
        position: relative;
        display: inline-block;
        padding-bottom: 5px;
        font-family: "Barlow", sans-serif;
        font-weight: 400;
        font-size: 24px;
        color: #df1166
    }

        .ps-post--campaign .ps-post__morelink:before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 2px;
            background-color: #df1166;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

        .ps-post--campaign .ps-post__morelink:hover {
            color: #333
        }

            .ps-post--campaign .ps-post__morelink:hover:before {
                background-color: #333
            }

@media (max-width:1599px) {
    .ps-post--campaign .ps-post__content {
        padding: 100px 50px
    }
}

@media (max-width:1399px) {
    .ps-post--campaign .ps-post__content {
        padding: 50px 50px
    }
}

@media (max-width:1199px) {
    .ps-post--campaign .ps-post__thumbnail, .ps-post--campaign .ps-post__content {
        width: 50%
    }

        .ps-post--campaign .ps-post__content h3 {
            font-size: 20px
        }

        .ps-post--campaign .ps-post__content h2 {
            font-size: 35px
        }

    .ps-post--campaign .ps-post__morelink {
        font-size: 16px
    }
}

@media (max-width:991px) {
    .ps-post--campaign ps-post__content {
        padding: 50px 30px
    }

        .ps-post--campaign ps-post__content h3 {
            font-size: 18px
        }

        .ps-post--campaign ps-post__content h2 {
            font-size: 30px
        }

    .ps-post--campaign .ps-post__morelink {
        font-size: 14px
    }
}

@media (max-width:767px) {
    .ps-post--campaign .ps-post__thumbnail {
        height: 300px
    }

    .ps-post--campaign .ps-post__thumbnail, .ps-post--campaign .ps-post__content {
        display: block;
        width: 100%
    }

        .ps-post--campaign .ps-post__content br {
            display: none
        }
}

@media (max-width:479px) {
    .ps-post--campaign .ps-post__content h3 {
        font-size: 16px
    }

    .ps-post--campaign .ps-post__content h2 {
        font-size: 24px
    }

    .ps-post--campaign .ps-post__morelink {
        font-size: 14px
    }
}

.ps-post--grid {
    margin-bottom: 30px
}

    .ps-post--grid .ps-post__badge {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        font-size: 14px;
        color: #eaeaea;
        line-height: 20px;
        padding: 5px 20px;
        background-color: #000;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        border-radius: 30px
    }

    .ps-post--grid .ps-post__content {
        padding: 25px;
        background-color: #f9f9f9
    }

        .ps-post--grid .ps-post__content p {
            color: #777
        }

    .ps-post--grid .ps-post__title {
        display: block;
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.3em;
        color: #333
    }

        .ps-post--grid .ps-post__title:hover {
            color: #df1166
        }

    .ps-post--grid .ps-post__meta {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        color: #c3c3c3
    }

        .ps-post--grid .ps-post__meta a {
            color: #df1166
        }

            .ps-post--grid .ps-post__meta a:hover {
                text-decoration: underline
            }

    .ps-post--grid .ps-post__footer::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-post--grid .ps-post__morelink {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        text-transform: uppercase;
        float: left
    }

        .ps-post--grid .ps-post__morelink:hover {
            color: #df1166
        }

    .ps-post--grid .ps-post__favorite {
        float: right;
        font-size: 14px;
        color: #777
    }

        .ps-post--grid .ps-post__favorite i {
            margin-right: 3px
        }

        .ps-post--grid .ps-post__favorite:hover {
            color: #df1166
        }

.ps-post--sidebar {
    margin-bottom: 15px
}

    .ps-post--sidebar::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-post--sidebar .ps-post__thumbnail {
        float: left;
        width: 70px
    }

    .ps-post--sidebar .ps-post__content {
        float: left;
        width: calc(100% - 70px);
        padding-left: 10px
    }

        .ps-post--sidebar .ps-post__content p {
            font-size: 12px;
            text-transform: uppercase
        }

    .ps-post--sidebar .ps-post__title {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4em;
        color: #000;
        text-transform: uppercase
    }

        .ps-post--sidebar .ps-post__title:hover {
            color: #df1166
        }

.ps-post--list {
    margin-bottom: 40px
}

    .ps-post--list .ps-post__content {
        padding: 25px;
        background-color: #f9f9f9
    }

        .ps-post--list .ps-post__content p {
            font-size: 14px;
            line-height: 1.8em;
            color: #777
        }

        .ps-post--list .ps-post__content .ps-post__meta {
            font-size: 14px;
            font-weight: 500;
            color: #8d8d8d
        }

            .ps-post--list .ps-post__content .ps-post__meta a {
                color: #df1166
            }

    .ps-post--list .ps-post__title {
        display: block;
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2em;
        color: #333
    }

    .ps-post--list .ps-post__footer {
        padding-top: 15px
    }

        .ps-post--list .ps-post__footer::after {
            clear: both;
            content: "";
            display: table
        }

        .ps-post--list .ps-post__footer .ps-post__morelink {
            float: left
        }

    .ps-post--list .ps-post__morelink {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        text-transform: uppercase
    }

        .ps-post--list .ps-post__morelink:hover {
            color: #df1166
        }

    .ps-post--list .ps-post__actions {
        display: inline-block;
        float: right
    }

        .ps-post--list .ps-post__actions > a {
            display: inline-block;
            margin-right: 30px;
            font-size: 14px;
            color: #777
        }

            .ps-post--list .ps-post__actions > a i {
                margin-right: 5px
            }

    .ps-post--list .ps-post__shared {
        position: relative;
        display: inline-block
    }

        .ps-post--list .ps-post__shared > a {
            display: inline-block;
            margin-right: 10px;
            font-size: 14px;
            color: #777
        }

            .ps-post--list .ps-post__shared > a i {
                margin-right: 5px
            }

        .ps-post--list .ps-post__shared ul {
            position: absolute;
            display: inline-block;
            width: 90px;
            top: 100%;
            left: 50%;
            margin: 0;
            padding: 0;
            list-style: none;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease;
            visibility: hidden;
            opacity: 0
        }

            .ps-post--list .ps-post__shared ul::after {
                clear: both;
                content: "";
                display: table
            }

            .ps-post--list .ps-post__shared ul li {
                float: left
            }

                .ps-post--list .ps-post__shared ul li a {
                    display: inline-block;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    background-color: #df1166
                }

                    .ps-post--list .ps-post__shared ul li a i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%,-50%);
                        -moz-transform: translate(-50%,-50%);
                        -ms-transform: translate(-50%,-50%);
                        -o-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
                        color: #fff
                    }

                    .ps-post--list .ps-post__shared ul li a:hover {
                        background-color: #000
                    }

        .ps-post--list .ps-post__shared:hover ul {
            visibility: visible;
            opacity: 1
        }

.ps-post--detail {
    margin-bottom: 30px;
    background-color: #f7f7f7
}

    .ps-post--detail .ps-post__thumbnail {
        margin-bottom: 15px
    }

    .ps-post--detail .ps-post__title {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #333
    }

    .ps-post--detail .ps-post__meta {
        font-size: 14px;
        color: #8d8d8d
    }

        .ps-post--detail .ps-post__meta a {
            color: #df1166
        }

    .ps-post--detail .ps-post__header {
        margin-bottom: 15px;
        padding: 30px
    }

    .ps-post--detail .ps-post__content {
        padding: 30px
    }

        .ps-post--detail .ps-post__content p {
            margin-bottom: 25px;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            line-height: 1.8em;
            color: #8d8d8d
        }

        .ps-post--detail .ps-post__content blockquote {
            border-color: #df1166
        }

            .ps-post--detail .ps-post__content blockquote p {
                font-family: 'Montserrat', sans-serif;
                font-size: 18px;
                font-style: italic;
                line-height: 1.6em;
                color: #999
            }

                .ps-post--detail .ps-post__content blockquote p.author {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: italic;
                    line-height: 1.7em;
                    color: #8d8d8d
                }

                    .ps-post--detail .ps-post__content blockquote p.author span {
                        color: #666;
                        font-weight: 600
                    }

    .ps-post--detail .ps-post__footer {
        padding: 35px 30px;
        border-top: 1px solid #e5e5e5
    }

        .ps-post--detail .ps-post__footer::after {
            clear: both;
            content: "";
            display: table
        }

        .ps-post--detail .ps-post__footer .ps-post__tags {
            display: inline-block;
            float: left;
            color: #6d6d6d
        }

            .ps-post--detail .ps-post__footer .ps-post__tags i {
                margin-right: 10px
            }

            .ps-post--detail .ps-post__footer .ps-post__tags a {
                font-size: 14px;
                font-style: italic;
                color: #6d6d6d
            }

                .ps-post--detail .ps-post__footer .ps-post__tags a:hover {
                    color: #df1166
                }

        .ps-post--detail .ps-post__footer .ps-post__actions {
            float: right
        }

    .ps-post--detail .ps-post__actions span {
        margin-right: 30px;
        font-size: 14px;
        color: #777
    }

    .ps-post--detail .ps-post__actions .ps-post__social {
        position: relative;
        display: inline-block
    }

        .ps-post--detail .ps-post__actions .ps-post__social a {
            color: #777
        }

        .ps-post--detail .ps-post__actions .ps-post__social i {
            margin-right: 5px;
            color: #777
        }

        .ps-post--detail .ps-post__actions .ps-post__social ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            position: absolute;
            bottom: 100%;
            left: 50%;
            width: 120px;
            z-index: 1000;
            -webkit-transform: translate(-50%,-10px);
            -moz-transform: translate(-50%,-10px);
            -ms-transform: translate(-50%,-10px);
            -o-transform: translate(-50%,-10px);
            transform: translate(-50%,-10px);
            visibility: hidden;
            opacity: 0;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

            .ps-post--detail .ps-post__actions .ps-post__social ul::after {
                clear: both;
                content: "";
                display: table
            }

            .ps-post--detail .ps-post__actions .ps-post__social ul:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 100%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                height: 0;
                width: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #44ccf6
            }

            .ps-post--detail .ps-post__actions .ps-post__social ul li {
                float: left;
                vertical-align: top;
                margin-right: 0
            }

                .ps-post--detail .ps-post__actions .ps-post__social ul li a {
                    position: relative;
                    vertical-align: top;
                    display: inline-block;
                    width: 40px;
                    height: 40px
                }

                    .ps-post--detail .ps-post__actions .ps-post__social ul li a i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%,-50%);
                        -moz-transform: translate(-50%,-50%);
                        -ms-transform: translate(-50%,-50%);
                        -o-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
                        color: #fff
                    }

                    .ps-post--detail .ps-post__actions .ps-post__social ul li a:hover {
                        background-color: #000 !important
                    }

                .ps-post--detail .ps-post__actions .ps-post__social ul li:nth-child(1) a {
                    background-color: #3b5998
                }

                .ps-post--detail .ps-post__actions .ps-post__social ul li:nth-child(2) a {
                    background-color: #44ccf6
                }

                .ps-post--detail .ps-post__actions .ps-post__social ul li:nth-child(3) a {
                    background-color: rgba(209,63,45,.97)
                }

        .ps-post--detail .ps-post__actions .ps-post__social:hover ul {
            visibility: visible;
            opacity: 1
        }

@media (max-width:767px) {
    .ps-post--detail .ps-post__title {
        font-size: 20px
    }

    .ps-post--detail .ps-post__content blockquote p {
        font-size: 14px
    }

    .ps-post--detail .ps-post__footer .ps-post__tags {
        float: none;
        margin-bottom: 10px
    }

    .ps-post--detail .ps-post__footer .ps-post__actions {
        float: none
    }
}

.ps-pagination {
    text-align: center
}

    .ps-pagination::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-pagination .pagination {
        margin: 0;
        display: inline-block
    }

        .ps-pagination .pagination li {
            display: inline-block;
            text-align: center
        }

            .ps-pagination .pagination li > a {
                position: relative;
                display: inline-block;
                z-index: 30;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                color: #313131;
                line-height: 50px;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                border-radius: 0;
                border: none;
                background-color: transparent !important;
                cursor: pointer;
            }

                .ps-pagination .pagination li > a:before, .ps-pagination .pagination li > a:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%,-50%);
                    -moz-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    -o-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                    z-index: -2;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    border-radius: 50%;
                    -webkit-transition: all 0.4s ease;
                    -moz-transition: all 0.4s ease;
                    transition: all 0.4s ease
                }

                .ps-pagination .pagination li > a:before {
                    width: 30px;
                    height: 30px;
                    background-color: #e4e4e4
                }

                .ps-pagination .pagination li > a:after {
                    width: 30px;
                    height: 30px;
                    background-color: #df1166;
                    z-index: -1;
                    visibility: hidden;
                    opacity: 0
                }

                .ps-pagination .pagination li > a:hover {
                    color: #fff
                }

                    .ps-pagination .pagination li > a:hover:after {
                        visibility: visible;
                        opacity: 1
                    }

            .ps-pagination .pagination li.active {
                border: none
            }

                .ps-pagination .pagination li.active a {
                    color: #fff
                }

                    .ps-pagination .pagination li.active a:after {
                        visibility: visible;
                        opacity: 1
                    }

            .ps-pagination .pagination li .disabled {
                cursor: not-allowed
            }

    .ps-pagination.left {
        text-align: left
    }

    .ps-pagination.square .pagination li a:before, .ps-pagination.square .pagination li a:after {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }

@media (max-width:479px) {
    .ps-pagination .pagination li {
        margin-right: 10px
    }

        .ps-pagination .pagination li a {
            padding: 0 12px;
            font-size: 12px
        }

            .ps-pagination .pagination li a:before {
                width: 30px;
                height: 30px
            }

            .ps-pagination .pagination li a:after {
                width: 35px;
                height: 35px
            }
}

.ps-breadcrumb {
    margin-bottom: 5px
}

    .ps-breadcrumb .breadcrumb {
        margin: 0;
        padding: 0;
        background-color: transparent
    }

        .ps-breadcrumb .breadcrumb li {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            color: #e75e4e;
            text-transform: uppercase
        }

            .ps-breadcrumb .breadcrumb li:before {
                font-family: FontAwesome
            }

            .ps-breadcrumb .breadcrumb li:first-child:before {
                display: none
            }

        .ps-breadcrumb .breadcrumb a {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            color: #b3b3b3;
            text-transform: uppercase
        }

            .ps-breadcrumb .breadcrumb a:hover {
                color: #df1166
            }

            .ps-breadcrumb .breadcrumb a i {
                margin-right: 5px
            }

@media (max-width:991px) {
    .ps-breadcrumb {
        position: relative
    }

        .ps-breadcrumb .breadcrumb li {
            font-size: 12px
        }

        .ps-breadcrumb .breadcrumb a {
            font-size: 12px
        }
}

.ps-block--offer {
    display: table;
    background-color: #fafafa;
    width: 100%
}

    .ps-block--offer .ps-block__left, .ps-block--offer .ps-block__right {
        display: table-cell
    }

    .ps-block--offer .ps-block__left {
        width: calc(100% - 450px)
    }

    .ps-block--offer .ps-block__right {
        padding: 55px 0;
        display: table-cell;
        width: 450px;
        text-align: center;
        vertical-align: middle
    }

        .ps-block--offer .ps-block__right h3 {
            margin-bottom: 15px;
            font-family: "Barlow", sans-serif;
            font-size: 24px;
            line-height: 1.3em;
            color: #333;
            font-weight: 400
        }

        .ps-block--offer .ps-block__right p {
            display: block;
            margin-bottom: 10px;
            font-family: "Barlow", sans-serif;
            font-size: 14px;
            color: #333;
            line-height: 50px
        }

            .ps-block--offer .ps-block__right p span {
                font-size: 48px;
                color: #df1166;
                vertical-align: middle
            }

        .ps-block--offer .ps-block__right .ps-btn {
            line-height: 25px;
            padding: 10px 50px;
            background-color: #df1166;
            color: #fff;
            font-size: 18px;
            font-weight: 600
        }

            .ps-block--offer .ps-block__right .ps-btn:hover {
                background-color: #da8300
            }

@media (max-width:991px) {
    .ps-block--offer .ps-block__left {
        width: 30%
    }

    .ps-block--offer .ps-block__right {
        width: 70%
    }
}

@media (max-width:767px) {
    .ps-block--offer .ps-block__left {
        width: 0
    }

    .ps-block--offer .ps-block__right {
        width: 100%
    }
}

.ps-block--offer-fashion {
    display: table;
    width: 100%
}

    .ps-block--offer-fashion .ps-block__left, .ps-block--offer-fashion .ps-block__right {
        display: table-cell;
        width: 50%
    }

    .ps-block--offer-fashion .ps-block__right {
        padding: 80px 0 80px 50px;
        padding-left: 50px
    }

    .ps-block--offer-fashion .ps-btn--outline {
        display: inline-block;
        padding: 15px 60px;
        background-color: transparent
    }

        .ps-block--offer-fashion .ps-btn--outline:hover {
            background-color: #df1166
        }

@media (max-width:1199px) {
    .ps-block--offer-fashion {
        display: block
    }

        .ps-block--offer-fashion .ps-block__left, .ps-block--offer-fashion .ps-block__right {
            display: block;
            width: 100%
        }

        .ps-block--offer-fashion .ps-block__left {
            height: 500px
        }

        .ps-block--offer-fashion .ps-block__right {
            padding: 50px 0;
            text-align: center
        }
}

@media (max-width:767px) {
    .ps-block--offer-fashion .ps-block__left {
        height: 400px
    }
}

@media (max-width:479px) {
    .ps-block--offer-fashion .ps-block__left {
        height: 300px
    }
}

.ps-block--contact {
    margin-bottom: 20px
}

    .ps-block--contact h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 1em;
        letter-spacing: .2px;
        color: #626262
    }

    .ps-block--contact h4 {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.2em;
        letter-spacing: .2px;
        color: #626262;
        font-weight: 400
    }

    .ps-block--contact p {
        color: #777
    }

        .ps-block--contact p i {
            margin-right: 5px
        }

    .ps-block--contact .ps-social li a {
        background-color: transparent
    }

        .ps-block--contact .ps-social li a i {
            color: #b2b2b2
        }

        .ps-block--contact .ps-social li a:hover i {
            color: #df1166
        }

.ps-review {
    margin-bottom: 40px
}

    .ps-review::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-review .ps-review__thumbnail {
        float: left;
        width: 100px
    }

        .ps-review .ps-review__thumbnail img {
            width: 80px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%
        }

    .ps-review .ps-review__content {
        float: left;
        width: calc(100% - 100px);
        padding: 25px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        border-radius: 4px;
        background-color: #f1f1f1
    }

        .ps-review .ps-review__content p {
            font-size: 14px;
            line-height: 1.5em;
            text-align: left;
            color: #5b5b5b
        }

    .ps-review header {
        margin-bottom: 10px
    }

        .ps-review header .br-widget, .ps-review header .br-wrapper {
            display: inline-block;
            margin-right: 10px
        }

        .ps-review header p {
            display: inline-block;
            font-size: 14px;
            color: #5b5b5b
        }

            .ps-review header p a {
                font-weight: 700;
                color: #df1166
            }

@media (max-width:767px) {
    .ps-review .ps-review__thumbnail {
        margin-bottom: 20px;
        float: none;
        width: 100%
    }

    .ps-review .ps-review__content {
        float: none;
        width: 100%;
        padding: 15px
    }
}

.ps-author {
    margin-bottom: 40px
}

    .ps-author::after {
        clear: both;
        content: "";
        display: table
    }

.ps-author__thumbnail {
    width: 135px;
    float: left
}

.ps-author__content {
    float: left;
    width: calc(100% - 135px);
    padding: 20px;
    background-color: #f7f7f7
}

    .ps-author__content > header h4 {
        margin-bottom: 0;
        font-size: 18px;
        color: #ffa827;
        line-height: 1.6em
    }

    .ps-author__content > header p {
        font-size: 12px;
        color: #8d8d8d
    }

    .ps-author__content p {
        color: #8d8d8d;
        line-height: 1.8em
    }

.ps-comments .ps-heading {
    margin-bottom: 20px;
    font-size: 24px;
    color: #323232;
    font-weight: 600
}

.ps-comment {
    margin-bottom: 60px
}

    .ps-comment::after {
        clear: both;
        content: "";
        display: table
    }

.ps-comment__thumbnail {
    float: left;
    width: 100px
}

    .ps-comment__thumbnail img {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        width: 80px
    }

.ps-comment__content {
    float: left;
    width: calc(100% - 100px);
    background-color: #f7f7f7;
    padding: 20px;
    -webkit-border-radius: 0 20px 0 20px;
    -moz-border-radius: 0 20px 0 20px;
    -ms-border-radius: 0 20px 0 20px;
    border-radius: 0 20px 0 20px
}

    .ps-comment__content > header h4 {
        display: inline-block;
        font-size: 14px;
        color: #000
    }

        .ps-comment__content > header h4 span {
            margin-left: 10px;
            color: #989898
        }

    .ps-comment__content > header a {
        float: right;
        font-size: 16px;
        color: #df1166;
        text-transform: uppercase;
        font-weight: 600
    }

        .ps-comment__content > header a i {
            margin-left: 10px
        }

    .ps-comment__content p {
        font-size: 14px;
        line-height: 1.8em;
        color: #8d8d8d
    }

.ps-comment .ps-comment {
    margin-top: 30px
}

    .ps-comment .ps-comment .ps-comment__content {
        background-color: #fff;
        -webkit-border-radius: 0 10px 0 10px;
        -moz-border-radius: 0 10px 0 10px;
        -ms-border-radius: 0 10px 0 10px;
        border-radius: 0 10px 0 10px
    }

.ps-widget {
    position: relative
}

.ps-widget--footer .ps-widget__title {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 40px;
    color: #656565;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 10
}

/*.ps-widget--footer .ps-widget__title:before {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 15px;
        background-color: #FBF5F3
    }*/

.ps-widget--info > header .ps-logo {
    margin-bottom: 15px;
    max-width: 120px;
    display: inline-block
}

.ps-widget--info p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #999
}

    .ps-widget--info p strong {
        margin-bottom: 20px;
        font-weight: 700
    }

.ps-widget--info.second {
    padding-top: 55px
}

@media (max-width:991px) {
    .ps-widget--info.second {
        padding-top: 0
    }
}

.ps-widget--link > header h3 {
    font-size: 16px
}

.ps-widget--link > footer ul.ps-list--link li {
    margin-bottom: 15px
}

.ps-widget--link > footer ul.ps-list--link a {
    font-family: "Barlow", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.8em;
    color: #fff;
    text-transform: uppercase
}

.ps-widget--sidebar {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 25px;
    margin-bottom: 25px
}

    .ps-widget--sidebar .ps-widget__header {
        margin-bottom: 25px
    }

        .ps-widget--sidebar .ps-widget__header h3 {
            font-size: 18px;
            font-weight: 700;
            color: #333;
            text-transform: uppercase
        }

    .ps-widget--sidebar .ps-table--size {
        display: block
    }

        .ps-widget--sidebar .ps-table--size tbody > tr > td {
            width: 45px;
            height: 45px;
            vertical-align: middle;
            text-align: center;
            cursor: pointer;
            font-size: 13px;
            border: 1px solid #e4e4e4;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

            .ps-widget--sidebar .ps-table--size tbody > tr > td.active, .ps-widget--sidebar .ps-table--size tbody > tr > td:hover {
                background-color: #df1166;
                color: #fff
            }

.ps-sticky {
    position: relative
}

.ps-search--widget {
    position: relative
}

    .ps-search--widget > input {
        padding: 0 20px;
        border: none;
        height: 40px;
        background-color: #e8e8e8;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        border-radius: 40px;
        font-size: 13px;
        color: #403c34
    }

        .ps-search--widget > input::-webkit-input-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            color: #adadad
        }

        .ps-search--widget > input::-moz-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            color: #adadad
        }

        .ps-search--widget > input:-moz-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            color: #adadad
        }

        .ps-search--widget > input:-ms-input-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            color: #adadad
        }

    .ps-search--widget > button {
        right: 15px;
        color: #000;
        font-size: 15px;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

        .ps-search--widget > button:hover {
            color: #df1166
        }

.ps-widget--search {
    border-bottom: none
}

.widget {
    margin-bottom: 30px
}

    .widget .widget-title {
        display: block;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 600;
        color: #333;
        text-transform: uppercase
    }

.widget_tags a {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    background-color: #df1166
}

    .widget_tags a:hover {
        background-color: #101010
    }

.ps-product--fashion .ps-product__actions li a, .ps-product--fashion .ps-product__cart {
    background-color: #333
}

    .ps-product--fashion .ps-product__actions li a i, .ps-product--fashion .ps-product__cart i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size: 20px
    }

        .ps-product--fashion .ps-product__actions li a i:before, .ps-product--fashion .ps-product__cart i:before {
            color: #fff
        }

    .ps-product--fashion .ps-product__actions li a:hover, .ps-product--fashion .ps-product__cart:hover {
        background-color: #df1166
    }

        .ps-product--fashion .ps-product__actions li a:hover i:before, .ps-product--fashion .ps-product__cart:hover i:before {
            color: #333
        }

.ps-product__thumbnail {
    position: relative
}

    .ps-product__thumbnail img {
        width: 100%
    }

    .ps-product__thumbnail .ps-product__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10
    }

.ps-product--fashion {
    margin-bottom: 30px
}

    .ps-product--fashion .ps-product__overlay {
        -webkit-transition: all 1s cubic-bezier(.25,.46,.45,.94);
        -moz-transition: all 1s cubic-bezier(.25,.46,.45,.94);
        transition: all 1s cubic-bezier(.25,.46,.45,.94)
    }

    .ps-product--fashion .ps-product__thumbnail {
        overflow: hidden
    }

        .ps-product--fashion .ps-product__thumbnail img {
            -webkit-transition: all 1s cubic-bezier(.25,.46,.45,.94);
            -moz-transition: all 1s cubic-bezier(.25,.46,.45,.94);
            transition: all 1s cubic-bezier(.25,.46,.45,.94)
        }

    .ps-product--fashion .ps-product__actions {
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 30
    }

        .ps-product--fashion .ps-product__actions li {
            border-bottom: 1px solid #797979;
            -webkit-transition: all 0.25s cubic-bezier(.25,.46,.45,.94);
            -moz-transition: all 0.25s cubic-bezier(.25,.46,.45,.94);
            transition: all 0.25s cubic-bezier(.25,.46,.45,.94)
        }

            .ps-product--fashion .ps-product__actions li:nth-child(0) {
                -webkit-transform: translateX(110%);
                -moz-transform: translateX(110%);
                -ms-transform: translateX(110%);
                -o-transform: translateX(110%);
                transform: translateX(110%);
                -webkit-transition-delay: 0.1s;
                -moz-transition-delay: 0.1s;
                transition-delay: 0.1s
            }

            .ps-product--fashion .ps-product__actions li:nth-child(1) {
                -webkit-transform: translateX(111%);
                -moz-transform: translateX(111%);
                -ms-transform: translateX(111%);
                -o-transform: translateX(111%);
                transform: translateX(111%);
                -webkit-transition-delay: 0.2s;
                -moz-transition-delay: 0.2s;
                transition-delay: 0.2s
            }

            .ps-product--fashion .ps-product__actions li:nth-child(2) {
                -webkit-transform: translateX(112%);
                -moz-transform: translateX(112%);
                -ms-transform: translateX(112%);
                -o-transform: translateX(112%);
                transform: translateX(112%);
                -webkit-transition-delay: 0.3s;
                -moz-transition-delay: 0.3s;
                transition-delay: 0.3s
            }

            .ps-product--fashion .ps-product__actions li:nth-child(3) {
                -webkit-transform: translateX(113%);
                -moz-transform: translateX(113%);
                -ms-transform: translateX(113%);
                -o-transform: translateX(113%);
                transform: translateX(113%);
                -webkit-transition-delay: 0.4s;
                -moz-transition-delay: 0.4s;
                transition-delay: 0.4s
            }

            .ps-product--fashion .ps-product__actions li a {
                display: inline-block;
                vertical-align: top;
                width: 60px;
                height: 60px
            }

    .ps-product--fashion .ps-product__content {
        position: relative;
        padding: 10px;
        height: 60px;
        background-color: #FBF5F3
    }

    .ps-product--fashion .ps-product__title {
        text-align: center;
        display: block;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .ps-product--fashion .ps-product__price {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        text-transform: uppercase;
        color: #777;
        text-align: center
    }

        .ps-product--fashion .ps-product__price del {
            margin-right: 10px;
            color: #bfbfbf;
            font-weight: 500
        }

    .ps-product--fashion .ps-product__cart {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px
    }

    .ps-product--fashion:hover .ps-product__title {
        color: #df1166
    }

    .ps-product--fashion:hover .ps-product__actions {
        visibility: visible;
        opacity: 1
    }

        .ps-product--fashion:hover .ps-product__actions li {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0)
        }

    .ps-product--fashion:hover .ps-product__thumbnail img {
        -webkit-transform: scale(1.1,1.1);
        -moz-transform: scale(1.1,1.1);
        -ms-transform: scale(1.1,1.1);
        -o-transform: scale(1.1,1.1);
        transform: scale(1.1,1.1)
    }

@media (max-width:767px) {
    .ps-product--fashion {
        max-width: 350px;
        margin: 0 auto 30px
    }
}

.ps-product--yewelry {
    margin-bottom: 30px
}

    .ps-product--yewelry .ps-product__thumbnail {
        margin-bottom: 20px;
        overflow: hidden
    }

        .ps-product--yewelry .ps-product__thumbnail img {
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            transition: all 1s ease
        }

    .ps-product--yewelry .ps-product__actions {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        z-index: 100;
        width: 100%;
        text-align: center
    }

        .ps-product--yewelry .ps-product__actions li {
            position: relative;
            display: inline-block;
            margin-right: 20px;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            transition: all 0.5s ease;
            visibility: hidden;
            opacity: 0;
            -webkit-transform: scale(.2,.2);
            -moz-transform: scale(.2,.2);
            -ms-transform: scale(.2,.2);
            -o-transform: scale(.2,.2);
            transform: scale(.2,.2)
        }

            .ps-product--yewelry .ps-product__actions li a {
                position: relative;
                display: inline-block;
                width: 40px;
                height: 40px;
                background-color: #df1166
            }

                .ps-product--yewelry .ps-product__actions li a i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%,-50%);
                    -moz-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    -o-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                    font-size: 18px;
                    color: #fff
                }

                .ps-product--yewelry .ps-product__actions li a:hover {
                    background-color: #000
                }

            .ps-product--yewelry .ps-product__actions li:last-child {
                margin-right: 0
            }

    .ps-product--yewelry .ps-product__content {
        text-align: center;
        height: 50px;
        min-height: 50px
    }

    .ps-product--yewelry .br-theme-fontawesome-stars .br-widget a:after {
        font-size: 14px
    }

    .ps-product--yewelry .ps-product__title {
        font-family: "Barlow", sans-serif;
        font-size: 14px;
        line-height: 1.8em;
        color: #333
    }

        .ps-product--yewelry .ps-product__title:hover {
            color: #df1166
        }

    .ps-product--yewelry .ps-product__price {
        font-weight: 500;
        font-size: 16px;
        color: #777
    }

        .ps-product--yewelry .ps-product__price del {
            color: #bfbfbf;
            margin-right: 5px
        }

    .ps-product--yewelry:hover .ps-product__actions li {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1,1);
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        transform: scale(1,1)
    }

        .ps-product--yewelry:hover .ps-product__actions li:nth-child(2) {
            -webkit-transition-delay: 0.1s;
            -moz-transition-delay: 0.1s;
            transition-delay: 0.1s
        }

        .ps-product--yewelry:hover .ps-product__actions li:nth-child(3) {
            -webkit-transition-delay: 0.2s;
            -moz-transition-delay: 0.2s;
            transition-delay: 0.2s
        }

.ps-product-group__action {
    margin-bottom: 30px;
    text-align: right
}

    .ps-product-group__action .row {
        margin-left: 0;
        margin-right: 0
    }

    .ps-product-group__action p {
        text-align: left;
        line-height: 50px
    }

.ps-product-group__header {
    margin-bottom: 55px
}

.ps-product-group__content {
    margin-bottom: 40px;
    margin-left: -15px;
    margin-right: -15px
}

    .ps-product-group__content::after {
        clear: both;
        content: "";
        display: table
    }

.ps-product-group__footer {
    margin-bottom: 100px;
    text-align: center
}

.ps-product-group .ps-product-wrap {
    float: left;
    padding: 0 15px
}

.ps-product-group[data-item='5'] .ps-product-wrap {
    width: 20%
}

.ps-product-group[data-item='4'] .ps-product-wrap {
    width: 25%
}

.ps-product-group[data-item='3'] .ps-product-wrap {
    width: calc(100% / 3)
}

.ps-product-group.no-sidebar .ps-product-group__header {
    margin-bottom: 30px
}

@media (max-width:1700px) {
    .ps-product-group[data-item='5'] .ps-product-wrap {
        width: 25%
    }
}

@media (max-width:1440px) {
    .ps-product-group[data-item='5'] .ps-product-wrap {
        width: calc(100% / 3)
    }
}

@media (max-width:991px) {
    .ps-product-group[data-item='5'] .ps-product-wrap {
        width: 50%
    }
}

@media (max-width:767px) {
    .ps-product-group__action {
        text-align: left
    }

    .ps-product-group__header {
        margin-bottom: 30px
    }
}

@media (max-width:600px) {
    .ps-product-group[data-item='5'] .ps-product-wrap, .ps-product-group[data-item='4'] .ps-product-wrap, .ps-product-group[data-item='3'] .ps-product-wrap {
        width: 100%
    }
}

.ps-product--detail {
    margin: 40px auto 50px;
    max-width: 1170px
}

    .ps-product--detail::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-product--detail p {
        color: #5b5b5b;
        font-size: 15px
    }

    .ps-product--detail .ps-product__thumbnail::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-product--detail .ps-product__thumbnail .ps-product__preview {
        float: left;
        width: 106px;
        padding-right: 20px
    }

        .ps-product--detail .ps-product__thumbnail .ps-product__preview .item {
            margin: 10px;
            border: 3px solid #e5e5e5;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

            .ps-product--detail .ps-product__thumbnail .ps-product__preview .item:hover {
                border-color: #8d8d8d
            }

            .ps-product--detail .ps-product__thumbnail .ps-product__preview .item:focus {
                outline: none
            }

            .ps-product--detail .ps-product__thumbnail .ps-product__preview .item.slick-current {
                border-color: #8d8d8d
            }

    .ps-product--detail .ps-product__thumbnail .ps-product__image {
        float: left;
        width: calc(100% - 106px)
    }

    .ps-product--detail .ps-product__info h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        font-weight: 600;
        color: #313131
    }

    .ps-product--detail .ps-product__short-desc {
        margin-bottom: 20px
    }

    .ps-product--detail .ps-product__shopping::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-product--detail .ps-product__shopping .ps-btn {
        float: left;
        width: calc(100% - 200px);
        padding: 10px 50px;
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
        line-height: 25px;
        background-color: #df1166;
        text-align: center
    }

        .ps-product--detail .ps-product__shopping .ps-btn:hover {
            background-color: #303030
        }

    .ps-product--detail .ps-product__shopping .ps-product__actions {
        display: inline-block;
        vertical-align: top;
        float: right;
        width: 120px
    }

        .ps-product--detail .ps-product__shopping .ps-product__actions a {
            position: relative;
            display: inline-block;
            width: 55px;
            height: 55px;
            background-color: #999;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

            .ps-product--detail .ps-product__shopping .ps-product__actions a i {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -moz-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                -o-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                font-size: 22px
            }

                .ps-product--detail .ps-product__shopping .ps-product__actions a i:before {
                    color: #fff
                }

            .ps-product--detail .ps-product__shopping .ps-product__actions a:hover {
                background-color: #df1166
            }

    .ps-product--detail .ps-product__video {
        position: relative;
        margin: 10px;
        display: inline-block;
        border: 3px solid #e5e5e5
    }

        .ps-product--detail .ps-product__video i {
            display: inline-block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            background-color: rgba(255,255,255,.8);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

            .ps-product--detail .ps-product__video i:before {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -moz-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                -o-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                color: #111
            }

        .ps-product--detail .ps-product__video img {
            max-width: 60px
        }

        .ps-product--detail .ps-product__video:hover i {
            background-color: rgba(255,169,39,.8)
        }

            .ps-product--detail .ps-product__video:hover i:before {
                color: #fff
            }

    .ps-product--detail .ps-product__rating::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-product--detail .ps-product__rating .br-wrapper {
        display: inline-block;
        float: left
    }

    .ps-product--detail .ps-product__rating .br-widget > a {
        font-size: 20px !important
    }

    .ps-product--detail .ps-product__rating > a {
        float: right;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: #8d8d8d
    }

        .ps-product--detail .ps-product__rating > a:hover {
            color: #df1166
        }

    .ps-product--detail .ps-product__catefory a {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        text-align: left;
        color: #8d8d8d
    }

        .ps-product--detail .ps-product__catefory a:hover {
            color: #df1166
        }

    .ps-product--detail .ps-product__price {
        margin-bottom: 15px;
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        color: #000;
        font-weight: 600
    }

        .ps-product--detail .ps-product__price span {
            font-weight: 400
        }

        .ps-product--detail .ps-product__price del {
            margin-left: 5px;
            font-size: 24px;
            color: #bcbcbc;
            font-weight: 600
        }

    .ps-product--detail .ps-product__block {
        margin-bottom: 20px
    }

        .ps-product--detail .ps-product__block > h4 {
            display: block;
            margin-bottom: 15px;
            padding-bottom: 10px;
            font-size: 18px;
            color: #313131;
            font-weight: 400;
            border-bottom: 1px solid #e5e5e5
        }

        .ps-product--detail .ps-product__block p {
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            line-height: 1.8em;
            color: #5b5b5b
        }

.ps-product__style ul {
    padding-left: 0px;
}

.ps-product--detail .ps-product__style ul .in-stock {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px
}

    .ps-product--detail .ps-product__style ul .in-stock a {
        display: inline-block;
        width: 60px;
        border: 3px solid #e5e5e5;
        text-align: center;
        padding: 3px;
        cursor: pointer;
    }

.ps-product--detail .ps-product__style ul .out-of-stock {
    display: inline-block;
    margin-right: 10px
}

    .ps-product--detail .ps-product__style ul .out-of-stock a {
        display: inline-block;
        width: 60px;
        border: 3px solid #e5e5e5;
        text-align: center;
        padding: 3px;
        cursor: pointer;
        color: #333;
    }

.ps-product--detail .ps-product__style ul .in-stock a:hover {
    border-color: #df1166
}

.ps-product--detail .ps-product__style ul .selected a {
    border-color: #df1166;
    font-weight: 600;
}

.out-of-stock {
    background-image: url("/Content/theme/images/cancel.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
}


.ps-product--detail .ps-product__size {
    margin-bottom: 10px
}

    .ps-product--detail .ps-product__size::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-product--detail .ps-product__size > h4 a {
        float: right;
        display: inline-block;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: italic;
        color: #000;
        text-decoration: underline
    }

        .ps-product--detail .ps-product__size > h4 a:hover {
            color: #df1166
        }

    .ps-product--detail .ps-product__size .bootstrap-select.ps-select {
        width: calc(100% - 130px);
        float: left
    }

    .ps-product--detail .ps-product__size .form-group {
        float: left;
        display: inline-block;
        margin-right: 10px;
        max-width: 120px
    }

    .ps-product--detail .ps-product__size input {
        height: 55px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        border: 1px solid #979797
    }

.ps-product--detail .ps-product__content {
    margin-top: 50px
}

    .ps-product--detail .ps-product__content .tab-list {
        margin-bottom: 20px;
        padding-left: 0;
        text-align: left;
        border-bottom: 1px solid #e5e5e5
    }

        .ps-product--detail .ps-product__content .tab-list li {
            display: inline-block;
            margin-right: 110px
        }

            .ps-product--detail .ps-product__content .tab-list li a {
                position: relative;
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                line-height: 1.6em;
                color: #626262;
                text-transform: uppercase
            }

                .ps-product--detail .ps-product__content .tab-list li a:before {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: #df1166;
                    -webkit-transition: all 0.4s ease;
                    -moz-transition: all 0.4s ease;
                    transition: all 0.4s ease
                }

                .ps-product--detail .ps-product__content .tab-list li a:hover {
                    color: #df1166
                }

                    .ps-product--detail .ps-product__content .tab-list li a:hover:before {
                        width: 100%
                    }

            .ps-product--detail .ps-product__content .tab-list li.active a {
                color: #df1166
            }

                .ps-product--detail .ps-product__content .tab-list li.active a:before {
                    width: 100%
                }

.ps-product--detail .ps-product__review h4 {
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 400;
    color: #5b5b5b
}

.ps-product--detail .ps-product__review .form-group {
    margin-bottom: 30px
}

    .ps-product--detail .ps-product__review .form-group label {
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.3em;
        color: #5b5b5b
    }

.ps-product--detail .ps-product__tags {
    max-width: 400px
}

    .ps-product--detail .ps-product__tags .form-group {
        position: relative
    }

        .ps-product--detail .ps-product__tags .form-group button {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 5px;
            padding: 5px 20px;
            font-weight: 700;
            letter-spacing: 0;
            background-color: #5b5b5b
        }

            .ps-product--detail .ps-product__tags .form-group button:before {
                display: none
            }

            .ps-product--detail .ps-product__tags .form-group button:hover {
                background-color: #df1166
            }

        .ps-product--detail .ps-product__tags .form-group input {
            height: 45px;
            -webkit-border-radius: 45px;
            -moz-border-radius: 45px;
            -ms-border-radius: 45px;
            border-radius: 45px
        }

            .ps-product--detail .ps-product__tags .form-group input:focus {
                border-color: #df1166
            }

.ps-product--detail .tab-pane {
    padding-top: 10px;
    font-size: 14px
}

@media (max-width:991px) {
    .ps-product--detail .ps-product__thumbnail {
        margin-bottom: 30px
    }

        .ps-product--detail .ps-product__thumbnail .ps-product__preview {
            width: 100%;
            float: none
        }

        .ps-product--detail .ps-product__thumbnail .ps-product__image {
            width: 100%
        }

        .ps-product--detail .ps-product__thumbnail .ps-product__video {
            display: none
        }

    .ps-product--detail .ps-product__thumbnail--mobile {
        display: block
    }

    .ps-product--detail .ps-product__info {
        padding-left: 0;
        min-width: 0;
        width: 100%
    }

    .ps-product--detail .ps-product__content .tab-list li {
        margin-right: 15px
    }
}

@media (max-width:767px) {
    .ps-product--detail .ps-product__info h1 {
        font-size: 24px
    }

    .ps-product--detail .ps-product__style ul li {
        margin-bottom: 10px
    }
}

@media (max-width:479px) {
    .ps-product--detail .ps-product__size .btn-group, .ps-product--detail .ps-product__size .form-group {
        width: 100%;
        max-width: 100%
    }

    .ps-product--detail .ps-product__size .btn-group {
        margin-bottom: 10px;
        width: 100% !important
    }

    .ps-product--detail .ps-product__shopping .ps-btn {
        margin-bottom: 15px;
        width: 100%;
        text-align: center
    }

    .ps-product--detail .ps-product__content .tab-list {
        text-align: left
    }

        .ps-product--detail .ps-product__content .tab-list li {
            display: block
        }

            .ps-product--detail .ps-product__content .tab-list li a {
                display: block;
                padding: 10px 0
            }

            .ps-product--detail .ps-product__content .tab-list li.active a:before {
                width: 50px
            }
}

.ps-product--compare {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #000
}

    .ps-product--compare img {
        max-width: 90px;
        margin-right: 15px
    }

.ps-filter__trigger {
    position: relative;
    cursor: pointer
}

    .ps-filter__trigger > p {
        display: inline-block;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600;
        color: #333;
        text-transform: uppercase;
        line-height: 40px
    }

.ps-filter__icon {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    vertical-align: top
}

    .ps-filter__icon span, .ps-filter__icon:before, .ps-filter__icon:after {
        position: absolute;
        right: 10px;
        height: 2px;
        width: 20px;
        background-color: #303030;
        z-index: 100;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

    .ps-filter__icon span {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .ps-filter__icon:before, .ps-filter__icon:after {
        content: ''
    }

    .ps-filter__icon:before {
        top: 12px
    }

    .ps-filter__icon:after {
        bottom: 12px
    }

    .ps-filter__icon:hover {
        cursor: pointer
    }

    .ps-filter__icon.active span {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0
    }

    .ps-filter__icon.active:before, .ps-filter__icon.active:after {
        top: 50%;
        bottom: auto;
        width: 20px;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        background-color: #fc354c
    }

    .ps-filter__icon.active:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg)
    }

    .ps-filter__icon.active:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

.ps-filter__result {
    text-align: right
}

    .ps-filter__result p {
        margin-bottom: 0;
        line-height: 40px
    }

.ps-filter__content {
    display: none;
    padding: 30px 50px;
    background-color: #f7f7f7
}

    .ps-filter__content::after {
        clear: both;
        content: "";
        display: table
    }

.ps-filter__column {
    float: left;
    width: 20%;
    padding: 0 15px
}

    .ps-filter__column > h3 {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #333;
        font-weight: 600;
        letter-spacing: .05em
    }

        .ps-filter__column > h3:before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100px;
            height: 1px;
            background-color: #c3c3c3
        }

@media (max-width:1199px) {
    .ps-filter__content {
        padding: 30px 15px
    }
}

@media (max-width:991px) {
    .ps-filter__column {
        width: 50%;
        max-width: 300px
    }
}

@media (max-width:767px) {
    .ps-filter__result {
        text-align: left
    }
}

@media (max-width:479px) {
    .ps-filter__column {
        width: 100%;
        max-width: 300px
    }
}

@media (max-width:767px) {
    .zoomContainer {
        display: none
    }
}

.ps-collection {
    position: relative;
    display: block;
    overflow: hidden
}

    .ps-collection img {
        width: 100%
    }

    /*.ps-collection:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(255,255,255,.5);
        -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
        -moz-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
        -ms-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
        -o-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
        transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
        -webkit-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
        transition: all 0.6s ease
    }*/

    .ps-collection img {
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    .ps-collection:hover img {
        -webkit-transform: scale(1.05,1.05);
        -moz-transform: scale(1.05,1.05);
        -ms-transform: scale(1.05,1.05);
        -o-transform: scale(1.05,1.05);
        transform: scale(1.05,1.05)
    }


    .ps-collection:hover:before {
        background-color: rgba(255,255,255,.5);
        -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,120%,0);
        -moz-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,120%,0);
        -ms-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,120%,0);
        -o-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,120%,0);
        transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,120%,0)
    }

.ps-collection--2nd {
    overflow: hidden
}

    .ps-collection--2nd img {
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out
    }

    .ps-collection--2nd:before, .ps-collection--2nd:after {
        display: none
    }

    .ps-collection--2nd:hover img {
        -webkit-transform: scale(1.1,1.1);
        -moz-transform: scale(1.1,1.1);
        -ms-transform: scale(1.1,1.1);
        -o-transform: scale(1.1,1.1);
        transform: scale(1.1,1.1)
    }

.owl-slider {
    position: relative
}

.ps-slider--center .owl-nav {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 0
}

    .ps-slider--center .owl-nav::after {
        clear: both;
        content: "";
        display: table
    }

.ps-slider--center .owl-prev, .ps-slider--center .owl-next {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #242424;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%)
}

    .ps-slider--center .owl-prev i, .ps-slider--center .owl-next i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        color: #fff
    }

    .ps-slider--center .owl-prev:hover, .ps-slider--center .owl-next:hover {
        background-color: #df1166
    }

.ps-slider--center .owl-prev {
    margin-left: -70px
}

.ps-slider--center .owl-next {
    float: right;
    margin-right: -70px
}

.ps-slider--center:hover .owl-prev, .ps-slider--center:hover .owl-next {
    visibility: visible;
    opacity: 1
}

@media (max-width:1440px) {
    .ps-slider--center .owl-prev {
        margin-left: 0
    }

    .ps-slider--center .owl-next {
        margin-right: 0
    }
}

@media (max-width:991px) {
    .ps-slider--center .owl-prev, .ps-slider--center .owl-next {
        visibility: visible;
        opacity: 1
    }
}

.ps-slider--services .owl-nav .owl-prev, .ps-slider--services .owl-nav .owl-next {
    background-color: transparent;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

    .ps-slider--services .owl-nav .owl-prev i, .ps-slider--services .owl-nav .owl-next i {
        color: #303030;
        font-size: 20px
    }

.ps-slider--services p {
    padding: 10px;
    margin-bottom: 0;
    line-height: 20px;
    text-align: center
}

    .ps-slider--services p > i {
        color: #df1166;
        font-size: 25px;
        vertical-align: middle;
        margin-right: 15px
    }

    .ps-slider--services p strong {
        text-transform: uppercase
    }

.ps-slider--fashion3 {
    max-height: 690px;
    overflow-y: hidden
}

.ps-loading:before, .ps-loading:after {
    content: '';
    position: fixed;
    z-index: 1000000
}

.ps-loading:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease
}

.ps-loading:after {
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -20px 0 0 -20px;
    border: 8px solid #df1166;
    border-bottom-color: #311847;
    border-radius: 50%;
    animation: animLoader 0.8s linear infinite forwards
}

.ps-loading.loaded:before, .ps-loading.loaded:after {
    visibility: hidden;
    opacity: 0;
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    transition-delay: 0.5s
}

@keyframes animLoader {
    to {
        transform: rotate(360deg)
    }
}

.grid-border-r {
    border-right: 3px solid white
}

.grid-border-l {
    border-left: 6px solid white
}

.masonry-wrapper {
    margin: 0
}

    .masonry-wrapper .grid-item, .masonry-wrapper .grid-sizer {
        width: 100%
    }

        .masonry-wrapper .grid-item .grid-item__inner .grid-item__content-wrapper {
            background-color: #9EC3C3
        }

    .masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }

.ps-countdown {
    margin-bottom: 40px;
    margin: 0;
    padding: 0;
    list-style: none
}

    .ps-countdown li {
        display: inline-block;
        margin-right: 15px;
        text-align: center
    }

        .ps-countdown li p {
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
            text-align: center;
            color: #8d8d8d;
            text-transform: uppercase
        }

        .ps-countdown li.divider, .ps-countdown li span {
            vertical-align: top;
            font-family: "Barlow", sans-serif;
            font-size: 48px;
            text-align: left;
            text-transform: uppercase;
            color: #777;
            line-height: 1em
        }

@media (max-width:479px) {
    .grid-border-r {
        border-right: none
    }

    .grid-border-l {
        border-left: none
    }

    .ps-countdown li:not(.divider) {
        margin-right: 10px;
        font-size: 14px
    }

        .ps-countdown li:not(.divider) span {
            font-size: 30px
        }

        .ps-countdown li:not(.divider) p {
            line-height: 30px;
            font-size: 12px
        }

    .ps-countdown li.divider {
        margin-right: 10px;
        font-size: 30px
    }
}

.ps-countdown--fashion li span {
    font-size: 30px;
    color: #222;
    font-weight: 600
}

.ps-countdown--fashion li p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    color: #222
}

.ps-countdown--fashion li.divider, .ps-countdown--fashion li span {
    font-family: 'Montserrat', sans-serif;
    color: #222
}

.ps-countdown--fashion li:not(.divider) {
    padding: 15px 10px;
    text-align: center;
    min-width: 90px;
    border: 3px solid #242424
}

.ps-countdown--fashion li.divider {
    margin-top: 20px
}

@media (max-width:479px) {
    .ps-countdown--fashion li {
        margin-right: 10px;
        margin-bottom: 10px;
        width: calc(50% - 20px)
    }

        .ps-countdown--fashion li span {
            font-size: 20px
        }

        .ps-countdown--fashion li p {
            font-size: 8px
        }

        .ps-countdown--fashion li:not(.divider) {
            min-width: 50px
        }
}

.ps-banner--2 {
    width: 100%
}

@media (max-width:1199px) {
    .ps-banner--2 {
        padding-left: 0
    }
}

.ps-banner--jewelry {
    width: 100%;
    height: 100vh
}

.ps-hero {
    padding: 40px 30px 15px 30px
}

    .ps-hero h3 {
        margin-bottom: 0;
        font-size: 36px;
        font-weight: 600;
        line-height: 1.3em;
        color: #313131;
        text-transform: uppercase
    }

    .ps-hero p {
        margin-bottom: 0;
        font-size: 14px;
        color: #313131;
        line-height: 1.4em
    }

.ps-banner--owl {
    position: relative;
    width: 100%;
    height: 100vh
}

    .ps-banner--owl .ps-banner__content {
        display: block;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 100px
    }

        .ps-banner--owl .ps-banner__content h4 {
            font-size: 24px;
            text-transform: uppercase;
            color: #303030;
            font-weight: 600;
            text-align: right
        }

        .ps-banner--owl .ps-banner__content h2 {
            font-family: "Barlow", sans-serif;
            text-transform: uppercase;
            font-size: 60px
        }

        .ps-banner--owl .ps-banner__content p {
            margin-bottom: 20px;
            font-size: 36px;
            color: #7b7b7b;
            line-height: 40px
        }

        .ps-banner--owl .ps-banner__content .ps-btn {
            padding: 12px 60px;
            background-color: #000
        }

            .ps-banner--owl .ps-banner__content .ps-btn:hover {
                background-color: #df1166
            }

@media (min-width:1600px) {
    .ps-banner--owl .ps-banner__content {
        left: 200px
    }
}

@media (max-width:1199px) {
    .ps-banner--owl .ps-banner__content {
        left: 50px
    }
}

@media (max-width:991px) {
    .ps-banner--owl .ps-banner__content h4 {
        font-size: 20px
    }

    .ps-banner--owl .ps-banner__content h2 {
        font-size: 40px
    }

    .ps-banner--owl .ps-banner__content p {
        font-size: 18px
    }
}

@media (max-width:479px) {
    .ps-banner--owl .ps-banner__content {
        left: 25px
    }
}

.ps-table thead > tr > th {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #515356;
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5
}

.ps-table tbody > tr > td {
    vertical-align: middle;
    padding: 30px 10px
}

.ps-table--compare {
    border: 1px solid #e1e1e1
}

    .ps-table--compare tbody > tr > td {
        padding: 20px 30px;
        border: 1px solid #e1e1e1;
        font-weight: 500 !important;
        color: #000;
        text-transform: uppercase
    }

        .ps-table--compare tbody > tr > td:first-child {
            background-color: #f5f5f5;
            border-color: #e1e1e1;
            text-transform: uppercase;
            color: #000;
            font-size: 18px;
            font-weight: 700;
            vertical-align: middle !important
        }

        .ps-table--compare tbody > tr > td .price {
            font-size: 18px;
            font-weight: 500;
            color: #000
        }

        .ps-table--compare tbody > tr > td .status {
            font-size: 18px;
            font-weight: 500;
            color: #989898;
            text-transform: uppercase
        }

            .ps-table--compare tbody > tr > td .status.in-stock {
                color: #13aa18
            }

.ps-table--whishlist thead > tr > th {
    text-align: center
}

.ps-table--whishlist tbody > tr > td {
    text-align: center;
    font-size: 18px;
    color: #8d8d8d
}

    .ps-table--whishlist tbody > tr > td .ps-product-link {
        text-transform: uppercase;
        color: #df1166;
        font-weight: 500;
        font-size: 14px
    }

        .ps-table--whishlist tbody > tr > td .ps-product-link:hover {
            color: #333
        }

    .ps-table--whishlist tbody > tr > td:first-child {
        width: 30%;
        text-align: left
    }

    .ps-table--whishlist tbody > tr > td:last-child {
        width: 150px
    }

.ps-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000000;
    background-color: rgba(0,0,0,.9);
    visibility: hidden;
    opacity: 0
}

.ps-modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 945px
}

.ps-modal__remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    background-color: #333;
    cursor: pointer;
    z-index: 1000;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translate(50%,-50%);
    -moz-transform: translate(50%,-50%);
    -ms-transform: translate(50%,-50%);
    -o-transform: translate(50%,-50%);
    transform: translate(50%,-50%);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .ps-modal__remove i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        color: #fff;
        font-size: 20px
    }

    .ps-modal__remove:hover {
        background-color: #df1166
    }

.ps-modal.active {
    visibility: visible;
    opacity: 1
}

.ps-modal.default .ps-modal__content {
    padding: 0 50px;
    width: 1170px;
    background-color: #fff
}

@media (max-width:1199px) {
    .ps-modal.default .ps-modal__content {
        width: 90%;
        padding: 0 30px
    }

        .ps-modal.default .ps-modal__content .ps-product--detail .ps-product__thumbnail {
            margin-bottom: 30px
        }

            .ps-modal.default .ps-modal__content .ps-product--detail .ps-product__thumbnail .ps-product__preview {
                display: none
            }

            .ps-modal.default .ps-modal__content .ps-product--detail .ps-product__thumbnail .ps-product__image {
                width: 100%
            }
}

@media (max-width:991px) {
    .ps-modal__content {
        max-height: 800px;
        overflow-y: auto
    }

    .ps-modal__remove {
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
        background-color: transparent;
        width: 30px;
        height: 30px
    }

        .ps-modal__remove i {
            color: #f44336
        }

    .ps-modal__content {
        width: 90%
    }
}

@media (max-width:767px) {
    .ps-modal__content {
        width: 85%
    }
}

.ps-btn--search-close {
    display: inline-block;
    position: fixed;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    z-index: 1000;
    background-color: rgba(255,255,255,.7);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(.1,.1);
    -moz-transform: scale(.1,.1);
    -ms-transform: scale(.1,.1);
    -o-transform: scale(.1,.1);
    transform: scale(.1,.1)
}

    .ps-btn--search-close:before, .ps-btn--search-close:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 2px;
        background-color: #161824;
        -webkit-transform: translate(-50%,-50%) rotate(45deg);
        -moz-transform: translate(-50%,-50%) rotate(45deg);
        -ms-transform: translate(-50%,-50%) rotate(45deg);
        -o-transform: translate(-50%,-50%) rotate(45deg);
        transform: translate(-50%,-50%) rotate(45deg);
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

    .ps-btn--search-close:after {
        -webkit-transform: translate(-50%,-50%) rotate(-45deg);
        -moz-transform: translate(-50%,-50%) rotate(-45deg);
        -ms-transform: translate(-50%,-50%) rotate(-45deg);
        -o-transform: translate(-50%,-50%) rotate(-45deg);
        transform: translate(-50%,-50%) rotate(-45deg)
    }

    .ps-btn--search-close:hover {
        background-color: #fff
    }

        .ps-btn--search-close:hover:before, .ps-btn--search-close:hover:after {
            background-color: #E31039
        }

.ps-search {
    position: fixed;
    width: 100%;
    height: 100%
}

    .ps-search:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
        z-index: 100;
        background-color: #df1166;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        -webkit-transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
        -moz-transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
        transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
        visibility: hidden;
        opacity: 0
    }

.ps-search__content {
    position: relative;
    z-index: 100;
    max-width: 800px;
    width: 100%;
    margin: 100px auto;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: scale(.5,.5);
    -moz-transform: scale(.5,.5);
    -ms-transform: scale(.5,.5);
    -o-transform: scale(.5,.5);
    transform: scale(.5,.5)
}

.ps-search.open {
    z-index: 1000
}

    .ps-search.open:before {
        visibility: visible;
        opacity: 1;
        width: calc(1920px * 2.5);
        height: calc(1920px * 2.5)
    }

    .ps-search.open .ps-search__content {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1,1);
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        transform: scale(1,1);
        -webkit-transition-delay: 0.25s;
        -moz-transition-delay: 0.25s;
        transition-delay: 0.25s
    }

    .ps-search.open + .ps-btn--search-close {
        visibility: visible;
        opacity: 1;
        -webkit-transition-delay: 0.25s;
        -moz-transition-delay: 0.25s;
        transition-delay: 0.25s;
        -webkit-transform: scale(1,1);
        -moz-transform: scale(1,1);
        -ms-transform: scale(1,1);
        -o-transform: scale(1,1);
        transform: scale(1,1)
    }

        .ps-search.open + .ps-btn--search-close:hover {
            -webkit-transition-delay: 0;
            -moz-transition-delay: 0;
            transition-delay: 0
        }

.validation-summary-errors ul li {
    color: #c00;
    font-weight: 700;
    margin-bottom: 10px;
    list-style: none
}

.validation-summary-errors ul {
    padding-left: 0
}

.ps-form--search {
    position: relative;
    max-width: 260px
}

    .ps-form--search input {
        height: 40px;
        background-color: #fbf5f3;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        border-radius: 40px;
        border: none;
        padding: 0 20px;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

        .ps-form--search input:hover {
            background-color: #f9f9f9
        }

    .ps-form--search button {
        width: 30px;
        height: 30px;
        right: 10px;
        color: #333;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }

        .ps-form--search button i {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%)
        }

@media (max-width:1199px) {
    .ps-form--search input {
        background-color: #f9f9f9
    }
}

@media (max-width:479px) {
    .ps-form--search input {
        background-color: #fff
    }
}

.ps-form--create-tags .form-group, .ps-form--product-review .form-group {
    margin-bottom: 20px
}

    .ps-form--create-tags .form-group > label, .ps-form--product-review .form-group > label {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        color: #5b5b5b
    }

.ps-form--create-tags {
    max-width: 600px
}

    .ps-form--create-tags .form-group::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-form--create-tags input {
        float: left;
        width: calc(100% - 160px)
    }

    .ps-form--create-tags button {
        float: right;
        width: 150px;
        text-align: center;
        height: 50px;
        color: #fff;
        border: none;
        padding: 0;
        font-weight: 600;
        background-color: #5b5b5b;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

        .ps-form--create-tags button:hover {
            background-color: #df1166
        }

.ps-form--widget-search {
    position: relative
}

    .ps-form--widget-search input {
        border: 1px solid #f7f7f7;
        background-color: #f7f7f7
    }

    .ps-form--widget-search button {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 10px;
        background-color: transparent;
        border: none
    }

.ps-form--post-comment .form-control {
    background-color: #f7f7f7;
    border-color: #f7f7f7
}

.ps-form--contact {
    margin-bottom: 50px
}

    .ps-form--contact .form-group {
        margin-bottom: 20px
    }

        .ps-form--contact .form-group > label {
            margin-bottom: 10px;
            font-weight: 400;
            text-transform: uppercase;
            font-size: 14px
        }

        .ps-form--contact .form-group .form-control {
            background-color: #f7f7f7;
            border-color: #f7f7f7;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            transition: all 0.4s ease
        }

            .ps-form--contact .form-group .form-control:focus {
                background-color: #eaeaea;
                border-color: #eaeaea
            }

        .ps-form--contact .form-group button.ps-btn {
            padding-left: 60px;
            padding-right: 60px
        }

.ps-cart {
    position: relative;
    display: inline-block
}

.ps-cart__toggle {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url("/Content/theme/images/shopping-cart/shopping-cart (1).png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    vertical-align: top;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .ps-cart__toggle i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size: 20px;
        color: #fff
    }

    .ps-cart__toggle span {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border: 1px solid #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        background-color: #df1166
    }

        .ps-cart__toggle span i {
            font-size: 10px;
            font-style: normal
        }

    .ps-cart__toggle:hover {
        background-color: #df1166
    }

.ps-cart__listing {
    position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
    background-color: #fff;
    z-index: 1000;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,.2);
    -moz-box-shadow: 0 10px 30px 0 rgba(0,0,0,.2);
    -ms-box-shadow: 0 10px 30px 0 rgba(0,0,0,.2);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,.2);
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%)
}

.ps-cart__content {
    padding-top: 10px
}

.ps-cart__total {
    padding: 18px
}

    .ps-cart__total p {
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: #303030;
    }

        .ps-cart__total p span {
            float: right;
            font-weight: 600;
            letter-spacing: .05em
        }

.ps-cart .ps-cart__footer {
    line-height: 1em
}

    .ps-cart .ps-cart__footer a.ps-btn {
        width: 100%;
        font-weight: 700;
        text-align: center;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }

        .ps-cart .ps-cart__footer a.ps-btn i {
            position: relative;
            top: 2px;
            font-size: inherit;
            margin-left: 10px
        }

        .ps-cart .ps-cart__footer a.ps-btn:before {
            display: none
        }

.ps-cart:hover .ps-cart__listing {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
}

@media (max-width:1400px) {
    .ps-cart {
        position: relative
    }

        .ps-cart .ps-cart__listing {
            left: auto;
            right: 0;
            -webkit-transform: translate(0,30px);
            -moz-transform: translate(0,30px);
            -ms-transform: translate(0,30px);
            -o-transform: translate(0,30px);
            transform: translate(0,30px)
        }

            .ps-cart .ps-cart__listing:before {
                display: none
            }

        .ps-cart:hover .ps-cart__listing {
            -webkit-transform: translate(0,0);
            -moz-transform: translate(0,0);
            -ms-transform: translate(0,0);
            -o-transform: translate(0,0);
            transform: translate(0,0)
        }
}

@media (max-width:479px) {
    .ps-cart .ps-cart__listing {
        width: 300px;
        right: -55px
    }
}

.ps-cart-item {
    padding: 15px 10px;
    position: relative;
    border-bottom: 1px solid #525a71
}

    .ps-cart-item::after {
        clear: both;
        content: "";
        display: table
    }

.ps-cart-item__thumbnail {
    display: block;
    position: relative;
    float: left;
    width: 60px;
    height: 60px;
    overflow: hidden
}

    .ps-cart-item__thumbnail > img {
        vertical-align: top;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

    .ps-cart-item__thumbnail > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10
    }

    .ps-cart-item__thumbnail:hover > img {
        -webkit-transform: rodtatscale(1.2,1.2);
        -moz-transform: rodtatscale(1.2,1.2);
        -ms-transform: rodtatscale(1.2,1.2);
        -o-transform: rodtatscale(1.2,1.2);
        transform: rodtatscale(1.2,1.2)
    }

    .ps-cart-item__thumbnail:hover > a {
        background-color: rgba(255,169,39,.5)
    }

.ps-cart-item__title {
    display: block;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.5em;
    text-align: left;
    color: #fff
}

    .ps-cart-item__title:hover {
        color: #df1166
    }

.ps-cart-item__content {
    float: left;
    width: calc(100% - 60px);
    padding-left: 15px
}

    .ps-cart-item__content p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 1.43;
        color: #999
    }

        .ps-cart-item__content p span {
            margin-right: 25px
        }

        .ps-cart-item__content p i {
            font-style: normal;
            color: #fff;
            margin-left: 8px
        }

.ps-cart-item .ps-cart-item__close {
    display: block;
    position: absolute;
    top: 18px;
    right: 15px;
    width: 16px;
    height: 16px;
    background-color: #999;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    z-index: 1000
}

    .ps-cart-item .ps-cart-item__close:before, .ps-cart-item .ps-cart-item__close:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 1px;
        background-color: #fff;
        -webkit-transform: translate(-50%,-50%) rotate(45deg);
        -moz-transform: translate(-50%,-50%) rotate(45deg);
        -ms-transform: translate(-50%,-50%) rotate(45deg);
        -o-transform: translate(-50%,-50%) rotate(45deg);
        transform: translate(-50%,-50%) rotate(45deg)
    }

    .ps-cart-item .ps-cart-item__close:after {
        -webkit-transform: translate(-50%,-50%) rotate(-45deg);
        -moz-transform: translate(-50%,-50%) rotate(-45deg);
        -ms-transform: translate(-50%,-50%) rotate(-45deg);
        -o-transform: translate(-50%,-50%) rotate(-45deg);
        transform: translate(-50%,-50%) rotate(-45deg)
    }

    .ps-cart-item .ps-cart-item__close:hover {
        background-color: #df1166
    }

.ps-cart-item:last-child {
    border-bottom: none
}


.payment-method-option .radio-label {
    display: inline-block
}

.ps-checkout .ps-checkout__billing {
    padding-right: 100px
}

    .ps-checkout .ps-checkout__billing > h3 {
        margin-bottom: 30px;
        font-family: 'Montserrat', sans-serif;
        font-size: 30px;
        font-weight: 700;
        line-height: .7em;
        color: #000;
        text-transform: uppercase
    }

    .ps-checkout .ps-checkout__billing .form-group {
        margin-bottom: 20px
    }

.ps-checkout table.ps-checkout__products > thead > tr > th {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    border-bottom: none;
    font-weight: 600
}

    .ps-checkout table.ps-checkout__products > thead > tr > th:last-child {
        text-align: right
    }

.ps-checkout table.ps-checkout__products > tbody > tr > td {
    border-bottom: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #fff;
    border-top: none
}

    .ps-checkout table.ps-checkout__products > tbody > tr > td:last-child {
        text-align: right
    }

.ps-checkout .ps-checkout__block {
    margin-bottom: 10px;
    padding: 20px;
    border-bottom: 1px solid #4d4d4d
}

    .ps-checkout .ps-checkout__block .ps-radio label {
        color: #fff
    }

    .ps-checkout .ps-checkout__block .ps-btn:before {
        background-color: rgba(255,255,255,.2)
    }

@media (max-width:1199px) {
    .ps-checkout .ps-checkout__billing {
        padding-right: 0
    }
}

.ps-checkout__order {
    margin-bottom: 30px;
    background-color: #283044
}

    .ps-checkout__order h3 {
        margin-bottom: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        line-height: 1.8;
        color: #fff;
        text-transform: uppercase
    }

    .ps-checkout__order .ps-radio label {
        color: #fff
    }

    .ps-checkout__order > header {
        margin-bottom: 10px;
        padding: 20px 30px;
        border-bottom: 1px solid #f4eeea45
    }

    .ps-checkout__order .content {
        padding: 0 30px 20px
    }

    .ps-checkout__order > footer > h3 {
        padding: 10px 30px
    }

    .ps-checkout__order > footer .cheque {
        padding: 20px 30px;
        border-top: 1px solid #4d4d4d;
        border-bottom: 1px solid #4d4d4d
    }

        .ps-checkout__order > footer .cheque p {
            color: #fff;
            font-size: 12px
        }

    .ps-checkout__order > footer .paypal {
        padding: 10px 30px
    }

        .ps-checkout__order > footer .paypal .ps-btn {
            margin-top: 40px;
            margin-bottom: 15px
        }

    .ps-checkout__order .paypal .ps-radio {
        display: inline-block;
        margin-right: 30px
    }

.ps-checkout_userinfo {
    padding: 30px;
    border: 1px solid #e5e5e5
}

.ps-invoice {
    background-color: #313131;
    padding: 30px;
}

.label-info {
    color: #df1166;
    background-color: transparent;
}

.ps-invoice table > tbody > tr > td {
    border-bottom: none;
    color: #fff;
    border-top: none
}


    .ps-invoice table > tbody > tr > td > h4 {
        color: #fff;
        margin-bottom: 0;
    }

    .ps-invoice table > tbody > tr > td > span {
        color: #b4b4b4;
    }

.label-danger {
    color: #d9534f;
    background-color: transparent;
}

#finalize-btn-container .place-order-button .ps-btn {
    width: 100%
}

.ps-payment-method {
    display: inline-block
}

    .ps-payment-method li {
        display: inline-block;
        margin-right: 5px;
        background-color: #fff
    }

        .ps-payment-method li img {
            max-width: 50px
        }
/*Payment list start*/
.payment-list {
    list-style-type: none;
    padding-left: 0;
}

    .payment-list li {
        display: inline-block;
    }

    .payment-list input[type="checkbox"][name="rbCheckoutGroup"] {
        display: none;
    }

    .payment-list label {
        padding: 10px;
        display: block;
        position: relative;
        margin: 10px;
        cursor: pointer;
    }

        .payment-list label:before {
            background-color: white;
            color: white;
            content: " ";
            display: block;
            border-radius: 50%;
            border: 1px solid grey;
            position: absolute;
            top: -5px;
            left: -5px;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 28px;
            transition-duration: 0.4s;
            transform: scale(0);
        }

        .payment-list label img {
            height: 100px;
            width: 100px;
            transition-duration: 0.2s;
            transform-origin: 50% 50%;
            display: block;
            margin-bottom: 10px;
        }

/*:checked + label:before {
        content: "✓";
        background-color: #df1166;
        transform: scale(1);
    }*/
.payment-list :checked + label {
    background-color: #df1166;
}

:checked + label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
}

/*Payment list end*/
.ps-shipping {
    padding: 20px 30px;
    border: 1px solid #e5e5e5
}

    .ps-shipping > h3 {
        margin-bottom: 15px;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 1em;
        color: #5b5b5b
    }

.ps-cart-listing .ps-cart__table thead > tr > th {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #515356;
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5
}

.ps-cart-listing .ps-cart__table tbody > tr > td {
    vertical-align: middle;
    padding: 10px
}

.ps-cart-listing .ps-cart__actions {
    padding-top: 55px;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    flex-flow: row nowrap
}

    .ps-cart-listing .ps-cart__actions .ps-cart__promotion {
        min-width: calc(100% - 350px);
        width: calc(100% - 350px)
    }

        .ps-cart-listing .ps-cart__actions .ps-cart__promotion .form-group {
            max-width: 290px
        }

        .ps-cart-listing .ps-cart__actions .ps-cart__promotion .form-control {
            margin-bottom: 20px;
            height: 45px;
            padding: 0 20px;
            border-color: #d9d9d9;
            background-color: #fff
        }

        .ps-cart-listing .ps-cart__actions .ps-cart__promotion .ps-btn {
            width: 100%
        }

    .ps-cart-listing .ps-cart__actions .ps-cart__total {
        border-top: none
    }

        .ps-cart-listing .ps-cart__actions .ps-cart__total h3 {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #515356;
            text-transform: uppercase;
            vertical-align: middle;
            line-height: 30px
        }

            .ps-cart-listing .ps-cart__actions .ps-cart__total h3 span {
                float: right;
                font-size: 24px;
                color: #8d8d8d;
                vertical-align: middle
            }

@media (max-width:991px) {
    .ps-cart-listing .ps-cart__table {
        display: block;
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto
    }

        .ps-cart-listing .ps-cart__table tbody > tr > td:first-child {
            min-width: 300px
        }

        .ps-cart-listing .ps-cart__table tbody > tr > td:nth-child(3) {
            min-width: 300px
        }
}

@media (max-width:767px) {
    .ps-cart-listing .ps-cart__actions {
        display: block
    }

        .ps-cart-listing .ps-cart__actions::after {
            clear: both;
            content: "";
            display: table
        }

        .ps-cart-listing .ps-cart__actions .ps-cart__promotion {
            width: 100%;
            min-width: 0;
            max-width: 400px
        }

            .ps-cart-listing .ps-cart__actions .ps-cart__promotion .form-group {
                max-width: 100%
            }

    .ps-cart-listing .ps-cart__total {
        padding: 0
    }

    .ps-cart-listing .ps-cart__promotion, .ps-cart-listing .ps-cart__total {
        width: 100%;
        min-width: 0;
        max-width: 400px;
        float: right
    }

        .ps-cart-listing .ps-cart__promotion .ps-btn, .ps-cart-listing .ps-cart__total .ps-btn {
            width: 100%;
            text-align: center
        }
}

.ps-subscribe {
    padding: 45px 150px
}

    .ps-subscribe .ps-subscribe__content {
        margin-left: 30px;
        position: relative;
        padding-left: 100px
    }

        .ps-subscribe .ps-subscribe__content i {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            font-size: 20px;
            font-size: 45px
        }

        .ps-subscribe .ps-subscribe__content h3 {
            font-size: 18px;
            line-height: 1.45em;
            color: #656565;
            text-transform: uppercase;
            font-weight: 500
        }

        .ps-subscribe .ps-subscribe__content p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.5em
        }

            .ps-subscribe .ps-subscribe__content p strong {
                font-size: 16px
            }

    .ps-subscribe .form-group {
        position: relative;
        margin-bottom: 0;
        padding: 10px 10px 10px 100px;
        background-color: #f7f7f7;
        width: calc(100% - 70px);
        float: left
    }

        .ps-subscribe .form-group .form-group__icon {
            position: absolute;
            top: 15px;
            left: 35px;
            padding-right: 15px;
            display: inline-block;
            line-height: 30px;
            border-right: 1px solid #aaa
        }

            .ps-subscribe .form-group .form-group__icon i {
                color: #303030
            }

        .ps-subscribe .form-group input.form-control {
            border: none;
            height: 40px
        }

    .ps-subscribe button {
        float: right;
        width: 60px;
        height: 60px;
        background-color: #df1166;
        border: none;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out
    }

        .ps-subscribe button i {
            color: #fff;
            font-size: 20px
        }

        .ps-subscribe button:hover {
            background-color: #1f212e
        }

.ps-subscribe__btn {
    color: white !important;
}

.ps-subscribe__blc {
    position: absolute !important;
    left: -5000px !important;
}


#mc_embed_signup {
    clear: left;
    font: 14px Helvetica,Arial,sans-serif;
    width: 100%;
}

    #mc_embed_signup input#mce-EMAIL[type="email"]:focus {
        border: none !important;
    }

    #mc_embed_signup input#mce-EMAIL {
        border: none !important;
        background-color: #f7f7f7 !important;
        width: 100% !important
    }

    #mc_embed_signup input.email {
        height: 40px;
    }

@media (max-width:1439px) {
    .ps-subscribe {
        padding: 45px 100px
    }
}

@media (max-width:1199px) {
    .ps-subscribe {
        padding: 45px 50px
    }

        .ps-subscribe .ps-subscribe__content {
            margin-left: 0;
            padding-left: 70px
        }
}

@media (max-width:991px) {
    .ps-subscribe .ps-subscribe__content {
        text-align: center;
        margin-bottom: 30px;
        padding-top: 60px;
        padding-left: 0
    }

        .ps-subscribe .ps-subscribe__content i {
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%,0);
            -moz-transform: translate(-50%,0);
            -ms-transform: translate(-50%,0);
            -o-transform: translate(-50%,0);
            transform: translate(-50%,0)
        }

    .ps-subscribe .ps-form--subscribe {
        max-width: 450px;
        margin: 0 auto
    }
}

@media (max-width:767px) {
    .ps-subscribe {
        padding: 45px 15px
    }
}

@media (max-width:479px) {
    .ps-subscribe .form-group {
        padding-left: 0
    }

        .ps-subscribe .form-group .form-group__icon {
            display: none
        }
}

#contact-map {
    height: 560px
}

.ps-contact {
    padding: 75px 0
}

.ps-subscribe--popup {
    padding: 100px 60px
}

    .ps-subscribe--popup h3 {
        font-size: 32px;
        color: #000;
        text-transform: uppercase;
        font-weight: 700
    }

    .ps-subscribe--popup p {
        line-height: 1.6em
    }

        .ps-subscribe--popup p span {
            display: block;
            color: #df1166;
            font-size: 36px;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1.4em
        }

    .ps-subscribe--popup .form-group {
        position: relative;
        margin-bottom: 0;
        padding: 10px 10px 10px 100px;
        background-color: #fff;
        width: calc(100% - 70px);
        float: left
    }

        .ps-subscribe--popup .form-group .form-group__icon {
            position: absolute;
            top: 15px;
            left: 35px;
            padding-right: 15px;
            display: inline-block;
            line-height: 30px;
            border-right: 1px solid #aaa
        }

            .ps-subscribe--popup .form-group .form-group__icon i {
                color: #303030
            }

        .ps-subscribe--popup .form-group input.form-control {
            border: none;
            height: 40px
        }

    .ps-subscribe--popup button {
        float: right;
        width: 60px;
        height: 60px;
        background-color: #000;
        border: none;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        transition: all 0.4s ease-out
    }

        .ps-subscribe--popup button i {
            color: #fff;
            font-size: 20px
        }

    .ps-subscribe--popup .ps-form--subscribe-email {
        width: 400px
    }

@media (max-width:767px) {
    .ps-subscribe--popup {
        padding: 50px 30px
    }

        .ps-subscribe--popup .ps-form--subscribe-email {
            width: 100%
        }

        .ps-subscribe--popup .form-group {
            width: 100%
        }

        .ps-subscribe--popup button {
            margin-top: 15px;
            width: 100%
        }
}

@media (max-width:479px) {
    .ps-subscribe--popup .form-group {
        padding-left: 15px
    }

        .ps-subscribe--popup .form-group .form-group__icon {
            display: none
        }
}

.ps-section .ps-section__title {
    display: inline-block;
    position: relative;
    z-index: 10;
    font-size: 24px;
}

/*.ps-section .ps-section__title:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25px;
        z-index: -1}*/



.ps-section .ps-section__header {
    margin-bottom: 40px
}

    .ps-section .ps-section__header p {
        font-size: 14px;
        color: #777;
        line-height: 1.3em
    }

.ps-section--jewelry .ps-section__title {
    font-family: "Barlow", sans-serif;
    text-transform: uppercase;
}

.ps-section--partner {
    background-color: #e5e5e5
}

.ps-section--features {
    padding: 90px 0 50px;
}

.ps-section--offer-1 {
    background-color: #ebebeb
}

    .ps-section--offer-1 .ps-section__content {
        padding: 80px 0
    }

    .ps-section--offer-1 h3 {
        font-size: 30px;
        line-height: 1em;
        letter-spacing: .05em;
        color: #333;
        text-transform: uppercase
    }

    .ps-section--offer-1 h2 {
        font-size: 48px;
        line-height: 1em;
        letter-spacing: .1em;
        color: #ffb727
    }

        .ps-section--offer-1 h2 del {
            font-size: 30px;
            color: #aaa;
            font-weight: 700
        }

    .ps-section--offer-1 p {
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 1.8em;
        color: #777
    }

    .ps-section--offer-1 .ps-countdown {
        margin-bottom: 35px
    }
.product-listing-fixed {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.ps-section--offer-3 {
    padding: 0
}

    .ps-section--offer-3 h2 {
        font-size: 48px;
        line-height: 1em;
        letter-spacing: .1em;
        color: #ffb727
    }

    .ps-section--offer-3 a img {
        width: 100%;
    }

    .ps-section--offer-3 h2 del {
        font-size: 30px;
        color: #aaa;
        font-weight: 700
    }

    .ps-section--offer-3 h3 {
        font-size: 30px;
        line-height: 1em;
        letter-spacing: .05em;
        color: #333;
        text-transform: uppercase
    }

    .ps-section--offer-3 p {
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 1.8em;
        color: #777
    }

    .ps-section--offer-3 .ps-countdown {
        margin-bottom: 35px
    }

    .ps-section--offer-3 .ps-btn {
        padding-left: 60px;
        padding-right: 60px
    }

@media (max-width:767px) {
    .ps-section--offer-3 {
        background: #d2d0d4 !important
    }
}

.ps-section--special-product .ps-masonry__filter {
    margin-top: 40px
}

    .ps-section--special-product .ps-masonry__filter li {
        position: relative;
        display: inline-block;
        padding-right: 20px;
        margin-right: 20px
    }

        .ps-section--special-product .ps-masonry__filter li:after {
            content: '/';
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0;
            font-family: "Barlow", sans-serif;
            font-size: 24px;
            color: #dedede
        }

        .ps-section--special-product .ps-masonry__filter li a {
            padding-bottom: 5px;
            font-family: "Barlow", sans-serif;
            font-size: 14px;
            font-style: italic;
            color: #000;
            line-height: 1em
        }

            .ps-section--special-product .ps-masonry__filter li a:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 0;
                height: 3px;
                background-color: #df1166;
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                transition: all 0.4s ease;
                visibility: hidden;
                opacity: 0
            }

            .ps-section--special-product .ps-masonry__filter li a:hover:before {
                visibility: visible;
                opacity: 1;
                width: 100%
            }

        .ps-section--special-product .ps-masonry__filter li:last-child {
            margin-right: 0
        }

            .ps-section--special-product .ps-masonry__filter li:last-child:after {
                display: none
            }

        .ps-section--special-product .ps-masonry__filter li.current a:before {
            visibility: visible;
            opacity: 1;
            width: 100%
        }

.ps-home-slider {
    position: relative;
    top: -125px;
    margin-bottom: -125px
}

.ps-blog {
    padding: 40px 0
}

    .ps-blog .ps-blog__sidebar .widget {
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e5e5
    }

.ps-fashion3--banner::after {
    clear: both;
    content: "";
    display: table
}

.ps-fashion3--banner .left {
    width: calc(100% - 475px);
    float: left
}

.ps-fashion3--banner .right {
    float: right;
    width: 475px
}

.ps-fashion3--banner .ps-list--banner {
    margin: 0;
    padding: 0;
    list-style: none
}

    .ps-fashion3--banner .ps-list--banner li {
        position: relative;
        display: table;
        text-align: center;
        background-color: #261d3f;
        vertical-align: middle;
        width: 100%
    }

        .ps-fashion3--banner .ps-list--banner li:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            border-bottom: 1px solid #513a91
        }

        .ps-fashion3--banner .ps-list--banner li a {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            vertical-align: middle
        }

        .ps-fashion3--banner .ps-list--banner li.active a {
            background-color: #513a91
        }

        .ps-fashion3--banner .ps-list--banner li:last-child:after {
            display: none
        }

        .ps-fashion3--banner .ps-list--banner li:hover a {
            background-color: #513a91
        }

        .ps-fashion3--banner .ps-list--banner li:last-child a {
            border-bottom: none
        }

@media (max-width:1199px) {
    .ps-fashion3--banner .left {
        width: 100%;
        height: 100%
    }

    .ps-fashion3--banner .right {
        width: 100%
    }

    .ps-fashion3--banner .ps-list--banner::after {
        clear: both;
        content: "";
        display: table
    }

    .ps-fashion3--banner .ps-list--banner li {
        float: left;
        width: 50%
    }

        .ps-fashion3--banner .ps-list--banner li a {
            display: block;
            padding: 30px 50px
        }

        .ps-fashion3--banner .ps-list--banner li:last-child {
            width: 100%
        }
}

@media (max-width:767px) {
    .ps-fashion3--banner .ps-list--banner li {
        width: 100%
    }
}

@media (max-width:479px) {
    .ps-fashion3--banner .ps-list--banner li a {
        padding: 15px 30px;
        font-weight: 500;
        font-size: 13px
    }
}

.sub-menu {
    min-width: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    border-top: 1px solid #FBF5F3;
    -webkit-transition: all 0.4s cubic-bezier(.165,.84,.44,1);
    -moz-transition: all 0.4s cubic-bezier(.165,.84,.44,1);
    transition: all 0.4s cubic-bezier(.165,.84,.44,1);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px)
}

    .sub-menu > li {
        display: block;
        margin-right: 0;
        width: 100%;
        text-align: left;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease
    }

        .sub-menu > li > a {
            display: block;
            position: relative;
            z-index: 10;
            padding: 12px 15px;
            font-size: 13px;
            line-height: 20px;
            color: #1d1d1d;
            font-weight: 600;
            text-transform: uppercase;
            background-color: #fff;
            border-bottom: 1px solid #FBF5F3;
            -webkit-transition: all 0.5s cubic-bezier(.165,.84,.44,1);
            -moz-transition: all 0.5s cubic-bezier(.165,.84,.44,1);
            transition: all 0.5s cubic-bezier(.165,.84,.44,1);
            letter-spacing: 1px
        }

            .sub-menu > li > a:before {
                content: '';
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 5px;
                width: 0;
                height: 2px;
                background-color: #df1166;
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                transition: all 0.4s ease
            }

            .sub-menu > li > a:hover {
                padding-left: 25px;
                color: #df1166
            }

                .sub-menu > li > a:hover:before {
                    width: 15px
                }

        .sub-menu > li:last-child > a {
            border-bottom: none
        }

        .sub-menu > li.menu-item-has-children {
            position: relative
        }

            .sub-menu > li.menu-item-has-children > .sub-menu {
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                -webkit-transform: translateY(30px);
                -moz-transform: translateY(30px);
                -ms-transform: translateY(30px);
                -o-transform: translateY(30px);
                transform: translateY(30px);
                z-index: 1000;
                visibility: hidden;
                opacity: 0
            }

            .sub-menu > li.menu-item-has-children:hover > .sub-menu {
                visibility: visible;
                opacity: 1;
                -webkit-transform: translateY(0);
                -moz-transform: translateY(0);
                -ms-transform: translateY(0);
                -o-transform: translateY(0);
                transform: translateY(0)
            }

@media (max-width:1199px) {
    .sub-menu {
        min-width: 0
    }
}

.mega-menu {
    position: absolute;
    top: 100%;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 10000;
    background-color: #fff;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    -ms-box-shadow: 0 1px 2px rgba(0,0,0,.05);
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    visibility: hidden;
    opacity: 0;
    border-top: 1px solid #d8d8d8
}

    .mega-menu .mega-wrap {
        max-width: 1440px;
        margin: 0 auto;
        padding: 35px 30px;
        display: -webkit-box;
        display: -moz-box;
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row nowrap;
        -moz-flex-flow: row nowrap;
        flex-flow: row nowrap;
        text-align: left
    }

    .mega-menu .mega-heading {
        margin-bottom: 15px;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.8em;
        color: #1d1d1d;
        text-transform: uppercase
    }

    .mega-menu .mega-column {
        width: 20%;
        padding: 0 30px;
        border-right: 1px solid #d8d8d8
    }

        .mega-menu .mega-column:last-child {
            border-right: 0
        }

    .mega-menu .mega-item a {
        display: block;
        padding: 5px 0;
        position: relative;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: #1d1d1d;
        font-weight: 400
    }

        .mega-menu .mega-item a:before {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            content: '';
            width: 0;
            height: 1px;
            background-color: #df1166;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease
        }

        .mega-menu .mega-item a:hover {
            padding-left: 25px;
            color: #df1166
        }

            .mega-menu .mega-item a:hover:before {
                width: 20px
            }

    .mega-menu .mega-item.mega-features li {
        margin-bottom: 0
    }

    .mega-menu .mega-item.mega-features a {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.8em;
        color: #1d1d1d;
        text-transform: uppercase
    }

        .mega-menu .mega-item.mega-features a:hover {
            color: #df1166
        }

@media (max-width:1199px) {
    .mega-menu {
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: all 0s ease;
        -moz-transition: all 0s ease;
        transition: all 0s ease;
        visibility: visible;
        opacity: 1;
        display: none
    }

        .mega-menu .mega-wrap {
            -webkit-box-lines: multiple;
            -moz-box-lines: multiple;
            box-lines: multiple;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 10px 15px;
            border-top: 1px solid #FBF5F3
        }

        .mega-menu .mega-column {
            width: 100%;
            margin-bottom: 15px;
            padding: 0 0 15px;
            border-right: none;
            border-bottom: 1px solid #FBF5F3
        }

            .mega-menu .mega-column:last-child {
                border-bottom: none;
                padding-bottom: 0
            }

        .mega-menu .mega-item li a {
            font-size: 13px
        }

        .mega-menu .mega-item.mega-features li > a {
            font-size: 13px
        }
}

.menu {
    display: inline-block;
    vertical-align: top
}

    .menu::after {
        clear: both;
        content: "";
        display: table
    }

    .menu > li {
        position: relative;
        display: inline-block;
        float: left;
        margin-right: -10px
    }

        .menu > li > a {
            position: relative;
            display: inline-block;
            padding: 5px 20px;
            font-size: 15px;
            font-weight: 500;
            line-height: 40px;
            z-index: 10;
            text-transform: uppercase;
            color: #df1166;
            letter-spacing: 1px
        }

            .menu > li > a:before {
                content: '';
                display: block;
                position: absolute;
                height: 3px;
                width: 0;
                bottom: 2px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: #df1166;
                z-index: -1;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-transition: all 0.4s cubic-bezier(.165,.84,.44,1);
                -moz-transition: all 0.4s cubic-bezier(.165,.84,.44,1);
                transition: all 0.4s cubic-bezier(.165,.84,.44,1)
            }

            .menu > li > a:hover:before {
                visibility: visible;
                opacity: 1;
                width: 100%
            }

        .menu > li > .sub-menu {
            visibility: hidden;
            opacity: 0
        }

        .menu > li.has-mega-menu {
            position: static
        }

        .menu > li.dropdown {
            position: relative
        }

            .menu > li.dropdown:hover > .sub-menu {
                visibility: visible;
                opacity: 1
            }

        .menu > li.current-menu-item > a:before {
            visibility: visible;
            opacity: 1;
            width: 100%
        }

        .menu > li:hover > .sub-menu {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0)
        }

        .menu > li:hover .mega-menu {
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
            opacity: 1
        }

@media (max-width:1199px) {
    .menu {
        display: block
    }

        .menu > li {
            display: block;
            float: none;
            width: 100%;
            border-bottom: 1px solid #FBF5F3
        }

            .menu > li > a {
                display: block;
                line-height: 20px;
                font-weight: 600;
                font-size: 13px
            }

                .menu > li > a:before {
                    display: none
                }

            .menu > li:hover > a, .menu > li.current > a {
                background-color: #e5e5e5;
                color: #303030
            }

            .menu > li.menu-item-has-children > a:after {
                content: "\f107";
                font-family: FontAwesome;
                display: inline-block;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 10px
            }

        .menu .sub-menu {
            padding: 0;
            position: relative;
            -webkit-transition: all 0s ease;
            -moz-transition: all 0s ease;
            transition: all 0s ease
        }

            .menu .sub-menu > li {
                border-bottom: 1px solid #FBF5F3
            }

                .menu .sub-menu > li > a {
                    padding: 10px 15px
                }

                .menu .sub-menu > li:last-child {
                    border-bottom: 0
                }

                .menu .sub-menu > li.menu-item-has-children > .sub-menu {
                    position: relative;
                    top: 0;
                    left: 0;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0)
                }

                    .menu .sub-menu > li.menu-item-has-children > .sub-menu li a {
                        padding-left: 30px
                    }
}

.menu--2 > li > a {
    font-family: "Barlow", sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 16px
}

    .menu--2 > li > a:before {
        height: 3px
    }

.header__actions {
    float: right
}

    .header__actions > a, .header__actions .ps-dropdown > a {
        display: inline-block;
        padding: 0 15px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        text-align: center;
        color: #656565;
        text-transform: uppercase;
        line-height: 25px;
        border-left: 1px solid #ddd;
        vertical-align: top;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        box-shadow: none
    }

        .header__actions > a > i.fa.fa-angle-down, .header__actions .ps-dropdown > a > i.fa.fa-angle-down {
            margin-left: 5px
        }

        .header__actions > a:hover, .header__actions .ps-dropdown > a:hover {
            background-color: #e5e5e5;
            color: #df1166
        }

    .header__actions > a {
        margin-right: -4px
    }

    .header__actions .ps-dropdown {
        margin-right: -4px
    }

        .header__actions .ps-dropdown.open a.dropdown-toggle {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -ms-box-shadow: none;
            box-shadow: none;
            background-color: #e5e5e5;
            color: #df1166
        }

        .header__actions .ps-dropdown .dropdown-menu {
            padding: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            border-radius: 0;
            border: none
        }

            .header__actions .ps-dropdown .dropdown-menu li a {
                padding: 9px 20px;
                display: block;
                line-height: 20px;
                border-bottom: 1px solid #e5e5e5;
                font-size: 13px;
                color: #8d8d8d;
                text-transform: uppercase
            }

                .header__actions .ps-dropdown .dropdown-menu li a img {
                    max-width: 20px;
                    border: 1px solid #FBF5F3;
                    margin-right: 10px
                }

                .header__actions .ps-dropdown .dropdown-menu li a:hover {
                    background-color: #df1166;
                    color: #fff
                }

            .header__actions .ps-dropdown .dropdown-menu li:last-child {
                border-bottom: none
            }

        .header__actions .ps-dropdown:last-child > a {
            border-right: 1px solid #e5e5e5
        }

@media (max-width:479px) {
    .header__actions > a, .header__actions .ps-dropdown > a {
        font-size: 12px
    }
}

.navigation {
    position: relative;
    color: #fff;
    padding: 5px 0;
    background: rgba(255,255,255,.5);
    border-bottom: 1px solid #ddd;
}

    .navigation > .container-fluid .left, .navigation > .container-fluid .center, .navigation > .container-fluid .right {
        float: left
    }

    .navigation .left {
        width: 15%
    }

    .navigation .center {
        width: 60%;
        text-align: center
    }

    .navigation .right {
        width: 25%
    }

        .navigation .right::after {
            clear: both;
            content: "";
            display: table
        }

        .navigation .right .ps-form--search, .navigation .right .ps-cart {
            margin-bottom: 0;
            float: right;
            margin-top: 10px;
            vertical-align: middle
        }

        .navigation .right .ps-form--search {
            margin-right: 15px;
            margin-top: 5px
        }

@media (max-width:1199px) {
    .navigation .left {
        width: 50%
    }

    .navigation .center {
        width: 0
    }

    .navigation .right {
        width: 50%
    }

        .navigation .right .ps-cart {
            margin-right: 10px
        }

        .navigation .right .menu-toggle {
            float: right
        }
}

@media (max-width:991px) {
    .navigation .left {
        width: 35%
    }

    .navigation .center {
        width: 0
    }

    .navigation .right {
        width: 65%
    }
}

@media (max-width:767px) {
    .navigation .left {
        width: 30%
    }

    .navigation .right {
        width: 70%
    }
}

.header {
    position: relative;
    z-index: 100;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .header .ps-logo {
        display: inline-block;
        max-width: 100px;
        line-height: 50px
    }

    .header .header__top {
        border-bottom: 1px solid #ddd;
        background-color: #fff
    }

        .header .header__top p {
            margin-bottom: 0;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            text-align: left;
            color: #656565;
            line-height: 25px
        }

@media (max-width:1199px) {
    .header {
        position: relative
    }
}

@media (max-width:991px) {
    .header .header__top p {
        display: none
    }

    .header .ps-logo {
        max-width: 120px
    }
}

@media (max-width:767px) {
    .header .ps-search--header {
        max-width: 200px
    }
}

.header--sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 240px;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    background-color: #f9f9f9;
    border-left: 1px solid #FBF5F3;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
    -moz-transition: all 0.4s cubic-bezier(.25,.46,.45,.94);
    transition: all 0.4s cubic-bezier(.25,.46,.45,.94)
}

    .header--sidebar.active {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }

@media (max-width:479px) {
    .header--sidebar .ps-form--search {
        padding: 15px 15px
    }

        .header--sidebar .ps-form--search input {
            border: 1px solid #FBF5F3;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            border-radius: 0
        }

        .header--sidebar .ps-form--search button {
            right: 15px
        }

    h1 {
        font-size: 24px !important;
        line-height: normal;
    }

    .ps-section .ps-section__title {
        font-size: 18px;
    }
}

.navigation--sticky {
    position: fixed;
    border-bottom: 1px solid #e5e5e5;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .navigation--sticky .navigation {
        background-color: #fff
    }

    .navigation--sticky .ps-form--search input {
        background-color: #f7f7f7
    }

    .navigation--sticky.pin {
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0)
    }

    .navigation--sticky.unpin {
        -webkit-transform: translate(0,-100%);
        -moz-transform: translate(0,-100%);
        -ms-transform: translate(0,-100%);
        -o-transform: translate(0,-100%);
        transform: translate(0,-100%)
    }

@media (max-width:1199px) {
    .navigation--sticky {
        background-color: #000 !important
    }

        .navigation--sticky.header--2 .ps-logo {
            margin-top: 5px
        }
}

.menu--active {
    -webkit-transform: translateX(-240px);
    -moz-transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    -o-transform: translateX(-240px);
    transform: translateX(-240px)
}

    .menu--active.pin {
        -webkit-transform: translate(-240px,0);
        -moz-transform: translate(-240px,0);
        -ms-transform: translate(-240px,0);
        -o-transform: translate(-240px,0);
        transform: translate(-240px,0)
    }

    .menu--active.unpin {
        -webkit-transform: translate(-240px,-100%);
        -moz-transform: translate(-240px,-100%);
        -ms-transform: translate(-240px,-100%);
        -o-transform: translate(-240px,-100%);
        transform: translate(-240px,-100%)
    }

.header--2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100
}

    .header--2 .header__top {
        border-bottom: 1px solid #525252
    }

        .header--2 .header__top .header__actions {
            font-size: 0
        }

            .header--2 .header__top .header__actions > a {
                margin-right: 0;
                border-color: #525252;
                color: #fff
            }

                .header--2 .header__top .header__actions > a:hover {
                    color: #fff;
                    background-color: #df1166
                }

            .header--2 .header__top .header__actions .ps-dropdown {
                margin-right: 0
            }

                .header--2 .header__top .header__actions .ps-dropdown a {
                    color: #fff;
                    border-color: #525252
                }

                    .header--2 .header__top .header__actions .ps-dropdown a:hover {
                        background-color: #df1166
                    }

                .header--2 .header__top .header__actions .ps-dropdown .dropdown-menu li a {
                    color: #303030;
                    border-bottom: 1px solid rgba(0,0,0,.1)
                }

                    .header--2 .header__top .header__actions .ps-dropdown .dropdown-menu li a:hover {
                        color: #fff
                    }

        .header--2 .header__top .left {
            float: left;
            width: 20%
        }

            .header--2 .header__top .left .ps-dropdown .dropdown-menu {
                right: auto;
                left: 0
            }

        .header--2 .header__top .center {
            float: left;
            width: 60%;
            text-align: center;
            overflow-x: hidden
        }

            .header--2 .header__top .center p {
                margin-bottom: 0;
                color: #fff;
                line-height: 40px
            }

                .header--2 .header__top .center p span {
                    color: #ff0e0e
                }

        .header--2 .header__top .right {
            float: right;
            width: 20%
        }

    .header--2 .navigation::after {
        clear: both;
        content: "";
        display: table
    }

    .header--2 .navigation .left, .header--2 .navigation .right {
        width: calc(50% - 90px)
    }

    .header--2 .navigation .left {
        float: left;
        padding-right: 60px;
        text-align: right
    }

    .header--2 .navigation .center {
        float: left;
        width: 180px
    }

    .header--2 .navigation .right {
        float: right;
        padding-left: 60px
    }

    .header--2 .navigation--mobile {
        padding: 10px 0;
        display: none
    }

        .header--2 .navigation--mobile .ps-logo {
            margin-left: 20px;
            display: inline-block;
            max-width: 120px
        }

        .header--2 .navigation--mobile .menu-toggle {
            float: right;
            margin-top: 10px;
            margin-right: 10px
        }

            .header--2 .navigation--mobile .menu-toggle span, .header--2 .navigation--mobile .menu-toggle:before, .header--2 .navigation--mobile .menu-toggle:after {
                background-color: #fff
            }

            .header--2 .navigation--mobile .menu-toggle.active span, .header--2 .navigation--mobile .menu-toggle.active:before, .header--2 .navigation--mobile .menu-toggle.active:after {
                background-color: #fc354c
            }

        .header--2 .navigation--mobile .menu--2 {
            display: none;
            background-color: #fff
        }

            .header--2 .navigation--mobile .menu--2 > li {
                background-color: #f9f9f9
            }

                .header--2 .navigation--mobile .menu--2 > li > a {
                    color: #303030
                }

                .header--2 .navigation--mobile .menu--2 > li .sub-menu > li > a {
                    padding-left: 30px
                }

    .header--2 .header__actions.left .btn-group > a:first-child {
        border-left: none
    }

    .header--2 .header__actions .ps-dropdown:last-child > a {
        border-right: none
    }

@media (max-width:1440px) {
    .header--2 .header__top .center {
        width: 50%
    }

        .header--2 .header__top .center p {
            font-size: 11px
        }

    .header--2 .header__top .right {
        text-align: right;
        width: 30%
    }
}

@media (max-width:1199px) {
    .header--2 .header__top .center {
        width: 0
    }

        .header--2 .header__top .center p {
            display: none
        }

    .header--2 .header__top .left, .header--2 .header__top .right {
        width: 50%
    }

    .header--2 .navigation {
        display: none
    }

    .header--2 .navigation--mobile {
        display: block
    }
}

@media (max-width:510px) {
    .header--2 .header__top .container-fluid {
        padding: 0
    }

    .header--2 .header__top .left, .header--2 .header__top .right {
        width: 100%
    }

    .header--2 .header__top .left {
        display: none;
        text-align: right;
        border-bottom: 1px solid #525252
    }
}

.header--3 .header__actions {
    float: none
}

    .header--3 .header__actions .ps-dropdown a {
        border: none;
        color: #333;
        font-weight: 500
    }

    .header--3 .header__actions .ps-dropdown .dropdown-menu li a {
        color: #333
    }

.header--3 .header__links a {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #303030
}

    .header--3 .header__links a > i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size: 16px
    }

    .header--3 .header__links a:hover {
        color: #df1166
    }

    .header--3 .header__links a:last-child {
        margin-right: 0
    }

.header--3 .header__links .cart span {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: #df1166;
    font-size: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%
}

    .header--3 .header__links .cart span i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-style: normal
    }

.header--3 .header__links .cart:hover span i {
    color: #fff
}

.header--3 .header__top {
    display: table;
    width: 100%;
    padding-top: 30px
}

    .header--3 .header__top .left, .header--3 .header__top .center, .header--3 .header__top .right {
        display: table-cell;
        table-layout: fixed;
        width: calc(100% / 3)
    }

    .header--3 .header__top .center {
        text-align: center
    }

    .header--3 .header__top .right {
        text-align: right
    }

.header--3 .menu > li {
    float: none
}

.header--3 .navigation {
    text-align: center
}

.header--3 .header__services {
    background-color: #f7f7f7;
    overflow: hidden;
    max-height: 45px
}

@media (max-width:1199px) {
    .header--3 .navigation {
        padding: 0;
        background-color: #f7f7f7
    }

        .header--3 .navigation .menu-toggle {
            float: right
        }

        .header--3 .navigation .menu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 100;
            width: 100%;
            max-height: 100vh;
            overflow-y: auto;
            background-color: #fff;
            display: none
        }

            .header--3 .navigation .menu li {
                text-align: left
            }
}

@media (max-width:640px) {
    .header--3 .header__top {
        padding: 10px 0
    }

        .header--3 .header__top .left {
            float: right;
            display: none
        }

        .header--3 .header__top .center {
            float: left
        }

        .header--3 .header__top .right {
            padding-top: 20px;
            vertical-align: top
        }
}

@media (max-width:767px) {
    .header--3 .header__services {
        display: none
    }
}

@media (max-width:479px) {
    .header--3 .header__top .center {
        width: 100px
    }

    .header--3 .header__top .right {
        width: calc(100% - 150px)
    }
}

.menu--sidebar {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden
}

    .menu--sidebar > li {
        display: block;
        float: none;
        margin: 0 0 10px;
        text-align: center
    }

        .menu--sidebar > li > a {
            color: #fff;
            font-size: 22px
        }

        .menu--sidebar > li .sub-menu {
            position: relative;
            -webkit-transform: translate(0,0);
            -moz-transform: translate(0,0);
            -ms-transform: translate(0,0);
            -o-transform: translate(0,0);
            transform: translate(0,0);
            border: none;
            visibility: visible;
            opacity: 1;
            display: none;
            -webkit-transition: all 0s ease;
            -moz-transition: all 0s ease;
            transition: all 0s ease
        }

            .menu--sidebar > li .sub-menu > li > a {
                text-align: center;
                font-size: 14px;
                color: #fff;
                background: none;
                border: none;
                font-weight: 400
            }

            .menu--sidebar > li .sub-menu > li.menu-item-has-children > a {
                position: relative
            }

                .menu--sidebar > li .sub-menu > li.menu-item-has-children > a:after {
                    content: "\f055";
                    font-family: FontAwesome;
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                    right: 15px
                }

            .menu--sidebar > li .sub-menu > li.menu-item-has-children > .sub-menu {
                position: relative;
                top: 0;
                left: 0;
                visibility: visible;
                opacity: 1;
                -webkit-transform: translate(0,0);
                -moz-transform: translate(0,0);
                -ms-transform: translate(0,0);
                -o-transform: translate(0,0);
                transform: translate(0,0)
            }

@media (max-width:1199px) {
    .menu--sidebar > li {
        margin-bottom: 0
    }

        .menu--sidebar > li > a {
            font-size: 14px;
            font-weight: 500
        }
}

@media (max-width:479px) {
    .menu--sidebar {
        max-height: 300px
    }
}

.header--left {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    z-index: 10000;
    background-color: #2b3147;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease
}

    .header--left .header__top {
        margin-bottom: 50px
    }

        .header--left .header__top .header__actions {
            margin-bottom: 40px;
            float: none;
            border-bottom: 1px solid #5b658c
        }

            .header--left .header__top .header__actions > a, .header--left .header__top .header__actions .ps-dropdown > a {
                font-size: 12px;
                border-color: #5b658c
            }

                .header--left .header__top .header__actions > a:hover, .header--left .header__top .header__actions .ps-dropdown > a:hover {
                    background-color: transparent
                }

            .header--left .header__top .header__actions > a {
                border: none
            }

            .header--left .header__top .header__actions .ps-dropdown {
                font-size: 0
            }

                .header--left .header__top .header__actions .ps-dropdown:last-child a {
                    border-right: none
                }

    .header--left .header__brand {
        text-align: center
    }

        .header--left .header__brand .ps-logo {
            display: inline-block;
            margin-bottom: 20px
        }

    .header--left .header__links a {
        position: relative;
        margin-right: 15px;
        display: inline-block;
        width: 40px;
        height: 40px;
        color: #fff
    }

        .header--left .header__links a i {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%)
        }

        .header--left .header__links a:hover {
            color: #df1166
        }

    .header--left .header__links .cart span {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background-color: #df1166;
        font-size: 10px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%
    }

        .header--left .header__links .cart span i {
            font-style: normal;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -moz-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%)
        }

    .header--left .header__links .cart:hover span i {
        color: #fff
    }

    .header--left .header__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-bottom: 50px
    }

        .header--left .header__bottom .ps-list--social {
            margin-bottom: 20px
        }

        .header--left .header__bottom .ps-payment-methods a {
            display: inline-block;
            margin-right: 10px
        }

        .header--left .header__bottom p {
            font-size: 14px;
            color: #fff
        }

            .header--left .header__bottom p a {
                color: #df1166
            }

                .header--left .header__bottom p a:hover {
                    text-decoration: underline
                }

    .header--left .menu-toggle {
        position: absolute;
        top: 0;
        left: 100%;
        background-color: #fff;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }

@media (max-width:1400px) {
    .header--left .header__bottom {
        padding-bottom: 20px
    }

        .header--left .header__bottom .ps-list--social {
            display: none
        }
}

@media (max-width:1199px) {
    .header--left {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%)
    }

        .header--left.active {
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0)
        }

        .header--left .header__top {
            margin-bottom: 0
        }

            .header--left .header__top .header__actions {
                margin-bottom: 10px
            }

        .header--left .header__brand .ps-logo {
            width: 60px
        }
}

@media (max-width:767px) {
    .header--left {
        width: 240px
    }
}

@media (max-width:479px) {
    .header--left .header__actions > a {
        display: none
    }

    .header--left .header__bottom {
        padding-bottom: 0
    }

        .header--left .header__bottom .ps-list--social {
            display: none
        }
}

.ps-site-info {
    padding-right: 150px
}

    .ps-site-info .ps-logo {
        display: inline-block;
        margin-bottom: 20px;
        max-width: 150px
    }

    .ps-site-info p {
        margin-bottom: 15px;
        font-size: 14px;
        color: #403c34
    }

    .ps-site-info .ps-site-info__contact h4 {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 16px;
        color: #656565
    }

    .ps-site-info .ps-social {
        padding-top: 5px
    }

@media (max-width:767px) {
    .ps-site-info {
        padding-right: 0
    }
}

.ps-footer {
    position: relative;
    padding-top: 60px;
    background-color: #f8f8f8
}

    .ps-footer .ps-footer__content {
        padding-bottom: 5px
    }

    .ps-footer .ps-footer__copyright {
        border-top: 1px solid #ddd;
        padding: 0
    }

        .ps-footer .ps-footer__copyright p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            color: #777;
            letter-spacing: .05em;
            line-height: 40px;
            margin-top: 15px
        }

            .ps-footer .ps-footer__copyright p span {
                color: #fff;
                font-weight: 700
            }

            .ps-footer .ps-footer__copyright p a {
                font-weight: 700;
                color: #df1166
            }

                .ps-footer .ps-footer__copyright p a:hover {
                    color: #fff
                }

    .ps-footer .ps-footer__payment-methods {
        text-align: right
    }

        .ps-footer .ps-footer__payment-methods a {
            display: inline-block;
            line-height: 40px;
            margin-right: 20px;
            margin-top: 10px;
            margin-bottom: 10px
        }

            .ps-footer .ps-footer__payment-methods a img {
                height: 40px;
                margin-top: .5rem;
                margin-bottom: .5rem
            }

            .ps-footer .ps-footer__payment-methods a:last-child {
                margin-right: 0
            }


@media (max-width:479px) {
    .ps-footer .ps-footer__copyright {
        text-align: center
    }

        .ps-footer .ps-footer__copyright p {
            font-size: 11px
        }

    .ps-footer .ps-footer__payment-methods {
        text-align: center
    }
}

.ma-0 {
    margin: 0
}

.pd-0 {
    padding: 0
}

.mt-0 {
    margin-top: 0
}

.mr-0 {
    margin-right: 0
}

.mb-0 {
    margin-bottom: 0
}

.ml-0 {
    margin-left: 0
}

.pt-0 {
    padding-top: 0
}

.pr-0 {
    padding-right: 0
}

.pb-0 {
    padding-bottom: 0
}

.pl-0 {
    padding-left: 0
}

.mt-5 {
    margin-top: 5px
}

.mr-5 {
    margin-right: 5px
}

.mb-5 {
    margin-bottom: 5px
}

.ml-5 {
    margin-left: 5px
}

.pt-5 {
    padding-top: 5px
}

.pr-5 {
    padding-right: 5px
}

.pb-5 {
    padding-bottom: 5px
}

.pl-5 {
    padding-left: 5px
}

.mt-10 {
    margin-top: 10px
}

.mr-10 {
    margin-right: 10px
}

.mb-10 {
    margin-bottom: 10px
}

.ml-10 {
    margin-left: 10px
}

.pt-10 {
    padding-top: 10px
}

.pr-10 {
    padding-right: 10px
}

.pb-10 {
    padding-bottom: 10px
}

.pl-10 {
    padding-left: 10px
}

.mt-15 {
    margin-top: 15px
}

.mr-15 {
    margin-right: 15px
}

.mb-15 {
    margin-bottom: 15px
}

.ml-15 {
    margin-left: 15px
}

.pt-15 {
    padding-top: 15px
}

.pr-15 {
    padding-right: 15px
}

.pb-15 {
    padding-bottom: 15px
}

.pl-15 {
    padding-left: 15px
}

.mt-20 {
    margin-top: 20px
}

.mr-20 {
    margin-right: 20px
}

.mb-20 {
    margin-bottom: 20px
}

.ml-20 {
    margin-left: 20px
}

.pt-20 {
    padding-top: 20px
}

.pr-20 {
    padding-right: 20px
}

.pb-20 {
    padding-bottom: 20px
}

.pl-20 {
    padding-left: 20px
}

.mt-25 {
    margin-top: 25px
}

.mr-25 {
    margin-right: 25px
}

.mb-25 {
    margin-bottom: 25px
}

.ml-25 {
    margin-left: 25px
}

.pt-25 {
    padding-top: 25px
}

.pr-25 {
    padding-right: 25px
}

.pb-25 {
    padding-bottom: 25px
}

.pl-25 {
    padding-left: 25px
}

.mt-30 {
    margin-top: 30px
}

.mr-30 {
    margin-right: 30px
}

.mb-30 {
    margin-bottom: 30px
}

.ml-30 {
    margin-left: 30px
}

.pt-30 {
    padding-top: 30px
}

.pr-30 {
    padding-right: 30px
}

.pb-30 {
    padding-bottom: 30px
}

.pl-30 {
    padding-left: 30px
}

.mt-35 {
    margin-top: 35px
}

.mr-35 {
    margin-right: 35px
}

.mb-35 {
    margin-bottom: 35px
}

.ml-35 {
    margin-left: 35px
}

.pt-35 {
    padding-top: 35px
}

.pr-35 {
    padding-right: 35px
}

.pb-35 {
    padding-bottom: 35px
}

.pl-35 {
    padding-left: 35px
}

.mt-40 {
    margin-top: 40px
}

.mr-40 {
    margin-right: 40px
}

.mb-40 {
    margin-bottom: 40px
}

.ml-40 {
    margin-left: 40px
}

.pt-40 {
    padding-top: 40px
}

.pr-40 {
    padding-right: 40px
}

.pb-40 {
    padding-bottom: 40px
}

.pl-40 {
    padding-left: 40px
}

.mt-45 {
    margin-top: 45px
}

.mr-45 {
    margin-right: 45px
}

.mb-45 {
    margin-bottom: 45px
}

.ml-45 {
    margin-left: 45px
}

.pt-45 {
    padding-top: 45px
}

.pr-45 {
    padding-right: 45px
}

.pb-45 {
    padding-bottom: 45px
}

.pl-45 {
    padding-left: 45px
}

.mt-50 {
    margin-top: 50px
}

.mr-50 {
    margin-right: 50px
}

.mb-50 {
    margin-bottom: 50px
}

.ml-50 {
    margin-left: 50px
}

.pt-50 {
    padding-top: 50px
}

.pr-50 {
    padding-right: 50px
}

.pb-50 {
    padding-bottom: 50px
}

.pl-50 {
    padding-left: 50px
}

.mt-55 {
    margin-top: 55px
}

.mr-55 {
    margin-right: 55px
}

.mb-55 {
    margin-bottom: 55px
}

.ml-55 {
    margin-left: 55px
}

.pt-55 {
    padding-top: 55px
}

.pr-55 {
    padding-right: 55px
}

.pb-55 {
    padding-bottom: 55px
}

.pl-55 {
    padding-left: 55px
}

.mt-60 {
    margin-top: 60px
}

.mr-60 {
    margin-right: 60px
}

.mb-60 {
    margin-bottom: 60px
}

.ml-60 {
    margin-left: 60px
}

.pt-60 {
    padding-top: 60px
}

.pr-60 {
    padding-right: 60px
}

.pb-60 {
    padding-bottom: 60px
}

.pl-60 {
    padding-left: 60px
}

.mt-65 {
    margin-top: 65px
}

.mr-65 {
    margin-right: 65px
}

.mb-65 {
    margin-bottom: 65px
}

.ml-65 {
    margin-left: 65px
}

.pt-65 {
    padding-top: 65px
}

.pr-65 {
    padding-right: 65px
}

.pb-65 {
    padding-bottom: 65px
}

.pl-65 {
    padding-left: 65px
}

.mt-70 {
    margin-top: 70px
}

.mr-70 {
    margin-right: 70px
}

.mb-70 {
    margin-bottom: 70px
}

.ml-70 {
    margin-left: 70px
}

.pt-70 {
    padding-top: 70px
}

.pr-70 {
    padding-right: 70px
}

.pb-70 {
    padding-bottom: 70px
}

.pl-70 {
    padding-left: 70px
}

.mt-75 {
    margin-top: 75px
}

.mr-75 {
    margin-right: 75px
}

.mb-75 {
    margin-bottom: 75px
}

.ml-75 {
    margin-left: 75px
}

.pt-75 {
    padding-top: 75px
}

.pr-75 {
    padding-right: 75px
}

.pb-75 {
    padding-bottom: 75px
}

.pl-75 {
    padding-left: 75px
}

.mt-80 {
    margin-top: 80px
}

.mr-80 {
    margin-right: 80px
}

.mb-80 {
    margin-bottom: 80px
}

.ml-80 {
    margin-left: 80px
}

.pt-80 {
    padding-top: 80px
}

.pr-80 {
    padding-right: 80px
}

.pb-80 {
    padding-bottom: 80px
}

.pl-80 {
    padding-left: 80px
}

.mt-85 {
    margin-top: 85px
}

.mr-85 {
    margin-right: 85px
}

.mb-85 {
    margin-bottom: 85px
}

.ml-85 {
    margin-left: 85px
}

.pt-85 {
    padding-top: 85px
}

.pr-85 {
    padding-right: 85px
}

.pb-85 {
    padding-bottom: 85px
}

.pl-85 {
    padding-left: 85px
}

.mt-90 {
    margin-top: 90px
}

.mr-90 {
    margin-right: 90px
}

.mb-90 {
    margin-bottom: 90px
}

.ml-90 {
    margin-left: 90px
}

.pt-90 {
    padding-top: 90px
}

.pr-90 {
    padding-right: 90px
}

.pb-90 {
    padding-bottom: 90px
}

.pl-90 {
    padding-left: 90px
}

.mt-95 {
    margin-top: 95px
}

.mr-95 {
    margin-right: 95px
}

.mb-95 {
    margin-bottom: 95px
}

.ml-95 {
    margin-left: 95px
}

.pt-95 {
    padding-top: 95px
}

.pr-95 {
    padding-right: 95px
}

.pb-95 {
    padding-bottom: 95px
}

.pl-95 {
    padding-left: 95px
}

.mt-100 {
    margin-top: 100px
}

.mr-100 {
    margin-right: 100px
}

.mb-100 {
    margin-bottom: 100px
}

.ml-100 {
    margin-left: 100px
}

.pt-100 {
    padding-top: 100px
}

.pr-100 {
    padding-right: 100px
}

.pb-100 {
    padding-bottom: 100px
}

.pl-100 {
    padding-left: 100px
}

.fw-300 {
    font-weight: 300
}

.fw-500 {
    font-weight: 500
}

.fw-700 {
    font-weight: 700
}

.fw-800 {
    font-weight: 800
}

.fw-900 {
    font-weight: 900
}

.ps-fullwidth {
    width: 100%
}

html .bg--parallax {
    position: relative;
    z-index: 10;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50%;
    background-size: cover !important
}

.bg--cover {
    /*background-position: 50% 50% !important;*/
    background-size: 100% 100% !important
}

.ps-sidebar ul, .ps-block--offer ul, .ps-block--contact ul, .widget_category ul, .ps-product--fashion ul, .ps-product--yewelry ul, .ps-filter ul, .ps-checkout__order ul, .ps-section--special-product ul, .header ul, .header--sidebar ul, .header--2 ul, .header--3 ul, .header--left ul, .ps-footer ul, .ps-sidebar ol, .ps-block--offer ol, .ps-block--contact ol, .widget_category ol, .ps-product--fashion ol, .ps-product--yewelry ol, .ps-filter ol, .ps-checkout__order ol, .ps-section--special-product ol, .header ol, .header--sidebar ol, .header--2 ol, .header--3 ol, .header--left ol, .ps-footer ol {
    margin: 0;
    padding: 0;
    list-style: none
}

.size-list {
    padding-left: 0;
}

.logo-pdn {
    line-height: 50px !important;
    padding: 0 10px 0 10px !important;
}

.msg-header {
    background-color: #f7f7f7;
    text-align: center;
    color: #5f6368;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
}

.size-tb {
    width: 100%
}

    .size-tb th, td {
        padding: 10px;
    }

    .size-tb th {
        background-color: #f3a0cc;
        color: #373737;
        border: 1px solid #e5e5e5;
        font-weight: 600;
    }

    .size-tb td {
        color: #373737;
        border: 1px solid #e5e5e5;
    }

.measures-row td {
    background-color: #e5e5e5;
    padding: 5px;
}

.border-b {
    border-bottom: 1px solid #ffffff24
}



/* Discount sticker*/
.sticker {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 52px !important;
    height: 52px;
    z-index: 20;
}


#tableModalButton {
    font-weight: 600;
}


/*search */

.header-search {
    position: relative;
}

.main-search-container {
    background-color: #fff;
    right: 0;
    overflow: hidden;
    position: absolute;
    z-index: 100000;
    /*min-width: 300px;
        max-width: 330px;*/
    width: 240px;
    display: none;
    border: 1px solid #dedede;
}

    .main-search-container a {
        color: #fff !important;
        text-decoration: none;
    }

    .main-search-container div {
        padding: 7px;
        font-size: 13px;
    }


        .main-search-container div:hover a {
        }

.search-item-result {
    cursor: pointer;
    border-bottom: 1px solid #555;
    /* clear: both; */
    position: relative;
    min-height: 90px;
}

    .search-item-result:hover {
        background-color: #dedede;
    }


.search-item-note {
    background-color: #1F212E;
    color: #df1166;
}

img.qsearch-result-img {
    width: 50px;
    margin-right: 10px;
}


.qsearch-img-container {
    width: 70px;
    position: absolute;
    left: 0;
}

.qsearch-pricing-container {
    width: 167px;
    position: absolute;
    left: 64px;
}

span.qsearch-price {
    color: black;
}


.qsearch-name {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11px !important;
    color: black;
    font-weight: bold;
}

span.qsearch-sale-price {
    color: #df1166;
    margin-left: 20px;
}

.qsearch-prices {
    padding-top: 8px !important;
    padding-left: 0 !important;
}

.qsearch-old-price {
    text-decoration: line-through;
}


.counter-baner {
    background-image: url('/Content/theme/images/custom-pages/bradays-min.jpg');
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    padding: 10px 5px;
    text-align: center;
}

    .counter-baner span {
        color: white;
        font-size: 2em;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
    }


.bg--mobile, .bg--desktop {
    background-position: top !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 70vh !important;
}

.bg--mobile {
    height: 70vh !important;
}

.bg--desktop {
    height: 80vh !important;
}

.owl-dots .active span {
    background-color: #df1166 !important;
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-controls .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

    .owl-theme .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        margin: 10px 7px;
        background: #D6D6D6;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity .2s ease;
        border-radius: 30px;
    }

.filter-title {
    float: left;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

.sidebar-colors {
    display: block;
    clear: both;
}

:checked + label::before {
    content: none;
}

.filter-actions {
    float: left;
    margin-right: 15px;
}

    .filter-actions .ps-dropdown {
        margin-right: -4px;
    }

        .filter-actions .ps-dropdown .dropdown-toggle {
            text-transform: uppercase;
            font-weight: 500;
            padding: 10px;
        }

            .filter-actions .ps-dropdown .dropdown-toggle:after {
                content: "\f107";
                font-size: 18px;
                margin-left: 5px;
                font-family: FontAwesome;
            }

        .filter-actions .ps-dropdown.open a.dropdown-toggle {
            box-shadow: none;
            background-color: #e5e5e5;
            color: #df1166;
        }

        .filter-actions .ps-dropdown .dropdown-menu {
            padding: 0;
            border-radius: 0;
            max-height: 400px;
            min-width: 250px;
            border: none;
            overflow: auto;
        }

            .filter-actions .ps-dropdown .dropdown-menu li {
                padding: 0;
                display: flex;
                line-height: 20px;
                font-size: 13px;
                color: #8d8d8d;
                text-transform: uppercase;
                align-content: center;
                align-items: center;
            }

                .filter-actions .ps-dropdown .dropdown-menu li .color-box {
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    border: 1px solid #8d8d8d;
                    margin-right: 5px;
                }

                .filter-actions .ps-dropdown .dropdown-menu li a {
                    padding: 10px 20px;
                    width: 100%;
                }

.dropdown-menu-colle li, .dropdown-menu-color li {
    cursor: pointer;
}

    .dropdown-menu-colle li div {
        padding: 10px !important;
    }

    .dropdown-menu-color li div {
        padding: 5px 10px !important;
    }

        .dropdown-menu-colle li div:hover, .dropdown-menu-color li div:hover {
            background-color: #f5f5f5;
        }

    .dropdown-menu-colle li div, .dropdown-menu-color li div {
        width: 100%;
    }

    .dropdown-menu-colle li .active:after, .dropdown-menu-color li .active:after {
        content: '\f00c';
        font-family: 'FontAwesome';
        font-weight: 300;
        font-style: normal;
        margin: 0px;
        text-decoration: none;
        right: 10px;
        position: absolute;
        color: #df1166;
    }

.filter-list .root-category-li .nested-leaf li .ps-radio {
    width: 100%;
}

.filter-list .root-category-li .nested-leaf li .active:after {
    content: '\f00c';
    font-family: 'FontAwesome';
    font-weight: 300;
    font-style: normal;
    margin: 0px;
    text-decoration: none;
    right: 10px;
    position: absolute;
    color: #df1166;
}

.dropdown-menu-color {
    min-width: 190px;
}

.filter-actions-footer {
    position: sticky;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    padding: 15px !important;
    list-style: none;
    display: flex;
    align-content: space-between;
}

    .filter-actions-footer button {
        width: 100%;
    }


/*Range filter*/

.price-slider, .price-slider-mobile {
    margin: auto;
    position: relative;
    height: 100%;
    padding: 100px 30px 30px 30px;
}

    .price-slider label, .price-slider-mobile label {
        font-weight: 500;
    }

    .price-slider svg, .price-slider-mobile svg,
    .price-slider input[type=range], .price-slider-mobile input[type=range] {
        position: absolute;
        left: 0;
        top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }

input[type=number] {
    margin-bottom: 20px;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}

    input[type=range]:focus {
        outline: none;
    }

        input[type=range]:focus::-webkit-slider-runnable-track {
            background: #df1166;
        }

        input[type=range]:focus::-ms-fill-lower {
            background: #df1166;
        }

        input[type=range]:focus::-ms-fill-upper {
            background: #df1166;
        }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: #df1166;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
    }

    input[type=range]::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        box-shadow: 0px 0px 0px #000;
        border: 2px solid #fff;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: #df1166;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
    }

    input[type=range]::-moz-range-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: #df1166;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
    }

    input[type=range]::-moz-range-thumb {
        z-index: 2;
        position: relative;
        box-shadow: 0px 0px 0px #000;
        border: 2px solid #fff;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: #df1166;
        cursor: pointer;
    }

    input[type=range]::-ms-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type=range]::-ms-fill-lower,
    input[type=range]::-ms-fill-upper {
        background: #df1166;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
    }

    input[type=range]::-ms-thumb {
        z-index: 2;
        position: relative;
        box-shadow: 0px 0px 0px #000;
        border: 2px solid #fff;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: #df1166;
        cursor: pointer;
    }
