.ares.tparrows {
    cursor: pointer;
    background: #fff;
    min-width: 60px;
    min-height: 60px;
    position: absolute;
    display: block;
    z-index: 100;
    border-radius: 50%;
}

    .ares.tparrows:before {
        font-family: "revicons";
        font-size: 25px;
        color: #aaa;
        display: block;
        line-height: 60px;
        text-align: center;
        transition: color 0.3s;
        z-index: 2;
        position: relative;
    }

    .ares.tparrows.tp-leftarrow:before {
        content: "\e81f";
    }

    .ares.tparrows.tp-rightarrow:before {
        content: "\e81e";
    }

    .ares.tparrows:hover:before {
        color: #000;
    }

.ares .tp-title-wrap {
    position: absolute;
    z-index: 1;
    display: inline-block;
    background: #fff;
    min-height: 60px;
    line-height: 60px;
    top: 0;
    margin-left: 30px;
    border-radius: 0 30px 30px 0;
    overflow: hidden;
    transition: transform 0.3s;
    transform: scaleX(0);
    transform-origin: 0% 50%;
}

.ares.tp-rightarrow .tp-title-wrap {
    right: 0;
    margin-right: 30px;
    margin-left: 0;
    border-radius: 30px 0 0 30px;
}

.ares.tparrows:hover .tp-title-wrap {
    transform: scaleX(1) scaleY(1);
}

.ares .tp-arr-titleholder {
    position: relative;
    transition: transform 0.3s;
    transform: translateX(200px);
    text-transform: uppercase;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 60px;
    white-space: nowrap;
    padding: 0 20px;
    margin-left: 10px;
    opacity: 0;
}

.ares.tp-rightarrow .tp-arr-titleholder {
    transform: translateX(-200px);
    margin-left: 0;
    margin-right: 10px;
}

.ares.tparrows:hover .tp-arr-titleholder {
    transform: translateX(0);
    transition-delay: 0.1s;
    opacity: 1;
}

.ares.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
}

.ares .tp-bullet {
    width: 13px;
    height: 13px;
    position: absolute;
    background: #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box;
}

    .ares .tp-bullet:hover, .ares .tp-bullet.selected {
        background: #fff;
    }

.ares .tp-bullet-title {
    position: absolute;
    color: #888;
    font-size: 12px;
    padding: 0 10px;
    font-weight: 600;
    right: 27px;
    top: -4px;
    background: #fff;
    background: rgb(255, 255, 255, 0.75);
    visibility: hidden;
    transform: translateX(-20px);
    transition: transform 0.3s;
    line-height: 20px;
    white-space: nowrap;
}

    .ares .tp-bullet-title:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent rgb(255, 255, 255, 0.75);
        content: " ";
        position: absolute;
        right: -10px;
        top: 0;
    }

.ares .tp-bullet:hover .tp-bullet-title {
    visibility: visible;
    transform: translateX(0);
}

.ares .tp-bullet.selected:hover .tp-bullet-title {
    background: #fff;
}

    .ares .tp-bullet.selected:hover .tp-bullet-title:after {
        border-color: transparent transparent transparent #fff;
    }

.ares.tp-bullets:hover .tp-bullet-title {
    visibility: hidden;
}

.ares.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
    visibility: visible;
}

.ares .tp-tab {
    opacity: 1;
    padding: 10px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #e5e5e5;
}

.ares .tp-tab-image {
    width: 60px;
    height: 60px;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    display: inline-block;
    float: left;
}

.ares .tp-tab-content {
    background: rgb(0, 0, 0, 0);
    position: relative;
    padding: 15px 15px 15px 85px;
    left: 0;
    overflow: hidden;
    margin-top: -15px;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
}

.ares .tp-tab-date {
    display: block;
    color: #aaa;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
}

.ares .tp-tab-title {
    display: block;
    text-align: left;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    line-height: 17px;
}

.ares .tp-tab:hover, .ares .tp-tab.selected {
    background: #FBF5F3;
}

.custom.tparrows {
    cursor: pointer;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 10000;
}

    .custom.tparrows:hover {
        background: #000;
    }

    .custom.tparrows:before {
        font-family: "revicons";
        font-size: 15px;
        color: #fff;
        display: block;
        line-height: 40px;
        text-align: center;
    }

    .custom.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .custom.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

.custom.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
}

.custom .tp-bullet {
    width: 12px;
    height: 12px;
    position: absolute;
    background: #aaa;
    background: rgb(125, 125, 125, 0.5);
    cursor: pointer;
    box-sizing: content-box;
}

    .custom .tp-bullet:hover, .custom .tp-bullet.selected {
        background: rgb(125, 125, 125);
    }

.dione.tparrows {
    height: 100%;
    width: 100px;
    background: transparent;
    background: rgb(0, 0, 0, 0);
    line-height: 100%;
    transition: all 0.3s;
}

    .dione.tparrows:hover {
        background: rgb(0, 0, 0, 0.45);
    }

.dione .tp-arr-imgwrapper {
    width: 100px;
    left: 0;
    position: absolute;
    height: 100%;
    top: 0;
    overflow: hidden;
}

.dione.tp-rightarrow .tp-arr-imgwrapper {
    left: auto;
    right: 0;
}

.dione .tp-arr-imgholder {
    background-position: center center;
    background-size: cover;
    width: 100px;
    height: 100%;
    top: 0;
    visibility: hidden;
    transform: translateX(-50px);
    transition: all 0.3s;
    opacity: 0;
    left: 0;
}

.dione.tparrows.tp-rightarrow .tp-arr-imgholder {
    right: 0;
    left: auto;
    transform: translateX(50px);
}

.dione.tparrows:before {
    position: absolute;
    line-height: 30px;
    margin-left: -22px;
    top: 50%;
    left: 50%;
    font-size: 30px;
    margin-top: -15px;
    transition: all 0.3s;
}

.dione.tparrows.tp-rightarrow:before {
    margin-left: 6px;
}

.dione.tparrows:hover:before {
    transform: translateX(-20px);
    opacity: 0;
}

.dione.tparrows.tp-rightarrow:hover:before {
    transform: translateX(20px);
}

.dione.tparrows:hover .tp-arr-imgholder {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.dione .tp-bullet {
    opacity: 1;
    width: 50px;
    height: 50px;
    padding: 3px;
    background: #000;
    background-color: rgb(0, 0, 0, 0.25);
    margin: 0;
    box-sizing: border-box;
    transition: all 0.3s;
}

.dione .tp-bullet-image {
    display: block;
    box-sizing: border-box;
    position: relative;
    box-shadow: 5px 5px 10px 0 rgb(0, 0, 0, 0.25) inset;
    width: 44px;
    height: 44px;
    background-size: cover;
    background-position: center center;
}

.dione .tp-bullet-title {
    position: absolute;
    bottom: 65px;
    display: inline-block;
    left: 50%;
    background: #000;
    background: rgb(0, 0, 0, 0.75);
    color: #fff;
    padding: 10px 30px;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.3s;
    transform: translateZ(0.001px) translateX(-50%) translateY(14px);
    transform-origin: 50% 100%;
    opacity: 0;
    white-space: nowrap;
}

.dione .tp-bullet:hover .tp-bullet-title {
    transform: rotateX(0deg) translateX(-50%);
    opacity: 1;
}

.dione .tp-bullet-title:after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: rgb(0, 0, 0, 0.75) transparent transparent transparent;
    bottom: -8px;
}

.erinyen.tparrows {
    cursor: pointer;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    min-width: 70px;
    min-height: 70px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 35px;
}

    .erinyen.tparrows:before {
        font-family: "revicons";
        font-size: 20px;
        color: #fff;
        display: block;
        line-height: 70px;
        text-align: center;
        z-index: 2;
        position: relative;
    }

    .erinyen.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .erinyen.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

.erinyen .tp-title-wrap {
    position: absolute;
    z-index: 1;
    display: inline-block;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    min-height: 70px;
    line-height: 70px;
    top: 0;
    margin-left: 0;
    border-radius: 35px;
    overflow: hidden;
    transition: opacity 0.3s;
    transform: scale(0);
    visibility: hidden;
    opacity: 0;
}

.erinyen.tparrows:hover .tp-title-wrap {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.erinyen.tp-rightarrow .tp-title-wrap {
    right: 0;
    margin-right: 0;
    margin-left: 0;
    border-radius: 35px;
    padding-right: 20px;
    padding-left: 10px;
}

.erinyen.tp-leftarrow .tp-title-wrap {
    padding-left: 20px;
    padding-right: 10px;
}

.erinyen .tp-arr-titleholder {
    letter-spacing: 3px;
    position: relative;
    transition: transform 0.3s;
    transform: translateX(200px);
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    line-height: 70px;
    white-space: nowrap;
    padding: 0 20px;
    margin-left: 11px;
    opacity: 0;
}

.erinyen .tp-arr-imgholder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center center;
    background-size: cover;
}

.erinyen .tp-arr-img-over {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
}

.erinyen.tp-rightarrow .tp-arr-titleholder {
    transform: translateX(-200px);
    margin-left: 0;
    margin-right: 11px;
}

.erinyen.tparrows:hover .tp-arr-titleholder {
    transform: translateX(0);
    transition-delay: 0.1s;
    opacity: 1;
}

.erinyen.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #555;
    background: linear-gradient(to bottom, #555555 0%, #222222 100%);
    padding: 10px 15px;
    margin-left: -15px;
    margin-top: -10px;
    box-sizing: content-box;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px rgb(33, 33, 33, 0.3);
}

.erinyen .tp-bullet {
    width: 13px;
    height: 13px;
    position: absolute;
    background: #111;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box;
}

    .erinyen .tp-bullet:hover, .erinyen .tp-bullet.selected {
        background: #e5e5e5;
        background: linear-gradient(to bottom, #e5e5e5 0%, #999999 100%);
        border: #555 solid 1px;
        width: 12px;
        height: 12px;
    }

.erinyen .tp-thumb {
    opacity: 1;
}

.erinyen .tp-thumb-over {
    background: #000;
    background: rgb(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.3s;
}

.erinyen .tp-thumb-more:before {
    font-family: "revicons";
    font-size: 12px;
    color: #aaa;
    color: rgb(255, 255, 255, 0.75);
    display: block;
    line-height: 12px;
    text-align: left;
    z-index: 2;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
}

.erinyen .tp-thumb-more:before {
    content: "\e825";
}

.erinyen .tp-thumb-title {
    font-family: "Raleway";
    letter-spacing: 1px;
    font-size: 12px;
    color: #fff;
    display: block;
    line-height: 15px;
    text-align: left;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 20px 35px 20px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
    font-weight: 500;
}

.erinyen .tp-thumb.selected .tp-thumb-more:before, .erinyen .tp-thumb:hover .tp-thumb-more:before {
    color: #aaa;
}

.erinyen .tp-thumb.selected .tp-thumb-over, .erinyen .tp-thumb:hover .tp-thumb-over {
    background: #fff;
}

.erinyen .tp-thumb.selected .tp-thumb-title, .erinyen .tp-thumb:hover .tp-thumb-title {
    color: #000;
}

.erinyen .tp-tab-title {
    color: #a8d8ee;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto Slab";
    margin-bottom: 5px;
}

.erinyen .tp-tab-desc {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 25px;
    font-family: "Roboto Slab";
}

.gyges.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #777;
    background: linear-gradient(to bottom, #777777 0%, #666666 100%);
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
    border-radius: 10px;
}

.gyges .tp-bullet {
    width: 12px;
    height: 12px;
    position: absolute;
    background: #333;
    border: #444 solid 3px;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box;
}

.gyges .tp-thumb {
    opacity: 1;
}

.gyges .tp-thumb-img-wrap {
    padding: 3px;
    background: #000;
    background-color: rgb(0, 0, 0, 0.25);
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.3s;
}

.gyges .tp-thumb-image {
    padding: 3px;
    display: block;
    box-sizing: border-box;
    position: relative;
    box-shadow: 5px 5px 10px 0 rgb(0, 0, 0, 0.25) inset;
}

.gyges .tp-thumb-title {
    position: absolute;
    bottom: 100%;
    display: inline-block;
    left: 50%;
    background: rgb(255, 255, 255, 0.8);
    padding: 10px 30px;
    border-radius: 4px;
    margin-bottom: 20px;
    opacity: 0;
    transition: all 0.3s;
    transform: translateZ(0.001px) translateX(-50%) translateY(14px);
    transform-origin: 50% 100%;
    white-space: nowrap;
}

.gyges .tp-thumb:hover .tp-thumb-title {
    transform: rotateX(0deg) translateX(-50%);
    opacity: 1;
}

.gyges .tp-thumb-title:after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: rgb(255, 255, 255, 0.8) transparent transparent transparent;
    bottom: -8px;
}

.gyges .tp-tab {
    opacity: 1;
    padding: 10px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid rgb(255, 255, 255, 0.15);
}

.gyges .tp-tab-image {
    width: 60px;
    height: 60px;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    display: inline-block;
    float: left;
}

.gyges .tp-tab-content {
    background: rgb(0, 0, 0, 0);
    position: relative;
    padding: 15px 15px 15px 85px;
    left: 0;
    overflow: hidden;
    margin-top: -15px;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
}

.gyges .tp-tab-date {
    display: block;
    color: rgb(255, 255, 255, 0.25);
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
}

.gyges .tp-tab-title {
    display: block;
    text-align: left;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    line-height: 17px;
}

.gyges .tp-tab:hover, .gyges .tp-tab.selected {
    background: rgb(0, 0, 0, 0.5);
}

.hades.tparrows {
    cursor: pointer;
    background: #000;
    background: rgb(0, 0, 0, 0.15);
    width: 100px;
    height: 100px;
    position: absolute;
    display: block;
    z-index: 1000;
}

    .hades.tparrows:before {
        font-family: "revicons";
        font-size: 30px;
        color: #fff;
        display: block;
        line-height: 100px;
        text-align: center;
        transition: background 0.3s, color 0.3s;
    }

    .hades.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .hades.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

    .hades.tparrows:hover:before {
        color: #aaa;
        background: #fff;
        background: rgb(255, 255, 255, 1);
    }

.hades .tp-arr-allwrapper {
    position: absolute;
    left: 100%;
    top: 0;
    background: #888;
    width: 100px;
    height: 100px;
    transition: all 0.3s;
    opacity: 0;
    transform: rotatey(-90deg);
    transform-origin: 0% 50%;
}

.hades.tp-rightarrow .tp-arr-allwrapper {
    left: auto;
    right: 100%;
    transform-origin: 100% 50%;
    transform: rotatey(90deg);
}

.hades:hover .tp-arr-allwrapper {
    opacity: 1;
    transform: rotatey(0deg);
}

.hades .tp-arr-imgholder {
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hades.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
}

.hades .tp-bullet {
    width: 3px;
    height: 3px;
    position: absolute;
    background: #888;
    cursor: pointer;
    border: #fff solid 5px;
    box-sizing: content-box;
    box-shadow: 0 0 3px 1px rgb(0, 0, 0, 0.2);
    transform: translatez(0.01px);
}

    .hades .tp-bullet:hover, .hades .tp-bullet.selected {
        background: #555;
    }

.hades .tp-bullet-image {
    position: absolute;
    top: -80px;
    left: -60px;
    width: 120px;
    height: 60px;
    background-position: center center;
    background-size: cover;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    transform-style: flat;
    transform: rotatex(-90deg);
    box-shadow: 0 0 3px 1px rgb(0, 0, 0, 0.2);
    transform-origin: 50% 100%;
}

.hades .tp-bullet:hover .tp-bullet-image {
    display: block;
    opacity: 1;
    transform: rotatex(0deg);
    visibility: visible;
}

.hades .tp-thumb {
    opacity: 1;
}

.hades .tp-thumb-img-wrap {
    border-radius: 50%;
    padding: 3px;
    display: inline-block;
    background: #000;
    background-color: rgb(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.3s;
}

.hades .tp-thumb-image {
    padding: 3px;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    position: relative;
    box-shadow: 5px 5px 10px 0 rgb(0, 0, 0, 0.25) inset;
}

.hades .tp-thumb-title:after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: rgb(0, 0, 0, 0.75) transparent transparent transparent;
    bottom: -8px;
}

.hades .tp-tab {
    opacity: 1;
}

.hades .tp-tab-title {
    display: block;
    color: #333;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
}

.hades .tp-tab-price {
    display: block;
    text-align: center;
    color: #999;
    font-size: 16px;
    margin-top: 10px;
    line-height: 20px;
}

.hades .tp-tab-button {
    display: inline-block;
    margin-top: 15px;
    text-align: center;
    padding: 5px 15px;
    color: #fff;
    font-size: 14px;
    background: #219bd7;
    border-radius: 4px;
    font-weight: 400;
}

.hades .tp-tab-inner {
    text-align: center;
}

.hebe.tparrows {
    cursor: pointer;
    background: #fff;
    min-width: 70px;
    min-height: 70px;
    position: absolute;
    display: block;
    z-index: 1000;
}

    .hebe.tparrows:before {
        font-family: "revicons";
        font-size: 30px;
        color: #aaa;
        display: block;
        line-height: 70px;
        text-align: center;
        transition: color 0.3s;
        z-index: 2;
        position: relative;
        background: #fff;
        min-width: 70px;
        min-height: 70px;
    }

    .hebe.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .hebe.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

    .hebe.tparrows:hover:before {
        color: #000;
    }

.hebe .tp-title-wrap {
    position: absolute;
    z-index: 0;
    display: inline-block;
    background: #000;
    background: rgb(0, 0, 0, 0.75);
    min-height: 60px;
    line-height: 60px;
    top: -10px;
    margin-left: 0;
    transition: transform 0.3s;
    transform: scaleX(0);
    transform-origin: 0% 50%;
}

.hebe.tp-rightarrow .tp-title-wrap {
    right: 0;
}

.hebe.tparrows:hover .tp-title-wrap {
    transform: scaleX(1);
}

.hebe .tp-arr-titleholder {
    position: relative;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 90px;
    white-space: nowrap;
    padding: 0 20px 0 90px;
}

.hebe.tp-rightarrow .tp-arr-titleholder {
    margin-left: 0;
    padding: 0 90px 0 20px;
}

.hebe.tparrows:hover .tp-arr-titleholder {
    transform: translateX(0);
    transition-delay: 0.1s;
    opacity: 1;
}

.hebe .tp-arr-imgholder {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 100%;
    display: block;
    background-size: cover;
    background-position: center center;
    top: 0;
    right: -90px;
}

.hebe.tp-rightarrow .tp-arr-imgholder {
    right: auto;
    left: -90px;
}

.hebe.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
}

.hebe .tp-bullet {
    width: 3px;
    height: 3px;
    position: absolute;
    background: #fff;
    cursor: pointer;
    border: #222 solid 5px;
    border-radius: 50%;
    box-sizing: content-box;
    transform: translateZ(0.01px);
    transition: all 0.3s;
}

    .hebe .tp-bullet:hover, .hebe .tp-bullet.selected {
        background: #222;
        border-color: #fff;
    }

.hebe .tp-bullet-image {
    position: absolute;
    top: -90px;
    left: -40px;
    width: 70px;
    height: 70px;
    background-position: center center;
    background-size: cover;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    transform-style: flat;
    transform: scale(0);
    transform-origin: 50% 100%;
    border-radius: 6px;
}

.hebe .tp-bullet:hover .tp-bullet-image {
    display: block;
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.hebe .tp-tab-title {
    color: #a8d8ee;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto Slab";
    margin-bottom: 5px;
}

.hebe .tp-tab-desc {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    line-height: 25px;
    font-family: "Roboto Slab";
}

.hephaistos.tparrows {
    cursor: pointer;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 50%;
}

    .hephaistos.tparrows:hover {
        background: #000;
    }

    .hephaistos.tparrows:before {
        font-family: "revicons";
        font-size: 18px;
        color: #fff;
        display: block;
        line-height: 40px;
        text-align: center;
    }

    .hephaistos.tparrows.tp-leftarrow:before {
        content: "\e82c";
        margin-left: -2px;
    }

    .hephaistos.tparrows.tp-rightarrow:before {
        content: "\e82d";
        margin-right: -2px;
    }

.hephaistos.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
}

.hephaistos .tp-bullet {
    width: 12px;
    height: 12px;
    position: absolute;
    background: #999;
    border: #f5f5f5 solid 3px;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box;
    box-shadow: 0 0 2px 1px rgb(130, 130, 130, 0.3);
}

    .hephaistos .tp-bullet:hover, .hephaistos .tp-bullet.selected {
        background: #fff;
        border-color: #000;
    }

.hermes.tparrows {
    cursor: pointer;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    width: 30px;
    height: 110px;
    position: absolute;
    display: block;
    z-index: 1000;
}

    .hermes.tparrows:before {
        font-family: "revicons";
        font-size: 15px;
        color: #fff;
        display: block;
        line-height: 110px;
        text-align: center;
        transform: translateX(0);
        transition: all 0.3s;
    }

    .hermes.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .hermes.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

    .hermes.tparrows.tp-leftarrow:hover:before {
        transform: translateX(-20px);
        opacity: 0;
    }

    .hermes.tparrows.tp-rightarrow:hover:before {
        transform: translateX(20px);
        opacity: 0;
    }

.hermes .tp-arr-allwrapper {
    overflow: hidden;
    position: absolute;
    width: 180px;
    height: 140px;
    top: 0;
    left: 0;
    visibility: hidden;
    transition: transform 0.3s 0.3s;
    perspective: 1000px;
}

.hermes.tp-rightarrow .tp-arr-allwrapper {
    right: 0;
    left: auto;
}

.hermes.tparrows:hover .tp-arr-allwrapper {
    visibility: visible;
}

.hermes .tp-arr-imgholder {
    width: 180px;
    position: absolute;
    left: 0;
    top: 0;
    height: 110px;
    transform: translateX(-180px);
    transition: all 0.3s;
    transition-delay: 0.3s;
}

.hermes.tp-rightarrow .tp-arr-imgholder {
    transform: translateX(180px);
}

.hermes.tparrows:hover .tp-arr-imgholder {
    transform: translateX(0);
}

.hermes .tp-arr-titleholder {
    top: 110px;
    width: 180px;
    text-align: left;
    display: block;
    padding: 0 10px;
    line-height: 30px;
    background: #000;
    background: rgb(0, 0, 0, 0.75);
    color: #fff;
    font-weight: 600;
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    letter-spacing: 1px;
    transition: all 0.3s;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    box-sizing: border-box;
}

.hermes.tparrows:hover .tp-arr-titleholder {
    transition-delay: 0.6s;
    transform: rotateX(0deg);
}

.hermes .tp-bullet {
    overflow: hidden;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: rgb(0, 0, 0, 0);
    box-shadow: 0 0 0 2px #FFF inset;
    transition: background 0.3s ease;
    position: absolute;
}

    .hermes .tp-bullet:hover {
        background-color: rgb(0, 0, 0, 0.2);
    }

    .hermes .tp-bullet:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        width: 100%;
        background-color: #FFF;
        box-shadow: 0 0 1px #FFF;
        transition: height 0.3s ease;
    }

    .hermes .tp-bullet.selected:after {
        height: 100%;
    }

.hermes .tp-tab {
    opacity: 1;
    padding-right: 10px;
    box-sizing: border-box;
}

.hermes .tp-tab-image {
    width: 100%;
    height: 60%;
    position: relative;
}

.hermes .tp-tab-content {
    background: rgb(54, 54, 54);
    position: absolute;
    padding: 20px 20px 20px 30px;
    box-sizing: border-box;
    color: #fff;
    display: block;
    width: 100%;
    min-height: 40%;
    bottom: 0;
    left: -10px;
}

.hermes .tp-tab-date {
    display: block;
    color: #888;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;
}

.hermes .tp-tab-title {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 19px;
}

.hermes .tp-tab.selected .tp-tab-title:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 30px 10px;
    border-color: transparent transparent transparent rgb(54, 54, 54);
    content: " ";
    position: absolute;
    right: -9px;
    bottom: 50%;
    margin-bottom: -30px;
}

.hermes .tp-tab-mask {
    padding-right: 10px !important;
}

@media only screen and (max-width: 960px) {
    .hermes .tp-tab .tp-tab-title {
        font-size: 14px;
        line-height: 16px;
    }

    .hermes .tp-tab-date {
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 10px;
    }

    .hermes .tp-tab-content {
        padding: 15px 15px 15px 25px;
    }
}

@media only screen and (max-width: 768px) {
    .hermes .tp-tab .tp-tab-title {
        font-size: 12px;
        line-height: 14px;
    }

    .hermes .tp-tab-date {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 5px;
    }

    .hermes .tp-tab-content {
        padding: 10px 10px 10px 20px;
    }
}

.hesperiden.tparrows {
    cursor: pointer;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 50%;
}

    .hesperiden.tparrows:hover {
        background: #000;
    }

    .hesperiden.tparrows:before {
        font-family: "revicons";
        font-size: 20px;
        color: #fff;
        display: block;
        line-height: 40px;
        text-align: center;
    }

    .hesperiden.tparrows.tp-leftarrow:before {
        content: "\e82c";
        margin-left: -3px;
    }

    .hesperiden.tparrows.tp-rightarrow:before {
        content: "\e82d";
        margin-right: -3px;
    }

.hesperiden.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
    border-radius: 8px;
}

.hesperiden .tp-bullet {
    width: 12px;
    height: 12px;
    position: absolute;
    background: #999;
    background: linear-gradient(to bottom, #999999 0%, #e1e1e1 100%);
    border: #e5e5e5 solid 3px;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: content-box;
}

    .hesperiden .tp-bullet:hover, .hesperiden .tp-bullet.selected {
        background: #666;
    }

.hesperiden .tp-thumb {
    opacity: 1;
    perspective: 600px;
}

    .hesperiden .tp-thumb .tp-thumb-title {
        font-size: 12px;
        position: absolute;
        margin-top: -10px;
        color: #fff;
        display: block;
        z-index: 10000;
        background-color: #000;
        padding: 5px 10px;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        transition: all 0.3s;
        transform: rotatex(90deg) translatez(0.001px);
        transform-origin: 50% 100%;
        opacity: 0;
    }

    .hesperiden .tp-thumb:hover .tp-thumb-title {
        transform: rotatex(0deg);
        opacity: 1;
    }

.hesperiden .tp-tab {
    opacity: 1;
    padding: 10px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #e5e5e5;
}

.hesperiden .tp-tab-image {
    width: 60px;
    height: 60px;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    display: inline-block;
    float: left;
}

.hesperiden .tp-tab-content {
    background: rgb(0, 0, 0, 0);
    position: relative;
    padding: 15px 15px 15px 85px;
    left: 0;
    overflow: hidden;
    margin-top: -15px;
    box-sizing: border-box;
    color: #333;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
}

.hesperiden .tp-tab-date {
    display: block;
    color: #aaa;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0;
}

.hesperiden .tp-tab-title {
    display: block;
    text-align: left;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    line-height: 17px;
}

.hesperiden .tp-tab:hover, .hesperiden .tp-tab.selected {
    background: #FBF5F3;
}

.metis.tparrows {
    background: #fff;
    padding: 10px;
    transition: all 0.3s;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
}

    .metis.tparrows:hover {
        background: #fff;
        background: rgb(255, 255, 255, 0.75);
    }

    .metis.tparrows:before {
        color: #000;
        transition: all 0.3s;
    }

    .metis.tparrows:hover:before {
        transform: scale(1.5);
    }

.metis .tp-bullet {
    opacity: 1;
    width: 50px;
    height: 50px;
    padding: 3px;
    background: #000;
    background-color: rgb(0, 0, 0, 0.25);
    margin: 0;
    box-sizing: border-box;
    transition: all 0.3s;
    border-radius: 50%;
}

.metis .tp-bullet-image {
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    position: relative;
    box-shadow: 5px 5px 10px 0 rgb(0, 0, 0, 0.25) inset;
    width: 44px;
    height: 44px;
    background-size: cover;
    background-position: center center;
}

.metis .tp-bullet-title {
    position: absolute;
    bottom: 65px;
    display: inline-block;
    left: 50%;
    background: #000;
    background: rgb(0, 0, 0, 0.75);
    color: #fff;
    padding: 10px 30px;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.3s;
    transform: translateZ(0.001px) translateX(-50%) translateY(14px);
    transform-origin: 50% 100%;
    opacity: 0;
    white-space: nowrap;
}

.metis .tp-bullet:hover .tp-bullet-title {
    transform: rotateX(0deg) translateX(-50%);
    opacity: 1;
}

.metis .tp-bullet-title:after {
    content: " ";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: rgb(0, 0, 0, 0.75) transparent transparent transparent;
    bottom: -8px;
}

.metis .tp-tab-number {
    color: #fff;
    font-size: 40px;
    line-height: 30px;
    font-weight: 400;
    font-family: "Playfair Display";
    width: 50px;
    margin-right: 17px;
    display: inline-block;
    float: left;
}

.metis .tp-tab-mask {
    padding-left: 20px;
    left: 0;
    max-width: 90px !important;
    transition: padding-left 0.4s, left 0.4s, max-width 0.4s;
}

.metis:hover .tp-tab-mask {
    padding-left: 0;
    left: 50px;
    max-width: 500px !important;
}

.metis .tp-tab-divider {
    border-right: 1px solid transparent;
    height: 30px;
    width: 1px;
    margin-top: 5px;
    display: inline-block;
    float: left;
}

.metis .tp-tab-title {
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    font-family: "Playfair Display";
    position: relative;
    padding-top: 10px;
    padding-left: 30px;
    display: inline-block;
    transform: translateX(-100%);
    transition: all 0.4s;
}

.metis .tp-tab-title-mask {
    position: absolute;
    overflow: hidden;
    left: 67px;
}

.metis:hover .tp-tab-title {
    transform: translateX(0);
}

.metis .tp-tab {
    opacity: 0.15;
    transition: all 0.4s;
}

    .metis .tp-tab:hover, .metis .tp-tab.selected {
        opacity: 1;
    }

        .metis .tp-tab.selected .tp-tab-divider {
            border-right: 1px solid #cdb083;
        }

.metis.tp-tabs {
    max-width: 118px !important;
    padding-left: 50px;
}

    .metis.tp-tabs:before {
        content: " ";
        height: 100%;
        width: 88px;
        background: rgb(0, 0, 0, 0.15);
        border-right: 1px solid rgb(255, 255, 255, 0.1);
        left: 0;
        top: 0;
        position: absolute;
        transition: all 0.4s;
    }

    .metis.tp-tabs:hover:before {
        width: 118px;
    }

@media (max-width: 499px) {
    .metis.tp-tabs:before {
        background: rgb(0, 0, 0, 0.75);
    }
}

.persephone.tparrows {
    cursor: pointer;
    background: #aaa;
    background: rgb(200, 200, 200, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 100;
    border: #f5f5f5 solid 1px;
}

    .persephone.tparrows:hover {
        background: #333;
    }

    .persephone.tparrows:before {
        font-family: "revicons";
        font-size: 15px;
        color: #fff;
        display: block;
        line-height: 40px;
        text-align: center;
    }

    .persephone.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .persephone.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

.persephone.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(76, 175, 80, 0.3);
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    box-sizing: content-box;
}

.persephone .tp-bullet {
    width: 12px;
    height: 12px;
    position: absolute;
    background: #aaa;
    border: #e5e5e5 solid 1px;
    cursor: pointer;
    box-sizing: content-box;
}

    .persephone .tp-bullet:hover, .persephone .tp-bullet.selected {
        background: #222;
    }

.uranus.tparrows {
    width: 50px;
    height: 50px;
    background: transparent;
}

    .uranus.tparrows:before {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 40px;
        transition: all 0.3s;
    }

    .uranus.tparrows:hover:before {
        opacity: 0.75;
    }

.uranus .tp-bullet {
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgb(255, 255, 255, 0);
    transition: box-shadow 0.3s ease;
    background: transparent;
}

    .uranus .tp-bullet.selected, .uranus .tp-bullet:hover {
        box-shadow: 0 0 0 2px #FFF;
        border: none;
        border-radius: 50%;
        background: transparent;
    }

.uranus .tp-bullet-inner {
    background-color: rgb(255, 255, 255, 0.7);
    transition: background-color 0.3s ease, transform 0.3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 50%;
    background-color: #FFF;
    background-color: rgb(255, 255, 255, 0.3);
    text-indent: -999em;
    cursor: pointer;
    position: absolute;
}

.uranus .tp-bullet.selected .tp-bullet-inner, .uranus .tp-bullet:hover .tp-bullet-inner {
    transform: scale(0.4);
    background-color: #fff;
}

.zeus.tparrows {
    cursor: pointer;
    min-width: 70px;
    min-height: 70px;
    position: absolute;
    display: block;
    z-index: 100;
    border-radius: 35px;
    overflow: hidden;
    background: rgb(0, 0, 0, 0.1);
}

    .zeus.tparrows:before {
        font-family: "revicons";
        font-size: 20px;
        color: #fff;
        display: block;
        line-height: 70px;
        text-align: center;
        z-index: 2;
        position: relative;
    }

    .zeus.tparrows.tp-leftarrow:before {
        content: "\e824";
    }

    .zeus.tparrows.tp-rightarrow:before {
        content: "\e825";
    }

.zeus .tp-title-wrap {
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s;
    border-radius: 50%;
}

.zeus .tp-arr-imgholder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    transform: translateX(-100%);
    transition: all 0.3s;
}

.zeus.tp-rightarrow .tp-arr-imgholder {
    transform: translateX(100%);
}

.zeus.tparrows:hover .tp-arr-imgholder {
    transform: translateX(0);
    opacity: 1;
}

.zeus.tparrows:hover .tp-title-wrap {
    transform: scale(1);
    opacity: 1;
}

.zeus .tp-bullet {
    box-sizing: content-box;
    border-radius: 50%;
    background-color: rgb(0, 0, 0, 0);
    transition: opacity 0.3s ease;
    width: 13px;
    height: 13px;
    border: #fff solid 2px;
}

    .zeus .tp-bullet:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        border-radius: 50%;
        background-color: #FFF;
        transform: scale(0);
        transform-origin: 50% 50%;
        transition: transform 0.3s ease;
    }

    .zeus .tp-bullet:hover:after, .zeus .tp-bullet.selected:after {
        transform: scale(1.2);
    }

.zeus .tp-bullet-image, .zeus .tp-bullet-imageoverlay {
    width: 135px;
    height: 60px;
    position: absolute;
    background: #000;
    background: rgb(0, 0, 0, 0.5);
    bottom: 25px;
    left: 50%;
    margin-left: -65px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center center;
    visibility: hidden;
    opacity: 0;
    backface-visibility: hidden;
    transform-origin: 50% 50%;
    transition: all 0.3s ease;
    border-radius: 4px;
}

.zeus .tp-bullet-title, .zeus .tp-bullet-imageoverlay {
    z-index: 2;
    transition: all 0.5s ease;
}

.zeus .tp-bullet-title {
    color: #fff;
    text-align: center;
    line-height: 15px;
    font-size: 13px;
    font-weight: 600;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    backface-visibility: hidden;
    transform-origin: 50% 50%;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 45px;
    width: 135px;
    vertical-align: middle;
    left: -57px;
}

.zeus .tp-bullet:hover .tp-bullet-title, .zeus .tp-bullet:hover .tp-bullet-image, .zeus .tp-bullet:hover .tp-bullet-imageoverlay {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.zeus .tp-thumb {
    opacity: 1;
}

.zeus .tp-thumb-over {
    background: #000;
    background: rgb(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.3s;
}

.zeus .tp-thumb-more:before {
    font-family: "revicons";
    font-size: 12px;
    color: #aaa;
    color: rgb(255, 255, 255, 0.75);
    display: block;
    line-height: 12px;
    text-align: left;
    z-index: 2;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
}

.zeus .tp-thumb-more:before {
    content: "\e825";
}

.zeus .tp-thumb-title {
    font-family: "Raleway";
    letter-spacing: 1px;
    font-size: 12px;
    color: #fff;
    display: block;
    line-height: 15px;
    text-align: left;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 20px 35px 20px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
    font-weight: 500;
}

.zeus .tp-thumb.selected .tp-thumb-more:before, .zeus .tp-thumb:hover .tp-thumb-more:before {
    color: #aaa;
}

.zeus .tp-thumb.selected .tp-thumb-over, .zeus .tp-thumb:hover .tp-thumb-over {
    background: #000;
}

.zeus .tp-thumb.selected .tp-thumb-title, .zeus .tp-thumb:hover .tp-thumb-title {
    color: #fff;
}

.zeus .tp-tab {
    opacity: 1;
    box-sizing: border-box;
}

.zeus .tp-tab-title {
    display: block;
    text-align: center;
    background: rgb(0, 0, 0, 0.25);
    font-family: "Roboto Slab", serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    color: #fff;
    padding: 9px 10px;
}

.zeus .tp-tab:hover .tp-tab-title, .zeus .tp-tab.selected .tp-tab-title {
    color: #000;
    background: rgb(255, 255, 255, 1);
}

.post-tabs .tp-thumb {
    opacity: 1;
}

.post-tabs .tp-thumb-over {
    background: #252525;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.3s;
}

.post-tabs .tp-thumb-more:before {
    font-family: "revicons";
    font-size: 12px;
    color: #aaa;
    color: rgb(255, 255, 255, 0.75);
    display: block;
    line-height: 12px;
    text-align: left;
    z-index: 2;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
}

.post-tabs .tp-thumb-more:before {
    content: "\e825";
}

.post-tabs .tp-thumb-title {
    font-family: "raleway";
    letter-spacing: 1px;
    font-size: 12px;
    color: #fff;
    display: block;
    line-height: 15px;
    text-align: left;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 15px 30px 15px 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
    font-weight: 500;
}

.post-tabs .tp-thumb.selected .tp-thumb-more:before, .post-tabs .tp-thumb:hover .tp-thumb-more:before {
    color: #aaaaaa;
}

.post-tabs .tp-thumb.selected .tp-thumb-over, .post-tabs .tp-thumb:hover .tp-thumb-over {
    background: #ffffff;
}

.post-tabs .tp-thumb.selected .tp-thumb-title, .post-tabs .tp-thumb:hover .tp-thumb-title {
    color: #000000;
}
