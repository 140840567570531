/*.custom-notifier {
    display: none;
    border: 1px solid #475ea3;
    font-size: 1.05em;
    height: 40px;
    line-height: 35px;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: center;
    width: 100%;
    background-color: #475ea3;
    color: white;
    font-weight: 600;
    z-index: 100000;
}*/


.border-bottom {
    border-bottom: solid 1px #e5e5e5;
}

.pointer {
    cursor: pointer;
}


/**/
.ps-product--fashion .ps-product__thumbnail {
    display: flex;
    align-items: center;
    text-align: center !important;
    justify-content: center;
}

.ps-product__thumbnail img {
    margin: 0 auto;
}

.current-page-number > a:before {
    background-color: #df1166 !important;
    color: white !important;
}


.current-page-number > a {
    color: white !important;
}


span.listing-item-sale-price {
    font-weight: 500;
    color: #df1166;
    font-size: 1.1em;
}


.header__logo {
    margin-left: 15px;
}


aside.ps-sidebar {
    z-index: 1000;
}

.aside-menu-x {
    float: right;
    margin-right: 16px;
    font-size: 1.5em;
    font-weight: bold;
    color: #FFA967;
}

.filters-mobile-cat {
    font-size: 1.1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    /* margin: 15px; */
    border: 1px solid #dedede;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    margin-top: -10px;
}

    .filters-mobile-cat span {
        font-weight: 500;
        font-size: 16px;
        margin-left: 5px;
    }

#categoryTreeUL, #categoryTreeUL ul, #filterTreeUL, #filterTreeUL ul {
    list-style-type: none;
    padding-left: 7px !important;
}

    #categoryTreeUL li, #filterTreeUL li {
        position: relative;
    }


#categoryTreeUL, #filterTreeUL {
    margin: 0;
    padding: 0;
    width: 220px;
}

    #categoryTreeUL a, #filterTreeUL a {
        display: block;
        line-height: 20px;
    }

.category-tree-ul-class .caret-tree-item {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}


a.active-cat-leaf-node {
    color: #df1166;
    font-weight: 500;
}


.category-tree-ul-class a.selected-leaf {
    color: #df1166;
    font-weight: bold;
}

.category-tree-ul-class .caret-tree-item::before {
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\002B";
    width: 20px;
    height: 20px;
    line-height: 20px !important;
    display: block;
    position: absolute;
    right: 0px;
    top: 5px;
    font-size: 18px;
    text-align: center;
    color: #777;
    transition: all .2s ease-in
}

.category-tree-ul-class .root-category-li > .caret-tree-item-down::before {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Safari */
    /*transform: rotate(90deg);  */
    transform: rotate(180deg);
    -ms-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    top: 2px;
    content: "\2212";
}

.category-tree-ul-class li.root-category-li > a {
    text-transform: uppercase;
    margin-bottom: 6px;
    margin-top: 14px;
    font-weight: 600;
}

.category-tree-ul-class .nested-leaf {
    display: none;
}

    .category-tree-ul-class .nested-leaf li {
        font-weight: normal;
        text-transform: none;
    }

.category-tree-ul-class .active-leaf {
    display: block;
}


.category-tree-item-a {
    cursor: pointer;
}

.ps-widget--sidebar {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 25px;
    margin-bottom: 25px;
    background-color: white;
}

.ps-sidebar-hidden {
    display: none;
}

.hide-menu {
    height: 100%;
    -webkit-transform: translate3d(300px,0,0);
    -moz-transform: translate3d(300px,0,0);
    -o-transform: translate3d(300px,0,0);
    -ms-transform: translate3d(300px,0,0);
    transform: translate3d(300px,0,0);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.show-menu {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.cart-checkout-product-image {
    max-width: 90px;
}


.ps-iconbox header p {
    text-transform: uppercase;
}


.ps-cart__table {
    border-bottom: solid 1px #e5e5e5;
}
